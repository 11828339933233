import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { LoadToast, updateToast, validation } from "../lib/common";
import * as User from "../Axios/user.axios";
import { useDispatch } from "react-redux";


const NewEmailOtpModal = ({ show, handleClose,NewEmailotp,useremail,updateemail }) => {
  const initial = {
    OTP: "",
    UpdateEmail:updateemail,
    OldEmail:useremail

  };
  const [formdata, setFormdata] = useState(initial);
  const [err, setErr] = useState({});
  const [loader, setloader] = useState(false);
  const [ExistsOtp, setExistsOtp] = useState(NewEmailotp??"");
  const dispatch = useDispatch();

  const submit=async()=>{

    setloader(true)
    const id = LoadToast();
    let err = await validation(Object.keys(initial), formdata);
    
    if (Object.keys(err)?.length > 0) {
      updateToast(id, "fix");
      setErr(err);
      setloader(false)

      return;
    }
    setErr({});
    if(ExistsOtp == formdata?.OTP){
    var { success, msg,data } = await User.ChangeEmail(formdata);
    updateToast(
      id,
      msg,
      success
    );
    setloader(false)

if(success=='success'){
  handleClose()
  localStorage.setItem("userInfo",data.Email);
  dispatch({
    type: "Register_Section",
    Register_Section: {
      User: {
        UserDetails: data,
        token: "",
      },
    },
  });
// window.location.reload()

}
    }
    else{
      updateToast(
        id,
        "Please Enter Valid OTP...",
        'error'
      );
    }
            
        }

        const Resendotp = async () => {
          const id = LoadToast();

          
          var { success, msg,data } = await User.SendOtp({Email:formdata?.UpdateEmail});
          
              updateToast(
                id,
                msg,
                success
              );
              if(success=='success'){
                setExistsOtp(data)
              }
        };
  console.log("NewEmailotpNewEmailotp",NewEmailotp);
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="md"
        className="common_modal otp_modal"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title></Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="p-3">
            <h4 className="modal_title text-center">Entddder OTP</h4>
            <div className="otp_input mt-4">
              <OtpInput
                id="OTP"
                value={formdata?.OTP}
                numInputs={6}
                onChange={(e) => {setFormdata({ ...formdata, ...{ ['OTP']: e } });setloader(false)} }                
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>{" "}
            {err?.OTP && (
                      <p className="mb-0 err_msg_txt text-center">{err?.OTP}</p>
                    )}
       {  loader? 
       <div className="mt-4 d-flex align-items-center justify-content-center">
       <Button className="cmn-btn f-14 fw-600 px-4 disabled">Processing...</Button>
     </div>
     :
       <div className="mt-4 d-flex align-items-center justify-content-center">
              <Button className="cmn-btn f-14 fw-600 px-4" onClick={submit}>Confirm</Button>
            </div>}
          </div>

          <div className="sgn_bot_sec">
            <p className="mb-0 text-center">
            Can't get OTP? <span className="sgn_term_btn resend_txt" onClick={() => Resendotp()} >RESEND</span>
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NewEmailOtpModal;
