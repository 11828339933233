import React from "react";
import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/Card";
import { MdArrowForwardIos } from "react-icons/md";
import { Link } from "react-router-dom";
import config from '../lib/config'

function BlogCard({  data,type ,footerbg}) {
  return (
    <div className="country-card">
      <div className="common-card">

            <Card>
          <Card.Img src={`${config?.IMG_URL}/blogs/${data?.Symbol}/${data?.Image}`} className="img-fluid country-bg" />
          <Card.Body className={footerbg == "black" ? "bg-black" : ""}>
                <div className="d-flex align-items-center justify-content-between">
                  <span className="ms-2 fw-600 f-15">{data?.Heading}</span>
                  <Link to="" className="blog-card-nav">
                    <span className="ms-2 fw-600 f-15">
                      <MdArrowForwardIos />
                    </span>
                  </Link>
                </div>
              </Card.Body>
    
        </Card>
      </div>
    </div>
  );
}

export default BlogCard;
