import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import PhoneInput from 'react-phone-number-input'


import meal from "../assets/images/book/meals.png"
import drink from "../assets/images/book/drink.png"




const ConfirmphoneModal = ({ phoneShow, handlePhoneClose }) => {

    const [value, setValue] = useState();

  return (
    <div>
      
      <Modal
        show={phoneShow}
        onHide={handlePhoneClose}
        centered
        size="md"
        className="common_modal"
      >
        <Modal.Header closeButton className="modal_header_without_title">
         
      </Modal.Header>
        <Modal.Body className="pb-4">
        <Modal.Title className="text-center">Save confirmation on your phone
(recommended)</Modal.Title>
<Row className="align-items-center justify-content-center">
    <Col sm={11} lg={10} className="text-center">
    <p className="text-center sm_dark_modal mt-3">Enjoy easy access to your booking details anywhere. anytime with the app</p>

    </Col>
</Row>
<div className="text-center input_modal">
<PhoneInput
                      className="custom_phoneinput"
                      international 
                      defaultCountry="IN"
      placeholder="Enter phone number"
      value={value}
      onChange={setValue}/>
</div>

<div className="text-center mt-3">

<button type="button" class="pay-btn btn btn-primary mt-3 text-center">
Send link to my phone</button>
</div>

<p className="text-center sm_dark_modal mt-3">You will receive the text msg with confirmation</p>

<hr className="purple_hr" />

<Modal.Title className="text-center">Print Your Booking
Confirmation</Modal.Title>
<Row className="align-items-center justify-content-center">
    <Col sm={11} lg={10} className="text-center">
    <p className="text-center sm_dark_modal mt-3">Get PDF with your booking confirmation in the language of your choice</p>

    </Col>
</Row>


<div className="text-center">

<button type="button" class="pay-btn btn btn-primary mt-1 text-center">
English Version</button>
</div>

<p className="text-center sm_dark_modal mt-3">Show your printout when checking in to your accommodation</p>


        
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ConfirmphoneModal;
