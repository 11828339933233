import React, { useState, useEffect } from "react";
import {Modal } from "react-bootstrap";

import {
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";


const ViewMapModal = ({ props, viewMapShow, handleViewMapClose, location }) => {
 
 
  const [MapLoaded, SetMapLoaded] = useState(false);
  const [getCenter, SetgetCenter] = useState("");
  const [Clicked, SetClicked] = useState("");


  useEffect(() => {

        var pos = {
          lat: Number(location?.lt),
          lng: Number(location?.ln),
        };
        SetgetCenter(pos);
        SetClicked(pos);

  }, []);

  const MapContainer = withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={15}
      defaultCenter={getCenter}
      onTilesLoaded={() => SetMapLoaded(true)}
      onClick={(e) => {
        SetClicked({
          lat: e?.latLng?.lat(),
          lng: e?.latLng?.lng(),
        });
      }}
    >
      <Marker
        clickable={true}
        position={Clicked}

      >

      </Marker>
    </GoogleMap>
  ));

  return (
    <div>
      <Modal
        show={viewMapShow}
        onHide={handleViewMapClose}
        centered
        size="md"
        className="common_modal viewmap_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Address</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-4">
          <div className="viewOnMap">

                <MapContainer
              containerElement={<div style={{ height: `400px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ViewMapModal;
