import React, { useEffect, useState } from "react";
import upcoming from "../assets/images/account/bookings/upcoming.png";
import cancel from "../assets/images/account/bookings/cancel.png";
import complete from "../assets/images/account/bookings/complete.png";
import search from "../assets/images/account/bookings/search.png";
import filter from "../assets/images/account/bookings/filter.png";
import calendar from "../assets/images/account/bookings/calendar.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "react-bootstrap";
import BookingRoomCard from "./BookingRoomCard";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Hotels from "../Axios/hotel.axios";
import NodataFound from "./NodataFound";
import CustomLoader from "./CustomLoader";
import moment from 'moment'
import { isEmpty } from "../lib/common";
import { useNavigate } from "react-router";


function Bookings({stripe,Status,statusTabs}) {

  // const [accTabs, setAccTabs] = useState(Status??"Pending");
  const [search, setsearch] = useState(false);
  

  const { UserDetails } = useSelector((state) => state.LoginReducer.User);
  var obj = {
    [Status]: { loader: true, page: 1, list: [] },
  };
  const navigate = useNavigate();

  const [Bookingdetails, setBookingdetails] = useState(obj);
  const [Loader, setLoader] = useState(false);
  var init={
    Searchdata:"",
    Checkin: "",
    Checkout: "",
  }
  const [Searchdata, setseachData] = useState(init);


useEffect((item)=>{
  if(UserDetails){
    Bookingdetails[Status] = { page: 1, list: [], loader: false };
    setBookingdetails(Bookingdetails);
    BookingDetails(1, Status);
  }

},[Status,search,UserDetails])

const BookingDetails=async(page,tab)=>{
let Arr=[]
  setLoader(true)
    let { success,data,count } = await Hotels?.Get_Booking_Details_Local({
      Userid:UserDetails?._id,
      BookingStatus:tab,
      limit:10,
      skip:((page ? parseInt(page) : 1) - 1) * 10,
      Searchdata:Searchdata?.Searchdata,
      Checkin:Searchdata?.Checkin,
      Checkout:Searchdata.Checkout

    });
    if(data){
      setBookingdetails({
        ...Bookingdetails,
        ...{
          [tab]: {
            list: [...(Bookingdetails[tab]?.list ?? [ ]), ...data
          ],
            loader:data?.length == 0 ||
            (Bookingdetails?.[tab]?.list?.length ?? 0) + (data?.length ?? 0) >=
            count?
            false : true,
            page: Bookingdetails[tab]?.page,

               },
        },
      });
  
    }
    
setTimeout(() => {
  setLoader(false)

}, 1000);
  // navigate('/account')

}
const OnchangeSearch =async(id,value)=>{
  setsearch(false)
    if(id=="Checkin"||id=='Checkout'){
    setseachData({...Searchdata,...{[id]:new Date(value)}})

  }
  else{
    setseachData({...Searchdata,...{[id]:value}})

  }



}

const LoadMore =()=>{
  Bookingdetails[Status].page = Bookingdetails[Status].page + 1;
  setBookingdetails(Bookingdetails);
  BookingDetails(Bookingdetails[Status].page, Status)

}


// console.log("SearchdataSearchdata",Searchdata);
  return (
    <div className="acc-bookings">
      <div className="cusTabs-card">
        <div className="tabs-headings">
          <span
            className={Status == "Pending" ? "name active" : "name"}
            onClick={() => statusTabs("Pending")}
          >
            <img src={upcoming} className="img-fluid" />
            Upcoming
          </span>
          <span
            className={Status == "Cancelled" ? "name active" : "name"}
            onClick={() => statusTabs("Cancelled")}
          >
            <img src={cancel} className="img-fluid" />
            Cancelled
          </span>
          <span
            className={Status == "Completed" ? "name active" : "name"}
            onClick={() => statusTabs("Completed")}
          >
            <img src={complete} className="img-fluid" />
            Completed
          </span>
          {/* <span
            className={Status == "Pending" ? "name active" : "name"}
            onClick={() => statusTabs("Pending")}
          >
            <img src={upcoming} className="img-fluid" />
            Expired
          </span> */}
        </div>
{/* {   Loader? */}
     <div className="tbs-content mt-4">
            <div className="tbs-item">
              <div className="searchFlilter-sec">
                <div className="search">
                  <img src={search} className="img-fluid" />
                  <input
                    type="text"
                    className="searchInp"
                    placeholder="Search"
                    id='search'
                    onChange={(e)=>OnchangeSearch('Searchdata',e.target.value)}
                  />
                </div>
                <div className="checkInOut checkIn">
                  <DatePicker
                    selected={Searchdata?.Checkin}
                    placeholder="Check-in"
                    onChange={(date) => OnchangeSearch('Checkin',date)}
                    showIcon
                    icon={<img src={calendar} />}
                    className="checkInOut-DatePicker"
                    value={Searchdata?.Checkin ?moment(Searchdata?.Checkin).format('DD-MM-YYYY'):"Checkin"}
                    // value={Searchdata?.Checkin}

                    id='Checkin'
                    // minDate={new Date()}
                    maxDate={
                      Searchdata.Checkout&&
                      new Date(
                        new Date(Searchdata.Checkout).setDate(
                          new Date(Searchdata.Checkout).getDate()-1
                        )
                      )
                    }

                  />
                </div>
                <div className="checkInOut checkOut">
                  <DatePicker
                    selected={Searchdata?.Checkout}
                    placeholder="Check-in"
                    onChange={(date) => OnchangeSearch('Checkout',date)}
                    showIcon
                    icon={<img src={calendar} />}
                    className="checkInOut-DatePicker"
                    // value={Searchdata?.Checkout}

                    value={Searchdata?.Checkout ?moment(Searchdata?.Checkout).format('DD-MM-YYYY'):"Checkout"}
                    id='Checkout'
                    minDate={
                      new Date(
                        new Date(Searchdata.Checkin).setDate(
                          new Date(Searchdata.Checkin).getDate() + 1
                        )
                      )
                    }

                  />
                </div>
                <div className="sear-button">
                  <Button className="srch-btn cmn-purple-btn" onClick={()=>setsearch(true)}>Search</Button>
                </div>
              </div>
              <div className="roomStatus mt-3">
                <img src={filter} className="img-fluid filter-icon" />
                <span className="f-15 fw-500 ms-2 ps-1">
                  Status: All Booking ({Bookingdetails[Status]?.list?.length})
                </span>
              </div>

             {!Loader?
              <>
 
            {
                       Bookingdetails[Status]?.list.length>0?
                       Bookingdetails[Status]?.list?.map((val)=>
                                    <div className="mt-3">
                                       <BookingRoomCard Hoteldata={val} Status={Status} BookingDetailsfun={BookingDetails} stripe={stripe}/>
                                     </div>
                       )
                                     :
                                     <div className="mt-3">
                       
                       <NodataFound/>
                                   </div>
            }
            </>
:

<div className="dataFount_loader">
        <CustomLoader />
      </div>
}


{Bookingdetails[Status]?.loader &&
  <div className="mt-3 mt-lg-4 d-flex align-items-center justify-content-center">

<Button onClick={()=>LoadMore()} className="cmn-btn px-3 " style={{fontWeight:600}}>Load More</Button>

</div>}
            </div>
    
        </div>
        {/* :
        <div className="dataFount_loader">
        <CustomLoader />
      </div>
        } */}
                  {/* {accTabs == "Pending" ? ( */}


      {/* ) : accTabs == "Cancelled" ? (
            <div className="tbs-item">
              <div className="searchFlilter-sec">
                <div className="search">
                  <img src={search} className="img-fluid" />
                  <input
                    type="text"
                    className="searchInp"
                    placeholder="Search"
                  />
                </div>
                <div className="checkInOut checkIn">
                  <DatePicker
                    selected={startDate}
                    placeholder="Check-in"
                    onChange={(date) => setStartDate(date)}
                    showIcon
                    icon={<img src={calendar} />}
                    className="checkInOut-DatePicker"
                    value="Check-in"
                  />
                </div>
                <div className="checkInOut checkOut">
                  <DatePicker
                    selected={startDate1}
                    placeholder="Check-in"
                    onChange={(date) => setStartDate1(date)}
                    showIcon
                    icon={<img src={calendar} />}
                    className="checkInOut-DatePicker"
                    value="Check-out"
                  />
                </div>
                <div className="sear-button">
                  <Button className="srch-btn cmn-purple-btn">Search</Button>
                </div>
              </div>
              <div className="roomStatus mt-3">
                <img src={filter} className="img-fluid filter-icon" />
                <span className="f-15 fw-500 ms-2 ps-1">
                  Status: All Booking (1)
                </span>
              </div>
   
        {
          Bookingdetails?.length>0?
Bookingdetails?.map((val)=>
             <div className="mt-3">
                <BookingRoomCard Hoteldata={val}/>
              </div>
)
              :
              <div className="mt-3">

<NodataFound/>
            </div>
              }
            </div>
          ) : (
            <div className="tbs-item">
              <div className="searchFlilter-sec">
                <div className="search">
                  <img src={search} className="img-fluid" />
                  <input
                    type="text"
                    className="searchInp"
                    placeholder="Search"
                  />
                </div>
                <div className="checkInOut checkIn">
                  <DatePicker
                    selected={startDate}
                    placeholder="Check-in"
                    onChange={(date) => setStartDate(date)}
                    showIcon
                    icon={<img src={calendar} />}
                    className="checkInOut-DatePicker"
                    value="Check-in"
                  />
                </div>
                <div className="checkInOut checkOut">
                  <DatePicker
                    selected={startDate1}
                    placeholder="Check-in"
                    onChange={(date) => setStartDate1(date)}
                    showIcon
                    icon={<img src={calendar} />}
                    className="checkInOut-DatePicker"
                    value="Check-out"
                  />
                </div>
                <div className="sear-button">
                  <Button className="srch-btn cmn-purple-btn">Search</Button>
                </div>
              </div>
              <div className="roomStatus mt-3">
                <img src={filter} className="img-fluid filter-icon" />
                <span className="f-15 fw-500 ms-2 ps-1">
                  Status: All Booking (1)
                </span>
              </div>
              {
          Bookingdetails?.length>0?
Bookingdetails?.map((val)=>
             <div className="mt-3">
                <BookingRoomCard Hoteldata={val}/>
              </div>
)
              :
              <div className="mt-3">

<NodataFound/>
            </div>
              }
            </div>
          )} */}

      </div>
    </div>
  );
}

export default Bookings;
