import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import Image from "../components/Image";
import { Link } from "react-router-dom";
import { LoadToast, isEmpty, updateToast, validation } from "../lib/common";
import * as User from "../Axios/user.axios";
import { useDispatch } from "react-redux";
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login";
import { useNavigate } from "react-router-dom";
import config from "../lib/config";
import { toast } from "react-toastify";
import { GetUserCookieToken } from "../Axios/hotel.axios";

const LoginModal = ({
  loginShow,
  handleLoginClose,
  handleForgotEmail,
  handleSignup,
  handleotp,
  UserEmail,
  setotp,
}) => {
  const initial = {
    Email: "",
    Password: "",
    Type: "email",
  };
  const [formdata, setFormdata] = useState(initial);
  const [err, setErr] = useState({});
  const [Accesstoken, setAccesstoken] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [passwordEyeshow, setpasswordEyeshow] = useState(false);
  const [loader, setloader] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleotpfun = () => {
    handleotp(true);
    handleLoginClose();
  };
  const handleForgotEmailShow = () => {
    handleLoginClose();
    handleForgotEmail(true);
  };

  const handleSignupShow = () => {
    handleLoginClose();
    handleSignup(true);
  };
  useEffect(() => {
    if (Accesstoken) {
      GoogleAccountDetails(Accesstoken.access_token);
    }
  }, [Accesstoken]);

  const onchange = (e) => {
    setErr({});
    const { id, value } = e.target;
    setFormdata({ ...formdata, ...{ [id]: value } });
  };

  const submit = async () => {
    setloader(true);
    const id = LoadToast();
    let err = await validation(Object.keys(initial), formdata);

    if (Object.keys(err)?.length > 0) {
      updateToast(id, "fix");
      setErr(err);
       setloader(false);

      return;
    }
    setErr({});

    let { status, msg, token, data, VerifyStatus } = await User.Login(formdata);
    if(isEmpty(VerifyStatus)){
      updateToast(id, msg, status);

    }
    // updateToast(id, msg, status);


    if (status == "success") {
      document.cookie = "token" + "=" + token + ";" + ";path=/";
      localStorage.setItem("userInfo", data.Email);
      localStorage.setItem("logintype", "email");

      dispatch({
        type: "Register_Section",
        Register_Section: {
          User: {
            UserDetails: data,
            token: token,
          },
        },
      });
      GetUserCookieToken();
      handleLoginClose();

      navigate("/account");
      setloader(false);
    } 
    else if (VerifyStatus == false) {
      setTimeout(() => {
        toast.success("User registered but not verified....Please complete your verify process");
      }, 2000);
      setTimeout(() => {
        toast.success("OTP send to your email address");
      }, 5000);

      setTimeout(() => {
        handleotpfun();
        setotp(data);
        UserEmail(formdata?.Email);
        setErr(err);

        setloader(false);
      }, 3000);
    } 
    else {
      setErr(err);
      setloader(false);
    }
  };

  /**Google Login --- start*/
  const googlelogin = useGoogleLogin({
    onSuccess: (codeResponse) => setAccesstoken(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const GoogleAccountDetails = async (data) => {
    let accountdetails = await User.getGoogleAccountDetails(data);
    if (accountdetails) {
      const id = LoadToast();

      let Senddata = {
        FirstName: accountdetails.given_name,
        Email: accountdetails.email,
        Type: "google",
      };
      let { status, msg, token, data } = await User.Login(Senddata);
      updateToast(id, msg, status);
      if (status == "success") {
        document.cookie = "token" + "=" + token + ";" + ";path=/";
        localStorage.setItem("userInfo", data.Email);
        localStorage.setItem("logintype", "google");

        dispatch({
          type: "Register_Section",
          Register_Section: {
            User: {
              UserDetails: data,
              token: token,
            },
          },
        });
        GetUserCookieToken();
        handleLoginClose();
      } else setErr(err);
    }
  };
  /**Google Login --- end*/

  /**Facebook Login --- start*/
  const facebookLogin = (response) => {
    setIsLoggedIn(true);
  };

  const FacebookAccountDetails = async (data) => {
    setIsLoggedIn(false);
    if (data && data?.status !== "unknown") {
      const id = LoadToast();

      let Senddata = {
        FirstName: data.name,
        Email: data.email,
        Type: "facebook",
      };
      let { success, msg, token, data } = await User.Login(Senddata);
      updateToast(id, msg, success);
      if (!isEmpty(success)) {
        document.cookie = "token" + "=" + token + ";" + ";path=/";
        localStorage.setItem("userInfo", data.Email);
        localStorage.setItem("logintype", "facebook");

        dispatch({
          type: "Register_Section",
          Register_Section: {
            User: {
              UserDetails: data,
              token: token,
            },
          },
        });
        handleLoginClose();
        GetUserCookieToken();
      } else setErr(err);
    }

    // Handle the Facebook login response
    // onLogin(response);
  };

  /**Facebook Login --- end*/

  const options = {
    loop: true,
    // center: true,
    items: 1,
    margin: 20,
    autoplay: false,
    dots: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    // responsive: {
    //   0: {
    //     items: 1,
    //   },
    //   600: {
    //     items: 2,
    //   },
    //   1000: {
    //     items: 2,
    //   },
    // },
  };

  return (
    <div>
      <Modal
        show={loginShow}
        onHide={handleLoginClose}
        centered
        size="xl"
        className="custom_modal common_modal"
      >
        <Modal.Header closeButton className="p-0">
          {/* <Modal.Title>
            Modal heading
            </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row className="mx-auto">
            <Col xl={5} className="d-none d-xl-block p-0">
              <div className="login_mdl_left h-100">
                <div className="opacticy_bg">
                  {/* <OwlCarousel
                    id="customer-testimonoals"
                    className="modal_owl_carousel"
                    {...options}
                  > */}
                  <div className="h-100 d-flex flex-column justify-content-evenly align-items-center">
                    <div>
                      <h4 className="title_bold text-center">TripFi.io</h4>
                      <p className="text-center mdl_subtitle">
                        BOOK 3M+ HOTELS, FLIGHTS & ACTIVITIE WITH CRYPTO
                      </p>
                      <div className="d-flex justify-content-center">
                        <img
                          src={Image.coins}
                          alt="coin"
                          className="img-fluid coin_img"
                        />
                      </div>
                    </div>
                    <div>
                      <h5 className="acc_create mb-4">
                        Create an account or sign in to
                      </h5>
                      <div className="d-flex align-items-center gap-2 benefits mb-2">
                        <img
                          src={Image.tick}
                          alt="tick"
                          className="img-fluid"
                        />{" "}
                        <p className="mb-0">
                          Access 90+ Crypto Payment Options
                        </p>
                      </div>
                      <div className="d-flex align-items-center gap-2 benefits mb-2">
                        <img
                          src={Image.tick}
                          alt="tick"
                          className="img-fluid"
                        />{" "}
                        <p className="mb-0">Get Up To 10% Back In Rewards</p>
                      </div>
                      <div className="d-flex align-items-center gap-2 benefits mb-4">
                        <img
                          src={Image.tick}
                          alt="tick"
                          className="img-fluid"
                        />{" "}
                        <p className="mb-0">See Exclusive Member pricing</p>
                      </div>
                    </div>
                  </div>{" "}
                  {/* </OwlCarousel> */}
                </div>
              </div>
            </Col>
            <Col xs={12} xl={7} className="p-0">
              <div className="login_mdl_right">
                <h4 className="modal_title">Login</h4>
                <div className="mt-3 mt-xl-4">
                  <p className="black_label mb-2">Enter your email</p>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    className="cred_input"
                    id="Email"
                    value={formdata.Email}
                    onChange={onchange}
                  />{" "}
                  {err?.Email && (
                    <p className="text-left err_msg_txt mb-0">{err?.Email}</p>
                  )}
                </div>
                <div className="mt-3 mt-xl-4">
                  <p className="black_label">Enter your password</p>
                  <div className="cus-pwdInp">
                    <input
                      type={passwordEyeshow ? "text" : "password"}
                      placeholder="Enter your password"
                      className="cred_input"
                      id="Password"
                      value={formdata.Password}
                      onChange={onchange}
                    />
                    <span className="showHideIcon">
                      {passwordEyeshow ? (
                        <IoEyeOutline
                          onClick={() => setpasswordEyeshow(!passwordEyeshow)}
                        />
                      ) : (
                        <IoEyeOffOutline
                          onClick={() => setpasswordEyeshow(!passwordEyeshow)}
                        />
                      )}
                    </span>
                  </div>
                  {err?.Password && (
                    <p className="text-left err_msg_txt mb-0">{err?.Password}</p>
                  )}
                </div>
                <div className="d-flex justify-content-end mt-2">
                  <button
                    className="frgt_pass"
                    onClick={() => {
                      handleForgotEmailShow();
                    }}
                  >
                    Forget Password?
                  </button>
                </div>
                {loader ? (
                  <button className="themeButton my-4 disabled">
                    Processing....
                  </button>
                ) : (
                  <button className="themeButton my-4" onClick={() => submit()}>
                    Continue
                  </button>
                )}
                <div className="d-flex justify-content-center mt-3 mb-4 or_section">
                  <p className="mb-0 position-relative">OR</p>
                </div>
                <div className="d-flex align-items-center justify-content-center gap-4 sign_optn">
                  <button>
                    <img src={Image.eth} alt="eth" className="img-fluid" />
                  </button>{" "}
                  <button onClick={() => googlelogin()}>
                    <img
                      src={Image.google}
                      alt="google"
                      className="img-fluid"
                    />
                  </button>{" "}
                  <button
                    className="position-relativce login__fbLoginBtn"
                    onClick={() => facebookLogin()}
                  >
                    <img
                      src={Image.facebook}
                      alt="facebook"
                      className="img-fluid"
                    />

                    <FacebookLogin
                      appId={config?.Facebookid}
                      autoLoad={isLoggedIn}
                      fields="name,email,picture"
                      callback={FacebookAccountDetails}
                      icon="fa-facebook"
                      className="img-fluid login__fbComp"
                    />
                  </button>
                  {/* <button onClick={()=>handleLogin()}>
                    <img
                      src={Image.facebook}
                      alt="facebook"
                      className="img-fluid"
                    />
                  </button> */}
                </div>
                <p className="mt-4 black_label text-center">
                  By clicking 'Register', I agree to the Terms of <br />{" "}
                  Use and 
                  <Link className="black_link" to ="/cms?content=privacypolicy">
                  <span className="sgn_term_btn" >Privacy Policy</span>
                  </Link>
                </p>
                <p className="mt-5 text-center black_label">
                  Don't have Account?{" "}
                  <button
                    className="theme_link border-0 outline-0 bg-transparent"
                    // onClick={() => {
                    //   handleSignupShow();
                    //   handleLoginClose();
                    // }}
                    onClick={() => handleSignupShow()}
                  >
                    Sign Up
                  </button>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LoginModal;
