import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { DateTimeForm, DownloadCertificate, LoadToast, isEmpty, updateToast } from "../lib/common";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Hotels from "../Axios/hotel.axios";
import { Invoice } from "./Invoice";
import BookingConfirmation from "../components/BookingConfirmation";
import Payment from "./stripepayment";
import { useNavigate } from "react-router";


const ReservationModal = ({ show, handleClose,Data,setClient,handleStrpieShow,Paymentmethod,handleNowpayment,setBookingid,Totalamount,Commission}) => {
  
  const [loader, setLoader] = useState(false);
  const [hold_loader, sethold_Loader] = useState(false);
  const [Usdamount,setUsdamount]=useState(false)

  const { UserDetails } = useSelector((state) => state.LoginReducer.User);
  useEffect(()=>{
    Fiatcurrencies()
  },[UserDetails])

  const invoiceRef = useRef(null);
  const navigate=useNavigate()

  const Fiatcurrencies=async()=>{
    let currency = await Hotels.FiatcurrencyValue()
  let usdamount= currency?.USDT
  setUsdamount(usdamount)
  }


  const CompleteReservation=async()=>{
    const id = LoadToast();

    if(isEmpty(UserDetails)){
     return updateToast(
        id,
        'Please Login First....!',
        'error'
      );
    }
    setLoader(true)
  let datas= Data.travellerdata
  let TravellerArr=[]
  let Mailarr=[]
  let CodeArr=[]
  let ContactArr=[]
  
   datas.map((value)=>{
    TravellerArr.push({travellerInfo:value.travellerInfo})
    ContactArr.push(value?.Contact?.MobileNumber)
    Mailarr.push(value?.Contact?.Email)
    CodeArr.push(value?.Contact?.Code)
   })
  
    let sendData={
  
        "bookingId": Data?.Bookingid,
        "roomTravellerInfo": JSON.stringify(TravellerArr),
  
        "deliveryInfo": {
            "emails":Mailarr,
            "contacts": ContactArr,
            "code": CodeArr
        },
        "type": Data?.hoteldata?.pt,
        "paymentInfos":JSON.stringify([
            {
                "amount": Data?.roomsdata?.[0]?.tp
  
            }
        ]
        )
    
      
    }
  
    let Bookings = await Hotels?.Instant_Bookings(sendData);
    if(Bookings?.data?.status?.success||Bookings?.status=='success'){

      let download = await DownloadCertificate(invoiceRef);
      let adddata={
        UserId:UserDetails?._id,
        BookingId:Bookings?.data?.bookingId,
        HotelId:Data?.hoteldata?.id,
        HotelId_split:(Data?.hoteldata?.id).split('-')[1],
        RoomId:Data?.roomsdata?.[0].id,
        Address:Data?.hoteldata.ad,
        HotelName:Data?.hoteldata.name,
        Checkin:new Date(Data?.Searchdata?.checkinDate),
        Checkout:new Date(Data?.Searchdata?.checkoutDate),
        RoomsCount:Data?.roomsdata?.length,
        Images:Data?.hoteldata?.img?.[0],
        Loacation:Data?.hoteldata?.gl,
        Ratings:Data?.hoteldata?.rt,
        PaymentStatus:"Unpaid",
        BookingType:"Instant",
        BookingStatus:"Created",
        Amount:Data?.roomsdata?.[0]?.tp,
        Emails:JSON.stringify(Mailarr),
        Currency:'INR',
        Invoice:download,
        Paymentmethod:Paymentmethod,
        OptionId:Data?.hoteldata?.ops?.[0]?.id,
        Currentcurrency: 'usd',
        CurrentcurrencyValue: Usdamount,
        PenaltyDetails:JSON.stringify(Data?.hoteldata?.ops[0]?.cnp.pd),
        RoomType:Data?.hoteldata?.ops?.[0]?.ris?.[0]?.rc,
        TotalAmount:Number(Totalamount),
        Commission:Number(Commission)

      }
      let {status,data,clientsecret,msg}=await Hotels?.Add_Booking_Details(adddata)
      
      handleClose()
      setLoader(false)

      updateToast(
        id,
        msg,
        status
      );

  if (status=='success'){
    setClient(clientsecret)
    setBookingid(data?.BookingId)
    Paymentmethod=="Card"?
    handleStrpieShow()
    :
    handleNowpayment()
  } 
    
    }
    else
    {
      setLoader(false)
      Data?.setReservation(true)
      if(Bookings?.data?.errors?.[0]?.message.includes(':')){
        updateToast(
          id,
          Bookings?.data?.errors[0]?.message.split(':')[1],
          'error'
        );
      }
else if(Bookings?.data?.errors?.[0]?.message){
  // console.log("BookingsBookingsBookingstrueee");
  updateToast(
    id,
    Bookings?.data?.errors[0]?.message,
    'error'
  );

}
else{
  updateToast(
    id,
    Bookings.msg,
        'error'
  );  
}
  
    }
  
  
  }

  const HoldBooking=async()=>{
    const id = LoadToast();

    if(isEmpty(UserDetails)){
      return toast.error('Please Login First....!')
        }
        sethold_Loader(true)
    let datas= Data.travellerdata
    let TravellerArr=[]
    let Mailarr=[]
    let CodeArr=[]
    let ContactArr=[]
    
     datas.map((value)=>{
      TravellerArr.push({travellerInfo:value.travellerInfo})
      ContactArr.push(value?.Contact?.MobileNumber)
      Mailarr.push(value?.Contact?.Email)
      CodeArr.push(value?.Contact?.Code)
     })
    
      let sendData={
    
          "bookingId": Data?.Bookingid,
          "roomTravellerInfo": TravellerArr,
          "deliveryInfo": {
              "emails":Mailarr,
              "contacts": ContactArr,
              "code": CodeArr
          },
          "type": Data?.hoteldata?.pt,
      }
    
      let Bookings = await Hotels?.Hold_Bookings(sendData);
      if(Bookings?.data){
        sethold_Loader(false)      
          let adddata={
            UserId:UserDetails?._id,
            BookingId:Bookings?.data?.bookingId,
            HotelId:Data?.hoteldata?.id,
            HotelId_split:(Data?.hoteldata?.id).split('-')[1],
            RoomId:Data?.roomsdata?.[0].id,
            Address:Data?.hoteldata.ad,
            HotelName:Data?.hoteldata.name,
            Checkin:new Date(Data?.Searchdata?.checkinDate),
            Checkout:new Date(Data?.Searchdata?.checkoutDate),
            RoomsCount:Data?.Searchdata?.roomInfo.length,
            Images:Data?.hoteldata?.img[0],
            Loacation:Data?.hoteldata?.gl,
            Ratings:Data?.hoteldata?.rt,
            PaymentStatus:"Unpaid",
            BookingType:"Hold",
            BookingStatus:"Pending",
            Amount:Data.roomsdata[0].tp,
            Cancellationtime:Data.hoteldata.ops[0].cnp.pd[0],
            Emails: JSON.stringify(Mailarr),
            Currency: 'INR',
            Paymentmethod: Paymentmethod,
            OptionId: Data?.hoteldata?.ops?.[0]?.id,
            Currentcurrency: 'usd',
            CurrentcurrencyValue: Usdamount,
            PenaltyDetails: JSON.stringify(Data?.hoteldata?.ops[0]?.cnp.pd),
            TotalAmount:Totalamount,
            Commission:Commission
    



          }
          let {status,data,msg}=await Hotels?.Add_Booking_Details(adddata)

          updateToast(
            id,
            msg,
            status
          );
    
      if (status=='success'){
        navigate('/account')

    
      } 
      
      }
      else
      {
        setLoader(false)
    
        toast.error('Your Reservation Cancelled')
    
      }
  
  }



  return (
    <div>
      <Modal
        show={show}
        onHide={ loader || hold_loader ?"":handleClose}
        centered
        size="md"
        className="common_modal reservation_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Complete Reservation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-4">
          <div className="reservation_modal_details">
            <div className="">
              <p className="heading_txt mb-1">Instant Booking</p>
              <p className="description mb-0">
      If You Want Book Immediately Please Click Here....!
              </p>
              {loader?
    <Button className="cmn-btn mt-2" disabled>Submit</Button>
    :
    <Button className="cmn-btn mt-2" onClick={()=>CompleteReservation()} >Submit</Button>

              }
          

            </div>
            {

} 
      {Data?.hoteldata.ops?.[0]?.cnp.ifra >0 &&
      
    
        <div className="mt-4">
             <p className="heading_txt mb-1">Hold Booking</p>
              <p className="description mb-0">
             Hold Your Bookings From {DateTimeForm({date:new Date(Data?.hoteldata?.ops?.[0]?.cnp?.pd?.[0].fdt),words:true})} to {DateTimeForm({date:new Date(Data?.hoteldata?.ops?.[0]?.cnp?.pd?.[0].tdt),words:true})} With Free Cancellation
              </p>
{  hold_loader?            <Button className="cmn-btn mt-2" disabled>Submit</Button>
:
<Button className="cmn-btn mt-2" onClick={()=>HoldBooking()}>Submit</Button>
}            </div>}
          </div>
          {/* <div style={{ opacity: 0, position: "relative",height:'100px' } } > */}


     
 
        </Modal.Body>
      </Modal>
      <div style={{ opacity: 0, position: "relative",height:'100px' } } >
          <BookingConfirmation
                  refdata={invoiceRef} Bookingdata={Data}
                />
          </div>
    </div>
    
  );
};

export default ReservationModal;
