import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import OtpInput from 'react-otp-input';

import Image from "../components/Image";

import { LoadToast, isEmpty, updateToast, validation } from "../lib/common";
import * as User from "../Axios/user.axios";


const ResetPassword = ({ showResetPassword, handleResetPasswordClose,handleLoginShow,UserEmail}) => {

  const LoginShow = () => {
    handleResetPasswordClose();
    handleLoginShow();
  };


  const initial = {
    NewPassword:"",
    ConfirmPassword:""
  };

  const [formdata, setFormdata] = useState(initial);
  const [Err, setErr] = useState({});
  const [loader, setloader] = useState(false);


  const onchange = (e) => {
    setErr({})
    const { id, value } = e.target;
    setFormdata({ ...formdata, ...{ [id]: value } });

  };
  const submit = async () => {
    setloader(true)
    const id = LoadToast();
    let err = await validation(Object.keys(initial), formdata);
    
    if (Object.keys(err)?.length > 0) {
      updateToast(id, "fix");
      setErr(err);
      setTimeout(() => {
        setloader(false)

      }, 2000);
      return;
    }
    setErr({});
    if(formdata?.ConfirmPassword!=formdata?.NewPassword){
      updateToast(id, "Confirm password must be the same as the New password...!","error");
      setErr(err);
      setTimeout(() => {
        setloader(false)

      }, 2000);

      return;
    }

    let sendata={
      Password:formdata?.ConfirmPassword,
      Email:UserEmail
    }
    var { success, msg } = await User.ChangePassword(sendata);
    updateToast(
      id,
      msg,
      success
    );
    if (success=='success') {
      setTimeout(() => {
        LoginShow()      

      }, 2000);
      setloader(false)

    } else {
      setErr(err);
      setTimeout(() => {
        setloader(false)

      }, 2000);
    }
  };

  return (
    <div>

      <Modal
        show={showResetPassword}
        onHide={handleResetPasswordClose}
        centered
        size="lg"
        className="custom_modal signup_modal"
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <Row className="mx-auto sgn_row">
            <Col xs={12} xl={6} className="p-0">
              <div className="login_mdl_right">
                <h4 className="modal_title">Reset Password</h4>
                <p className="thm_labl mt-4 mb-2">New Password</p>
                <input
                  type="email"
                  placeholder="New Password"
                  className="cred_input mb-2"
                  id="NewPassword"
                  value={formdata?.NewPassword}
                  onChange={onchange}
                  
                />
                   {Err?.NewPassword && (
                      <p className="text-left err_msg_txt">{Err?.NewPassword}</p>
                    )}
                 <p className="thm_labl mt-4 mb-2">Confirm Password</p>
                <input
                  type="email"
                  placeholder="Confirm Password"
                  className="cred_input mb-2"
                  id="ConfirmPassword"
                  value={formdata?.ConfirmPassword}
                  onChange={onchange}
                />
                  {Err?.ConfirmPassword && (
                      <p className="text-left err_msg_txt">{Err?.ConfirmPassword}</p>
                    )}
               {" "}
{     loader?           <button className="themeButton my-4 disabled" >Processing...</button>:
 <button className="themeButton my-4" onClick={submit}>Continue</button>
}               
              </div>
            </Col>{" "}
            <Col
              xs={12}
              xl={6}
              className="p-0 d-flex align-items-center justify-content-center"
            >
              <div className="login_mdl_right no_border">
                <div className="d-flex flex-column align-items-center justify-content-center  sign_optn">
                  <p>Login With Smart way</p>
                  <div>
                    <button>
                      <img src={Image.eth} alt="eth" className="img-fluid" />
                    </button>{" "}
                    <button>
                      <img
                        src={Image.google}
                        alt="google"
                        className="img-fluid"
                      />
                    </button>{" "}
                    <button>
                      <img
                        src={Image.message}
                        alt="message"
                        className="img-fluid"
                      />
                    </button>{" "}
                    <button>
                      <img
                        src={Image.facebook}
                        alt="facebook"
                        className="img-fluid"
                      />
                    </button>{" "}
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <div className="sgn_bot_sec">
            <p className="mb-0">
            Back to login?{" "}
              <span className="sgn_term_btn" 
              onClick={LoginShow}
              >
                {" "}
                LOGIN
              </span>
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ResetPassword;
