import React, { useState, useEffect, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { LoadToast, updateToast } from "../lib/common";
import { Cancel_Booking, CancellationPolicy, CurrencyValue, Nowpayment_Refund, Strip_Refund } from "../Axios/hotel.axios";
import { Cryptorepay } from "../hooks/cryptosend";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";



const CancelModal = ({ show, handleClose, BookingData,Cancelmodeltype }) => {
  const [loader, setLoader] = useState(false);
  const [Cancellation, setCancellation] = useState([]);
  const [Paymentdetails, setPaymentdetails] = useState([]);
  const [balance, setbalance] = useState(0);
  const navigate = useNavigate();


  useEffect(() => {
    Cancellationpolicy()
  }, [])


  useEffect(() => {
    if (balance)
      PaymentDetails()
  }, [Cancellation, balance])


  const { UserDetails } = useSelector((state) => state.LoginReducer.User);


  const PaymentDetails = async () => {

    let currencyvalue = await CurrencyValue({ currency: 'INR' })

    let  value= currencyvalue?.conversion_rates?.USD * balance

    let amount = parseFloat(value) * parseFloat(BookingData?.CurrentcurrencyValue)
    setPaymentdetails(amount?.toFixed(2))

  }
  const Cancellationpolicy = async () => {

if(parseFloat(BookingData?.PenaltyDetails?.[1]?.am) < parseFloat(BookingData?.TotalAmount)){
  let refundamount = parseFloat(BookingData?.TotalAmount) - parseFloat(BookingData?.PenaltyDetails?.[1]?.am)
  let sendamount = parseFloat(refundamount) * parseFloat(BookingData?.CurrentcurrencyValue)
  setbalance(sendamount?.toFixed(2))
  setCancellation(BookingData?.PenaltyDetails)
}
else{
  setbalance(BookingData?.TotalAmount)
  setCancellation(BookingData?.PenaltyDetails)


}

  }
  const CancelBooking = async () => {
    setLoader(true)
    const id = LoadToast();
    let Cancelbooking = await Cancel_Booking({ Bookingid: BookingData?.BookingId, Userid: BookingData?.UserId })
    if (Cancelbooking?.data?.status?.success) {
      if (BookingData?.Paymentmethod == 'Card') {
        console.log("balancebalance",balance);
        var { status, data, msg } = await Strip_Refund({ Paymentid: BookingData?.PaymentIntentid,amount:balance })

      }
     else  {
      console.log("fffffffffffffRefundCrypto");
        let refund = await RefundCrypto()
        if (refund) {
          var { status, data, msg } = await Nowpayment_Refund({ Bookingid: BookingData.BookingId })

        }

      }

      updateToast(
        id,
        msg?.code?msg?.code: msg,
        status
      );
      setLoader(false)


      if (status == "success") {
        setLoader(false)
        handleClose()
        setTimeout(() => {
          // window.location.reload()
          navigate(`/account?bookingstatus=${'Cancelled'}`)

        }, 1000);
      }

    }


  }
  // console.log("PaymentdetailsPaymentdetails",Paymentdetails);
  const RefundCrypto = async () => {
    console.log("PaymentdetailsPaymentdetails",Paymentdetails,UserDetails?.WalletAddress );

    if (Paymentdetails && UserDetails?.WalletAddress) {
      let SendCrypto = await Cryptorepay({
        toaddress: UserDetails?.WalletAddress,
        amount: Paymentdetails
      })
      console.log("SendCryptoSendCrypto",SendCrypto);

      return SendCrypto
    }
    else {

      toast.error('Try Again')
    }
  }

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="md"
        className="common_modal reservation_modal"
      >
        <Modal.Header closeButton className="justify-content-center">
          <Modal.Title>Cancel</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-4">
          <div className="reservation_modal_details">
            {/* <p className="heading_txt mb-1">Cancel</p> */}

            {Cancelmodeltype !=='hold'?
                      <p className="description mb-2 text-center fw-500 f-15" style={{ fontSize: '15px' }}>
                      Are you sure,you want to cancel this booking at this moment
                      Your penalty amount is {Cancellation?.[1]?.am??0}   INR         </p>
                      :
                      <p className="description mb-2 text-center fw-500 f-15" style={{ fontSize: '15px' }}>
                      Are you sure,you want to cancel this booking at this moment
                             </p>
                      }

            <div className="d-flex align-items-center justify-content-center">
              {loader ?
                <Button className="cmn-btn mt-2" disabled>Processing...</Button>
                :
                // <Button className="cmn-btn mt-2" onClick={() => CancelBooking()} >Cancel</Button>
                <Button className="cmn-btn mt-2" onClick={() => CancelBooking()} >Confirm</Button>


              }
            </div>
          </div>




        </Modal.Body>
      </Modal>

    </div>

  );
};

export default CancelModal;
