export const allData = {
  filterData: [
    // {
    //   title: "Free Cancellation",
    // },
    {
      title: "Room only",
      value: "Room only".toLowerCase(),
      key: "roomsonly",
      operation: "==",
      type: "string",
    },
    {
      title: "Room only",
      value: "Room only".toLowerCase(),
      key: "roomsonly",
      operation: "==",
      type: "string",
    },
    // {
    //   title: "Breakfast Included",
    // },
    // {
    //   title: "All Meals Included",
    // },

  ],
  filterData1: [
    {
      title: "₹ 0 - ₹ 2000",
      value: 0,
      key: "pricepernight",
      operation: "<=",
      type: "number",
      samecond: "or",
      secondvalue: 2000,
      secondoperation: ">="
    },
    {
      title: "₹ 2000 - ₹ 4000",
      value: 2000,
      key: "pricepernight",
      operation: "<=",
      type: "number",
      samecond: "or",
      secondvalue: 4000,
      secondoperation: ">="
    },
    {
      title: "₹ 4000 - ₹ 6000",
      value: 4000,
      key: "pricepernight",
      operation: "<=",
      type: "number",
      samecond: "or",
      secondvalue: 6000,
      secondoperation: ">="
    },
    {
      title: "₹ 6000 - ₹ 8000",
      value: 6000,
      key: "pricepernight",
      operation: "<=",
      type: "number",
      samecond: "or",
      secondvalue: 8000,
      secondoperation: ">="
    },
  ],
  filterData2: [
    {
      title: "3 Star",
      value: 3,
      key: "rt",
      operation: "==",
      type: "number",
      samecond: "or",
    },
    {
      title: "4 Star",
      value: 4,
      key: "rt",
      operation: "==",
      type: "number",
      samecond: "or",
    },
    {
      title: "5 Star",
      value: 5,
      key: "rt",
      operation: "==",
      type: "number",
      samecond: "or",
    },
  ],
  filterData3: [
    {
      title: "Excellent",
      value: "Excellent",
      key: "userreview",
      operation: "==",
      type: "string",
      samecond: "or",
    },
    {
      title: "Very Good",
      value: "Very Good",
      key: "userreview",
      operation: "==",
      type: "string",
      samecond: "or",
    },
    {
      title: "Good",
      value: "Good",
      key: "userreview",
      operation: "==",
      type: "string",
      samecond: "or",
    },
  ],
  filterData4: [
    {
      title: "Hotel",
      value: "HOTEL",
      key: "pt",
      operation: "==",
      type: "string",
      samecond: "or",
    },
    {
      title: "Apartment",
      value: "APARTMENT",
      key: "pt",
      operation: "==",
      type: "string",
      samecond: "or",
    },
    {
      title: "Guest House",
      value: "GUESTHOUSE",
      key: "pt",
      operation: "==",
      type: "string",
      samecond: "or",
    },
    {
      title: "Villa",
      value: "VILLA",
      key: "pt",
      operation: "==",
      type: "string",
      samecond: "or",
    },
    {
      title: "Cruise",
      value: "CRUISE",
      key: "pt",
      operation: "==",
      type: "string",
      samecond: "or",
    },
    {
      title: "Resort",
      value: "RESORT",
      key: "pt",
      operation: "==",
      type: "string",
      samecond: "or",
    },
  ],
  filterData5: [
    {
      title: "KK Nagar",
    },
    {
      title: "West Perumal Maistry Street",
    },
    {
      title: "North Veli Street",
    },
    {
      title: "Alagar Kovil Road",
    },
    {
      title: "Tirupparankunram Road",
    },
  ],
  filterData6: [
    {
      title: "Mattuthavani Bus Terminus",
    },
    {
      title: "Madurai Railway Station",
    },
  ],
  filterData7: [
    {
      title: "Madurai Main",
    },
    {
      title: "K.Pudur",
    },
    {
      title: "Tallakulam",
    },
  ],
  filterData8: [
    {
      title: "City View",
    },
    {
      title: "Garden View",
    },
  ],
  filterData9: [
    {
      title: "Private Pool",
    },
    {
      title: "Home Theatre",
    },
    {
      title: "Jacuzzi",
    },
    {
      title: "Living Area",
    },
    {
      title: "Bathtub",
    },
    {
      title: "Cook & Butler Service",
    },
    {
      title: "Balcony",
    },
    {
      title: "Interconnected Room",
    },
    {
      title: "Fireplace",
    },
    {
      title: "Heater",
    },
    {
      title: "Kitchenette",
    },
    {
      title: "Smoking Room",
    },
  ],
  filterData10: [
    {
      title: "Fortune",
    },
    {
      title: "Marriott, Westin & LeMeridien",
    },
    {
      title: "Oyo Hotels",
    },
    {
      title: "Sterling Holiday resorts",
    },
    {
      title: "Taj",
    },
    {
      title: "Treebo Hotels",
    },
  ],
  filterData11: [
    {
      title: "Swimming Pool",
    },
    {
      title: "Wi-Fi",
    },
    {
      title: "Spa",
    },
  ],
  filterData12: [
    {
      title: "Entire Property",
    },
    {
      title: "Caretaker",
    },
    {
      title: "Instant Book",
    },
    {
      title: "Homestays",
    },
  ],
  filterData13: [
    {
      title: "Unmarried Couples Allowed",
    },
    {
      title: "Alcohol Allowed",
    },
    {
      title: "Smoking Allowed",
    },
    {
      title: "Pets Allowed",
    },
  ],

  swiperData: [
    {
      img: require("../assets/images/pie_1.png"),
    },
    {
      img: require("../assets/images/pie_2.png"),
    },
    {
      img: require("../assets/images/pie_3.png"),
    },
    {
      img: require("../assets/images/pie_4.png"),
    },
  ],
};
