const Image = {
  coins: require("../assets/images/threeimage.png"),
  tick: require("../assets/images/tick2.png"),
  eth: require("../assets/images/eth.png"),
  google: require("../assets/images/google.png"),
  message: require("../assets/images/message.png"),
  facebook: require("../assets/images/facebook.png"),
  bannerImg: require("../assets/images/banner.png"),
  dining: require("../assets/images/dining.svg").default,
  highlights: require("../assets/images/highlights.svg").default,
  rooms: require("../assets/images/rooms.svg").default,
  location: require("../assets/images/location.svg").default,
  bed: require("../assets/images/bed.svg").default,
  window: require("../assets/images/window.svg").default,
  smoothie: require("../assets/images/smoothie.svg").default,
};

export default Image;
