import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import * as Hotels from '../Axios/hotel.axios'
import Payment from '../modals/Nowpayment'
import { Decryptdata, LoadToast, updateToast } from "../lib/common";
import { socket } from "../hooks/socket";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import CustomLoader from "../components/CustomLoader";

const NowpaymentModal = ({
  show,
  handleClose,
  Data,
  Bookingid,
  Amount,
  Pricecurrency,
}) => {
  const [loader, setLoader] = useState(false);
  const [PaymetDetails,setPaymentsDetails]=useState('')
  const [PaymentShow,setPaymentShow]=useState(false)
  const [Usdamount,setUsdamount]=useState(false)

  let navigate=useNavigate()

useEffect(()=>{
  localStorage.setItem("payment","stripe")

  Fiatcurrencies()
},[])


  useEffect(() => {
    socket.on("User connected", (value) => {
      let RespData=Decryptdata(value)
      console.log("RespDataRespData",RespData);
      // && Bookingid==RespData?.data?.BookingId
      if(RespData?.status =='success' ){

      setTimeout(() => {
        window.close()

      }, 1000);
      setPaymentShow(false)

      // navigate(`/account?bookingstatus=${RespData?.data?.BookingStatus}`)

      }
      else{
        setTimeout(() => {
          toast.error(RespData?.msg)

        }, 1000);
        setPaymentShow(false)


      }

    })
  }, [socket])

  /** to get currency value inr to usd */
const Fiatcurrencies=async()=>{
  let currency = await Hotels.CurrencyValue({currency:'INR'})
let usdamount= currency?.conversion_rates.USD*Amount
setUsdamount(usdamount)
}

  const Paymentsfun = async () => {
      setLoader(true)
      const id = LoadToast();
    let {status,msg,data}=await Hotels.createNowpayment({
         PriceAmount:Usdamount,
         Pricecurrency:Pricecurrency,
         orderId:Bookingid
        })
         updateToast(
          id,
          msg,
          status
        );
if(status=='success'){
  setPaymentsDetails(data)
  setPaymentShow(true)
}
setLoader(false)


 
  };
  useEffect(()=>{
    if(PaymetDetails?.invoice_url){
    let tag = document.createElement('a')
tag.setAttribute("href",PaymetDetails?.invoice_url)
tag.setAttribute("target",'_blank')
tag.click()

    }
  },[PaymetDetails])



  // console.log("invoice_urlinvoice_url",PaymetDetails);

  return (
    <div>
      <Modal
        show={show}
        // onHide={!PaymentShow?handleClose:""}
        centered
        size={PaymetDetails?.pay_address ? "lg" : "md"}
        className="common_modal reservation_modal"
      >
        <Modal.Header  className="justify-content-center">
          {PaymetDetails?.invoice_url ? (
            <></>
          ) : (
            <Modal.Title className="text-center">Now Payment</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body className="pb-4">
{   PaymetDetails?.invoice_url&&PaymentShow ?     
//  <Payment Data={PaymetDetails} /> 
<div className="reservation_modal_details">
<div className="payment_proceed">
<h5 className="description mb-0 fw-700 text-center">
Please don't refresh or close the page </h5>
<h5 className="description mb-0 fw-700 text-center">
Your payment has been processing....</h5>
<CustomLoader/>
</div>
</div>
:
 <div className="reservation_modal_details">
          <p className="description mb-0 text-center">
Make sure you want to pay with crypto?              </p>
<div className="d-flex align-items-center justify-content-center mt-2 mb-4">
          <Button className={`cmn-btn pay-btn btn btn-primary text-uppercase ${loader ? 'disabled cmn-btn mt-2 mb-4' : ""}`}
           onClick={() => !loader? Paymentsfun() : null}>Confirm</Button>
</div>
          
          </div>   
          }


     
 
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NowpaymentModal;
