import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Col, Container, Row, Form, ProgressBar } from "react-bootstrap";

import 'react-phone-number-input/style.css'
import * as CMS from "../Axios/cms.axios";
import { isEmpty } from "../lib/common";
import NodataFound from "../components/NodataFound";
import { useLocation } from "react-router";



export default function CancellationPolicy() {
  const [cancellationpolicy,setcancellationpolicy]=useState()
  const [faqdata,setfaqdata]=useState([])

  const location = useLocation();

  const searchParams = new URLSearchParams(location?.search);
  const content = searchParams.get("content");

useEffect(()=>{
  if(content){
    Getcmsdata()

  }
},[])

const Getcmsdata =async()=>{
  let { status, data } = await CMS.GetCms({Type:content});
  if(status =='success'){
    setcancellationpolicy(data)

  }
  else{
    setcancellationpolicy([])

  }
 
}
console.log("cancellationpolicycancellationpolicy",cancellationpolicy);
  return (
    <>
      <Header />
{!isEmpty(cancellationpolicy)  ?   
 <div className="search-page particular-room-details">
        <div className="search-header">
          <div className="container">
           <p className="filter_text mb-0">{cancellationpolicy?.Heading}</p>
          </div>
        </div>
        <Container className="pt-5 pb-4">
        <p className="cms_desc mt-0 mb-0">
        {cancellationpolicy?.Content}
</p>
          
        </Container>
        </div>
        :
        <div className="cancellationPolicy_noData_found">
        <NodataFound/>
        </div>
        }


        {/* <Container className="pt-5 pb-4">
        <p className="cms_heading mt-0 mb-3">Trip Secure – Terms and conditions</p>

<p className="cms_desc mt-0 mb-0">
The Trip Secure can be availed by the customers of TripFi in order to safeguard their travel.
</p>
<p className="cms_desc mt-0 mb-1">Trip Secure can be purchased while making a domestic hotel booking through the TripFi Platform. You get the following benefits:</p>
<p className="cms_desc_lit mt-0 mb-1 mt-3 ms-3">Assistance services provided by GLB Assure Private Limited.</p>
<p className="cms_desc_lit mt-0 mb-1 ms-3">Travel Insurance as a complimentary in-built product underwritten by Aditya Birla Health Insurance Co. Limited.</p>
<p className="cms_desc mt-0 mb-1 mt-3">Insurance coverage will be complimentary. Assistance services and insurance coverages are valid for the actual trip duration. If the customer cancels Trip Secure, consisting of assistance services, the complementary insurance benefit will also stand cancelled.
</p>
      <p className="cms_heading_2 mt-3">Assistance services provided by GLB Assure Private Limited consists of following benefits:</p>
      <p className="cms_heading_3">Emergency Cash Assistance ( Upto ₹ 4000 )</p>
      <p className="cms_heading_3">What is covered:</p>
      <p className="cms_desc mt-0 mb-2 ms-3">Any advance amount taken by the customer up to the amount as per the product purchased, in case of lost wallet only.</p>
      <p className="cms_heading_3 mt-3">What is not covered:</p>
      <p className="cms_desc mt-0 mb-2 ms-3">In case customer doesn’t have FIR copy of lost wallet, customer will not be eligible for this benefit.</p>
      <p className="cms_heading_3 mt-3">Things to keep handy to avail the service:</p>
      <p className="cms_desc mt-0 mb-2 ms-3">Toll free number & registered mobile number for verification purpose.</p>
      <p className="cms_heading_3 mt-3">How to make use of the services:</p>
      <p className="cms_desc_lit mb-2 ms-3">Customers call the dedicated hotline number of GLB Assure sent via confirmation email.</p>
        
      <p className="cms_desc_lit mb-2 ms-3">GLB Assure agent answers the call via hotline and validates the mobile number and policy coverage.</p>
       
       <p className="cms_desc_lit mb-2 ms-3">Post validation of the policy coverage, GLB Assure agent will assess customer’s issue and assist the customer with their service request.</p>
        <p className="cms_desc_lit mb-2 ms-3">The Customer shall be eligible for emergency cash assistance services only when the customer has reported the loss of wallet and filed an FIR while travelling out of his registered city of residence.</p>
       <p className="cms_desc_lit mb-2 ms-3">GLB Assure shall, at its discretion facilitate through its network providers, cash advance, physically or through online transfer basis the location to the customer to safeguard the necessary expenses up to the limits as per the product purchased till the customer safely reaches the city of residence.</p>
       <p className="cms_desc_lit mb-2 ms-3">The customer shall sign such documents acknowledging receipt of the advance at the time such advance is made by GLB Assure or through any of its network providers.</p>
       <p className="cms_desc_lit mb-2 ms-3">Additionally, there might be situations when it will not be possible to arrange for the transfer of funds to some remote geographical locations or due to events or conditions that are out of GLB Assure’s control (i.e., political instability, regulatory restrictions, war, natural disaster, etc.) and for which GLB Assure/TripFi cannot be held liable.</p>
       <p className="cms_desc_lit mb-2 ms-3">Any advance made under Emergency cash assistance shall be interest free and repayable by the customer within twenty-eight (28) days of the advance being made. In the event of the advance not being repaid, then steps will be taken to recover the advance, the costs of the recovery may be added to the outstanding balance of the advance made.</p>
       <p className="cms_desc_lit mb-2 ms-3">GLB assure will follow up with customers for repayment, and customer transfers the amount to GLB account or wallet for which details will be shared then with the customer.</p>


      <p className="cms_heading_3 mt-3">TATs:</p>
      <p className="cms_desc_lit mb-2 ms-3">Case registration: Right on the first call from the customer within 10 minutes.</p>
      <p className="cms_desc_lit mb-2 ms-3">Agent allocation: 15 Minutes (In case of offline cash assistance).</p>
      <p className="cms_desc_lit mb-2 ms-3">Delivery of cash: 90-120 Minutes (In case of offline cash assistance).</p>
      <p className="cms_desc_lit  mb-2 ms-3">Online Transfer: Within 1 hour.</p>

      <p className="cms_heading_3 mt-3">Single call to block lost cards</p>
      <p className="cms_heading_3 mt-3">What is covered:</p>
      <p className="cms_desc  mb-2 ms-3">Assistance in blocking all the lost cards of the customer in one go.</p>
      <p className="cms_heading_3 mt-3">What is not covered:</p>
      <p className="cms_desc  mb-2 ms-3">Any other reason apart from lost wallet is not covered.</p>
      <p className="cms_heading_3 mt-3">Things to keep handy to avail the service:</p>
      <p className="cms_desc  mb-2 ms-3">Toll free number & registered mobile number for verification purpose.</p>
      <p className="cms_heading_3 mt-3">How to make use of the services:</p>
      <p className="cms_desc_lit  mb-2 ms-3">Customers call the dedicated hotline number of GLB Assure sent via confirmation email.</p>
      <p className="cms_desc_lit  mb-2 ms-3">GLB Agent answers the call via hotline and validates the registered mobile number and policy coverage.</p>
      <p className="cms_desc_lit  mb-2 ms-3">Post validation of the policy coverage, GLB Assure agent will assess customer’s issue and assist the customer with their service request.</p>
      <p className="cms_desc_lit  mb-2 ms-3">The customer must notify the (Lost/stolen cards) within 24 hours of discovering the loss of cards.</p>
      <p className="cms_desc_lit  mb-2 ms-3">The customer must file a Police report within 6 hours from the discovery of the incident.</p>
      <p className="cms_desc_lit  mb-2 ms-3">Upon meeting the above requirements, the GLB Assure will call the customer’s authorised bank customer care and notify them to cancel the cards that were reported as lost.</p>
      <p className="cms_desc_lit  mb-2 ms-3">In one single call, customer can block all the cards from multiple banks in one go.</p>
      <p className="cms_desc_lit  mb-2 ms-3">Our agent takes customer on the fly while speaking to bank executives, for customer verification and card blocking.</p>


      <p className="cms_heading_3 mt-3">Medical Assistance: (Arranging doctor teleconsultation)</p>
     
      <p className="cms_heading_3 mt-3">What is covered:</p>
      <p className="cms_desc  mb-2 ms-3">Online doctor consultation (audio/video) with General Physician or Specialist Doctor. This facility is available on GLB Assure mobile application only. Prescription gets generated over app.</p>
      
      <p className="cms_heading_3 mt-3">What is not covered:</p>
      <p className="cms_desc  mb-2 ms-3">Cost of service is not covered by GLB Assure.</p>
      <p className="cms_heading_3 mt-3">Things to keep handy to avail the service:</p>
      <p className="cms_desc  mb-2 ms-3">Toll free number, registered mobile number for verification purpose & GLB Assure mobile app.</p>
      <p className="cms_heading_3 mt-3">How to make use of the services:</p>
      <p className="cms_desc_lit  mb-2 ms-3">Customer downloads GLB Assure app via link shared via confirmation email (OR).</p>
        
      <p className="cms_desc_lit  mb-2 ms-3">Customers call the dedicated hotline number of GLB Assure sent via confirmation email.</p>
      <p className="cms_desc_lit  mb-2 ms-3">GLB Agent answers the call via hotline and validate the registered mobile number and policy coverage.</p>
      <p className="cms_desc_lit  mb-2 ms-3">Customer will be assisted to navigate the GLB app wherein he/she can book the Doctor Consultation.</p>
      <p className="cms_desc_lit  mb-2 ms-3">The user will have the option of booking the consultation either immediately or as per the available time slot.</p>
       
      <p className="cms_heading_3 mt-3">Medical Assistance: (Arranging ambulance)</p>
      <p className="cms_heading_3 mt-3">What is covered:</p>
      <p className="cms_desc  mb-2 ms-3">GLB Assure will facilitate in providing assistance for finding the ambulance services. Customer will be eligible for this service only in case of life-threatening situations.</p>
       
      <p className="cms_heading_3 mt-3">What is not covered:</p>
      <p className="cms_desc  mb-2 ms-3">Ambulance service charges.</p>
      <p className="cms_heading_3 mt-3">Things to keep handy to avail the service:</p>
      <p className="cms_desc  mb-2 ms-3">Toll free number & registered mobile number for verification purpose.</p>
      
      <p className="cms_heading_3 mt-3">How to make use of the services:</p>
      <p className="cms_desc_lit  mb-2 ms-3">Customers call the dedicated hotline number of GLB Assure sent via confirmation email.</p>
      <p className="cms_desc_lit  mb-2 ms-3">Upon validation of the policy coverage, GLB Assure will assess the customer's issue and will assist the customer with arranging ambulance service.</p>
      <p className="cms_desc_lit  mb-2 ms-3">GLB Assure will be a facilitator for aiding in finding the ambulance and assist the customer in arranging the ambulance service to the nearest hospital.</p>
      <p className="cms_desc_lit  mb-2 ms-3">The customer will be eligible for this service only in the case of life-threatening situations.</p>
      <p className="cms_desc_lit  mb-2 ms-3">GLB Assure/TripFi is not responsible for any health condition of the customer.</p>
      <p className="cms_heading_3 mt-3">The Travel Insurance as a complimentary inbuilt product underwritten by Aditya Birla Health Insurance Co. Limited consists of following benefits:</p>
      <p className="cms_heading_4 mt-3">a) Inclusions</p>
      <div className="table-responsive">
      <table class="table table-bordered table_cms">
      <thead>
    <tr>
      <th scope="col">S.No.</th>
      <th scope="col">Benefits</th>
      <th scope="col">Coverage Amount (Upto)</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1</td>
      <td>In-patient care for illness and injury with day care treatment</td>
      <td>₹ 300000</td>
    </tr>

    <tr>
      <td>2</td>
      <td>Out-patient Care</td>
      <td>₹ 15000</td>
    </tr>

    <tr>
      <td>3</td>
      <td>Hotel over booking</td>
      <td>₹ 15000</td>
    </tr>
    

    <tr>
      <td>4</td>
      <td>Hotel/private rental cancellation</td>
      <td>₹ 12500</td>
    </tr>

    <tr>
      <td>5</td>
      <td>Missed Event</td>
      <td>₹ 12500</td>
    </tr>

    <tr>
      <td>6</td>
      <td>Gadget cover</td>
      <td>₹ 15000</td>
    </tr>

    <tr>
      <td>7</td>
      <td>Sports Injury</td>
      <td>₹ 75000</td>
    </tr>

    <tr>
      <td>8</td>
      <td>Digital Camera Insurance</td>
      <td>₹ 20000</td>
    </tr>

    <tr>
      <td>9</td>
      <td>Trip Delay (More than 6 hrs.)</td>
      <td>₹ 12500</td>
    </tr>

    <tr>
      <td>10</td>
      <td>Sports Equipment Cover</td>
      <td>₹ 10000</td>
    </tr>

    <tr>
      <td>11</td>
      <td>Trip cancellation/Trip Interruption/Trip Curtailment</td>
      <td>₹ 3000</td>
    </tr>
  
  </tbody>

</table>
      </div>
      <p className="cms_desc  mb-2">UIN Number: ADITGBP23002V012223</p>
      <p className="cms_heading_4 mt-3">b) Conditions applicable to this cover</p>
      <p className="cms_desc_lit  mb-2 ms-3">The Person should be below the age of 60 years.</p>
      <p className="cms_desc_lit  mb-2 ms-3">Travel insurance will be applicable for Indian residents only.</p>
      <p className="cms_desc_lit  mb-2 ms-3">The policy is valid only for customers of TripFi.</p>
      <p className="cms_heading_4 mt-3">c) Exclusions:</p>
      <p className="cms_desc_lit  mb-2 ms-3">Any Pre-existing medical conditions and its related complication/consequences.</p>
      <p className="cms_desc_lit  mb-2 ms-3">Travelling against the advice of Physician.</p>
      <p className="cms_desc_lit  mb-2 ms-3">Self-Quarantine.</p>
      <p className="cms_desc_lit  mb-2 ms-3">Quarantine/ Isolation Advised by any authorized testing center.</p>
      <p className="cms_desc_lit  mb-2 ms-3">Quarantine / Isolation at any authorized center.</p>
       <p className="cms_desc_lit  mb-2 ms-3">Self-Isolation during lockdown.</p>
       <p className="cms_desc  mb-2 mt-3">For detailed T&C of travel insurance, customer can refer to the wordings.</p>
       <p className="cms_desc  mb-2">An email with booking confirmation will be sent in the name of the individual as registered with TripFi, on his/her email id.</p>
        
          </Container> */}
      <Footer />




     
    </>
  );
}
