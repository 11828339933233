import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Dropdown from "react-bootstrap/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/esm/Button";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from "react-router-dom";


import Image from "../components/Image";
import { Col, Container, Row, Form, ProgressBar } from "react-bootstrap";
import Select from "react-select";

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import { BiRightArrowAlt } from "react-icons/bi";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";
import { allData } from "../Data/CommonData";

import InclusionModal from "../modals/InclusionModal";
import InformationModal from "../modals/InformationModal";

import Paymenttab from "../components/Paymenttab";


import { FaPrint, FaDownload  } from "react-icons/fa";

import CouponCard from "../components/couponCard";

import bookroom from "../assets/images/book/bookroom.png"
import payman from "../assets/images/book/pay_man.png"
import img_full from "../assets/images/book/img_full.png"
import tickpurple from "../assets/images/book/tick_purple.png"
import tickgrey from "../assets/images/book/tick_grey.png"
import bedicon from "../assets/images/book/bed_icon.png"
import pricetagicon from "../assets/images/book/pricetag_icon.png"
import calendaricon from "../assets/images/book/calendar_icon.png"




import successdollar from "../assets/images/book/successdollar.png"
import successtick from "../assets/images/book/successtick.png"
import dangertimer from "../assets/images/book/dangertimer.png"
import payment from "../assets/images/book/payment.png"
import roomicon from "../assets/images/book/room_icon.png"
import tagicon from "../assets/images/book/tag_icon.png"
import usericon from "../assets/images/book/user_icon.png"




import trip from "../assets/images/book/trip.png"
import verified from "../assets/images/book/verified.png"
import ConfirmphoneModal from "../modals/ConfirmphoneModal";


export default function ConfirmBooking() {

  const [phoneShow, setPhoneShow] = useState(false);
  const handlePhoneClose = () => setPhoneShow(false);
  const handlePhoneShow = () => setPhoneShow(true);

  return (
    <>
      <Header />
      <div className="search-page particular-room-details">
        <div className="search-header">
          <div className="container">
           <p className="filter_text mb-0">Booking Confimed</p>
          </div>
        </div>
       
       
     

        <Container>
     
          <Row className="justify-content-center mt-4 mb-2">
            <Col lg={8}>

                
            <div className="card card_book card_book_badge mb-3">
                <div className="badge_purple_card">Worry Free Hotel Stay!</div>
                <Row className="p-3 mt-5 mt-sm-3">
                <Col sm={3} md={3} className=" d-flex align-items-center justify-content-center">
                        <img src={payman} className="img-fluid" />
                        </Col>
                    <Col md={9} lg={9} sm={9} className="mt-3 mt-sm-0">
                    <p className="black_bold_text mt-0 mb-1">Trip Secure | One plan, Many benefits</p>

              <p className="book_text_light mt-0 mb-0">
              Hassle free stay. Get upto: Rs. 10K for hotel booking cancellation charges if cancelled due to illness, Rs. 20K for bounced booking, 24x7 emergency medical assistance, Rs. 20K for loss of tablets and laptops, Rs. 20K for loss of baggage, Rs. 10K for emergency travel of family members. T&Cs apply.
              </p>
                    </Col>
                 
                </Row>
                </div>


                <div className="card card_book mb-3 card_book_more_radius pb-5">
                
               
                <p className="black_bold_text mt-3 mb-3 px-3 mt-3">Your booking in Dubai confirmed</p>
          
                <p className="d-flex align-items-start px-3">
                    <img src={tickpurple} className="me-2 mt-1" />

                    <span className="tick_list_p">Your Confirmation is on it’s way to your@mail.com</span>

                </p>
      

      <div className="d-flex align-items-center px-3 flex_col_mob">
      <button type="button" class="pay-btn btn btn-primary me-3">
        <FaPrint className="me-2"/>
        Print Full Version</button>
      <button type="button" class="pay-btn btn btn-primary me-0" onClick={handlePhoneShow}>
        <FaDownload className="me-2" />
        Save Confirmation to Phone</button>

      </div>

      <p className="black_bold_text mt-3 mb-3 px-3 mt-4">Your Booking Summary</p>
<div className="px-3">
<Row>
    <Col md={2} lg={3} xl={2}>
        <img src={bookroom} />
    </Col>
    <Col md={6} lg={6} xl={6} className="mt-3 mt-md-0">
    <h2 className="rd__blackTitle m-0 text-left rd__blackTitle_sm">
            Heritage Dubai
              </h2>
              <div className="mt-3">
              <div className="d-flex align-items-start justify-content-start mb-2">
                <img src={pricetagicon} className="book_icons mt-1" />
                <div>
                <p className="black_bold_text_ssm mt-1 mb-0 ms-3">Total price: Approx BDT 7,716</p>
            {/* <p className="book_text_light">2 Adults</p> */}
            </div>
              </div>

              <div className="d-flex align-items-start justify-content-start mb-2">
                <img src={calendaricon} className="book_icons mt-1" />
                <div>
                <p className="black_bold_text_ssm mt-0 mb-0 ms-3">Fri, 29 Mar'24 12:00PM to Fri, 30 Mar'24 12:00PM</p>
            </div>
              </div>


              <div className="d-flex align-items-start justify-content-start mb-3">
                <img src={bedicon} className="book_icons mt-1" />
                <div>
                <p className="black_bold_text_ssm mt-0 mb-0 ms-3">King Bed</p>

            </div>
              </div>

          
            </div>
    </Col>
    <Col md={4} lg={12} xl={4}>
    <Link to="/reviewBooking" type="button" class="pay-btn btn btn-primary px-2 mt-3">
    View and Update Details
</Link>
    </Col>
</Row>


<Row className="align-items-center mt-4">
    <Col md={2} lg={3} xl={2}>
        <img src={bookroom} />
    </Col>
    <Col md={6} lg={6} xl={6}>
  
    <p className="black_bold_text_ssm mt-1 mb-0">Get a better room for just BDT 1,343.53</p>
            
    </Col>
    <Col md={4} lg={12} xl={4}>
    <Link to="/search" type="button" class="pay-btn-outline btn btn-primary-outline px-2 mt-3">
    Show your Option
</Link>
    </Col>
</Row>
          
</div>


             

            

         
              </div>
              

           
             
           
            </Col>
            <Col lg={4} className="mt-3 mt-lg-0">




            <div className="card_purple_info mb-2">
                    <p className="mb-0">Confirmation number: <span>215619813206549</span></p>
                    <p className="mb-0">PIN: <span>2563</span></p>
                </div>
               
          
        
          <div className="bg_green px-3 mb-3">
            <div className="d-flex align-items-center">
              <img src={payment} />
              <span>Free cancellation till 11:59 AM, 29 Mar 2024</span>
            </div>

          </div>

            <div className="card card_book mb-3 card_book_more_radius">
             

             

            <div className="px-3">
            <p className="black_bold_text mb-3 mt-3">Manage your trip with the app</p>

            

            <p className="d-flex align-items-start">
                    <img src={tickgrey} className="me-2 mt-1" />

                    <span className="tick_list_p">Access 90+ Crypto Payment Options</span>

                </p>


                <p className="d-flex align-items-start">
                    <img src={tickgrey} className="me-2 mt-1" />

                    <span className="tick_list_p">Get Up To 10% Back In Rewards</span>

                </p>


                <p className="d-flex align-items-start">
                    <img src={tickgrey} className="me-2 mt-1" />

                    <span className="tick_list_p">See Exclusive Member pricing</span>

                </p>
          
            </div>

             

            

         
              </div>


           

          
          
           


          



           
            
                </Col>
          </Row>

        </Container>
      </div>
      <Footer />



      {phoneShow &&
 <ConfirmphoneModal
 phoneShow={phoneShow}
 handlePhoneClose={handlePhoneClose}
/>
}
     
    </>
  );
}
