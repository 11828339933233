import React, { useState,useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";

import { FaCheck } from "react-icons/fa";

import bathroom_icon from "../assets/images/bathroom_icon.png"
import bedroom_icon from "../assets/images/bedroom_icon.png"
import tv_icon from "../assets/images/tv_icon.png"
import wifi_icon from "../assets/images/wifi_icon.png"
import more_icon from "../assets/images/more_icon.png"

import drink from "../assets/images/book/drink.png"
import * as Hotels from "../Axios/hotel.axios";
import { isEmpty } from "../lib/common";
import CustomLoader from "../components/CustomLoader";





const AmenitiesModal = ({ amenitiesShow, handleAmenitiesClose, Hotelid}) => {
  const [HotelData, setHotelData] = useState(false);
  useEffect(()=>{
    HotelDetails()
  },[])

  const HotelDetails=async()=>{

    let { success,data } = await Hotels.Hotel_Details({'id':Hotelid});
  
    setHotelData(data)
  
  }


  return (
    <div>
      
      <Modal
        show={amenitiesShow}
        onHide={handleAmenitiesClose}
        centered
        size="md"
        className="common_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title> Hotel Amenities</Modal.Title>
      </Modal.Header>
        <Modal.Body className="pb-4">
{          console.log("HotelDataHotelData",!isEmpty(HotelData))
}         
{/* {HotelData?.length>0&&    */}
 <div className="row mb-3">
        <div className="col-12 col-sm-12 col-md-12">
            <p className="amenitied_head mb-2"><img src={bathroom_icon} className="me-2" />Amenities</p>
        </div>
        {!isEmpty(HotelData)?
          <div className="col-12 col-sm-12 col-md-12">
          { HotelData?.hotel?.fl?.length > 0 ?
            HotelData?.hotel?.fl?.slice(0,20)?.map((item) =>
              <p className="mb-1 f-12 fw-400 list_tick">
                <FaCheck size={11} className="green_check" />
                <span className="ps-1">{item}</span>
              </p>

            )
            :
            <p className="mb-1 f-12 fw-400 list_tick">
              <span className="ps-1">No Aminities</span>
            </p>

          }

    </div>
    :
    <>{CustomLoader()} </>
        }
          
    </div>
    {/* } */}

    {/* <div className="row mb-3">
        <div className="col-12 col-sm-12 col-md-12">
            <p className="amenitied_head"><img src={bedroom_icon} className="me-2" />Bedroom</p>
        </div>
        <div className="col-12 col-sm-12 col-md-12">
        <p className="mb-1 f-12 fw-400 list_tick">
                    <FaCheck size={11} className="green_check"/>
                    <span className="ps-1">Air Conditioning</span>
                  </p>

               
        </div>
    </div>

    <div className="row mb-3">
        <div className="col-12 col-sm-12 col-md-12">
            <p className="amenitied_head"><img src={tv_icon} className="me-2" />Entertainment</p>
        </div>
        <div className="col-12 col-sm-12 col-md-12">
        <p className="mb-1 f-12 fw-400 list_tick">
                    <FaCheck size={11} className="green_check"/>
                    <span className="ps-1">TV</span>
                  </p>

               
        </div>
    </div>

    <div className="row mb-3">
        <div className="col-12 col-sm-12 col-md-12">
            <p className="amenitied_head"><img src={wifi_icon} className="me-2" />Internet</p>
        </div>
        <div className="col-12 col-sm-12 col-md-12">
        <p className="mb-1 f-12 fw-400 list_tick">
                    <FaCheck size={11} className="green_check"/>
                    <span className="ps-1">Free Wi-fi</span>
                  </p>

               
        </div>
    </div>

    <div className="row mb-3">
        <div className="col-12 col-sm-12 col-md-12">
            <p className="amenitied_head"><img src={more_icon} className="me-2" />More</p>
        </div>
        <div className="col-12 col-sm-12 col-md-12">
        <p className="mb-1 f-12 fw-400 list_tick">
                    <FaCheck size={11} className="green_check"/>
                    <span className="ps-1">Desk</span>
                  </p>

                  <p className="mb-1 f-12 fw-400 list_tick">
                    <FaCheck size={11} className="green_check" />
                    <span className="ps-1">Safe</span>
                  </p>

                  <p className="mb-1 f-12 fw-400 list_tick">
                    <FaCheck size={11} className="green_check"/>
                    <span className="ps-1">Soundproofing</span>
                  </p>

                 
        </div>
    </div> */}
        
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AmenitiesModal;
