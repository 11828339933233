import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaTwitter } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import Button from "react-bootstrap/esm/Button";
import { Getsociallinks } from "../Axios/cms.axios";
import { LoadToast, updateToast, validation } from "../lib/common";
import * as User from "../Axios/user.axios";


function Footer() {
  const PaymentType = [
    {
      img: require("../assets/images/footer/visa.png"),
    },
    {
      img: require("../assets/images/footer/master-card.png"),
    },
    {
      img: require("../assets/images/footer/american.png"),
    },
    {
      img: require("../assets/images/footer/stripe.png"),
    },
    {
      img: require("../assets/images/footer/crypto_com.png"),
    },
    {
      img: require("../assets/images/footer/solar.png"),
    },
    {
      img: require("../assets/images/footer/binance-pay.png"),
    },
    {
      img: require("../assets/images/footer/wechat-pay.png"),
    },
  ];
  const initial = {
    Email: ""
 
  };
  const [formdata, setFormdata] = useState(initial);
  const [loader, setloader] = useState(false);


  const [err, setErr] = useState({});

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getsociallinks();
  }, []);

  const [SocialList, setSocialList] = useState([]);

  const getsociallinks = async () => {
    var resp = await Getsociallinks({});
    if (resp.status == 'success') {
      setSocialList(resp?.data);
    }
  };
  const onchange = (e) => {
    setErr({});
    const { id, value } = e.target;
    setFormdata({ ...formdata, ...{ [id]: value } });
  };
const submit=async()=>{
  setloader(true);
  const id = LoadToast();
  let err = await validation(Object.keys(initial), formdata);

  if (Object.keys(err)?.length > 0) {
    updateToast(id, "fix");
    setErr(err);
     setloader(false);

    return;
  }
  let { status, msg,data } = await User.Subscribers(formdata);
  updateToast(id, msg, status);
  setFormdata(initial)

  console.log(
    "datadatadata",status
  );

}


  return (
    <div className="footer">
      <div className="container custom-container">
        <div className="row mb-3 mb-xl-4">
          <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3 mb-xl-0">
            <div className="payment-types">
              <h2 className="mb-0 fw-800 mb-3">TripFi.io</h2>
              <p className="mb-0 f-15 fw-600">
                We accept Credit Card, Debit Card and Cryptocurrency payments.
              </p>
              <div className="row mt-4">
                {PaymentType.map((item, index) => {
                  return (
                    <div className="col-3 col-xl-3 mb-2 px-1">
                      <img
                        src={item.img}
                        className="img-fluid payment-type-img"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-2 mb-3 mb-xl-0">
            <div className="">
              <h6 className="heading fw-700 mb-3">SUPPORT</h6>
              <div className="sub-heading">
                <p className="mb-4">
                  <Link to="">Help Center</Link>
                </p>
                <p className="mb-4">
                  <Link to="">My Trip</Link>
                </p>
                <p className="mb-4">
                  <Link to="">Payment Options</Link>
                </p>
                <p className="mb-0">
                  <Link to="/cms?content=termsandconditions">Terms & Conditions</Link>
                </p>
              </div>
            </div>
          </div>


          <>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-2 mb-3 mb-xl-0">
              <div className="">
{         SocialList?.length > 0&& <h6 className="heading fw-700 mb-3">COMMUNITY </h6>
}                
{
SocialList?.length > 0 &&
                  SocialList?.map((data) => (
                    <div className="sub-heading">

{/* <p className="mb-3">
                          <Link to={data?.Link} target="_blank" >
                            <span className="social-icon me-2">
                              <FaTwitter />
                            </span>
                            Twitter
                          </Link>
                        </p> */}



                      {data?.Website == 'Twitter'
                        && <p className="mb-3">
                          <Link to={data?.Link} target="_blank" >
                            <span className="social-icon me-2">
                              <FaTwitter />
                            </span>
                            Twitter
                          </Link>
                        </p>}
                      {data?.Website == 'Facebook'
                        && <p className="mb-3">

                          <Link to={data?.Link} target="_blank" >
                            <span className="social-icon me-2">
                              <FaFacebookF />
                            </span>
                            Facebook
                          </Link>
                        </p>}
                      {data?.Website == 'Telegram' &&
                        <p className="mb-3">
                          <Link to={data?.Link} target="_blank" >
                            <span className="social-icon me-2">
                              <FaTelegramPlane />
                            </span>
                            Telegram
                          </Link>
                        </p>}
                      {data?.Website == 'Instagram' &&
                        <p className="mb-0">
                          <Link to={data?.Link} target="_blank" >
                            <span className="social-icon me-2">
                              <FaInstagram />
                            </span>
                            Instagram
                          </Link>
                        </p>}
                    </div>
                  ))}
              </div>
            </div>
          </>

          <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <div className="search-sec">
              <div className="d-flex align-items-center gap-4">
                <img
                  src={require("../assets/images/footer/newsletter.png")}
                  className="img-fluid newsletter"
                />
                <div className="">
                  <p className="fw-600 mb-0 f-15">Save time, save money!</p>
                  <p className="fw-600 mb-0 f-15">
                    Sign up and we'll send the best deals to you
                  </p>
                </div>
              </div>
              <div className="subsc-search">
                <input  id="Email" type="email" placeholder="Your email" onChange={onchange} value={formdata?.Email}/>
                <Button className="subscribe-btn" onClick={submit}>Subscribe</Button>
              </div>
            </div>
            {err?.Email && (
                    <p className="text-left err_msg_txt mb-0">{err?.Email}</p>
                  )}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <hr className="foot-hr" />
        </div>
        <div className="post-footer">
          <p className="mb-0 text-center">© Copyright 2024. TripFi</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
