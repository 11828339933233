import React from "react";
import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/Card";
import { MdArrowForwardIos } from "react-icons/md";
import { Link } from "react-router-dom";

function CommonCard({ countryImg, logo, name, stays, footerbg, blog }) {
  return (
    <div className="country-card">
      <div className="common-card">
        <Card>
          <Card.Img src={countryImg} className="img-fluid country-bg" />
          {!blog ? (
            <>
              <Card.Body
                className={
                  footerbg == "black"
                    ? "d-flex align-items-center justify-content-between bg-black"
                    : "d-flex align-items-center justify-content-between"
                }
              >
                <div className="d-flex align-items-center">
                  <img src={logo} className="img-fluid country-logo" />
                  <span className="ms-2 fw-600 f-15">{name}</span>
                </div>
                <span className="fw-600 f-15">{stays}</span>
              </Card.Body>
            </>
          ) : (
            <>
              <Card.Body className={footerbg == "black" ? "bg-black" : ""}>
                <div className="d-flex align-items-center justify-content-between">
                  <span className="ms-2 fw-600 f-15">{name}</span>
                  <Link to="" className="blog-card-nav">
                    <span className="ms-2 fw-600 f-15">
                      <MdArrowForwardIos />
                    </span>
                  </Link>
                </div>
              </Card.Body>
            </>
          )}
        </Card>
      </div>
    </div>
  );
}

export default CommonCard;
