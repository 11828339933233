import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./Redux/store";
import { GoogleOAuthProvider } from '@react-oauth/google';
import FacebookLogin from 'facebook-login-react';

//  export const responseFacebook = (response) => {
//   console.log("responseresponse",response);
//       // Handle the Facebook login response
//       // onLogin(response);
//   };



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId="867471842785-8sccbhsvi3lhl0718amlmbgbmqmsokr3.apps.googleusercontent.com">

  <React.StrictMode>

  <Provider store={store}>

      <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      
      theme="colored"
      
      toastStyle={{
        marginRight:'10px',
        width:'300px',
        border:'1px',
        borderRadius:'10px'
      }}
    />
    <App />
    </Provider>
    </React.StrictMode>
    </GoogleOAuthProvider>


);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
