import React, {
    forwardRef,
    useEffect,
    useImperativeHandle
} from 'react';

import $ from 'jquery';
import { useSelector } from 'react-redux';
import { FaHeart } from 'react-icons/fa';
import {  Add_LikeAction, Get_Likelist } from '../Axios/hotel.axios';
import { toast } from 'react-toastify';

export const LikeRef = forwardRef((props, ref) => {

    const { UserDetails } = useSelector((state) => state.LoginReducer.User);

    useEffect(() => {
        getLikesDataCall()
    }, [UserDetails]);

    async function getLikesDataCall () {
        if(UserDetails?._id !== "") {
            var senddata = {
                Userid: UserDetails?._id,
                type:'user'
            }
            var check = await Get_Likelist(senddata);
            if(check) {
                props?.LikeList(check?.data?check?.data:[]);
            }
        }
    }

    useImperativeHandle(
        ref,
        () => ({
            async getLikesData() {
                getLikesDataCall();
            },
            async hitLike(data) {
                if (UserDetails._id !== "") {
                {
                    var likeData = {
                        // actions:"like",
                        Userid: UserDetails._id,
                        Hotelid: (data?.id).split('-')[1],
                        WholeHotelid:data?.id,
                        Address:data?.ad,
                        HotelName:data?.name,
                        Ratings:data?.rt,
                        Images:data?.img?.[0],
                        activity:"Liked by",
                        Amount:data?.pops?.[0]?.tpc,
                    }
                    var resp = await Add_LikeAction(likeData);
                    if(resp) {
                        if(resp.status === 'success') {
                                             
                                $('#'+resp?.data?.HotelId
                                +'-likecount').html(resp?.data?.LikesCount);

                        
                            
                        }
                    }
                    getLikesDataCall();
                    return resp?.likeOrUnlike;
                }
                
                }
                else{
                    toast.error('Please Login First')
                }
            
            }
        }),
    )
    return (
<></>
    )
})

