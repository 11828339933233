import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";

import { FaCheck } from "react-icons/fa";

import meal from "../assets/images/book/meals.png";
import drink from "../assets/images/book/drink.png";
import ParticularImagePreview from "./ParticularImagePreview";

const PhotoModal = ({ photoShow, handlePhotoClose, images, Hotels }) => {
  const [Hotelimages, setHotelimages] = useState([]);
  const [prevImage, setPrevImage] = useState(false);
  const [Image, setImage] = useState('');



  const handlePreviewShow = (data) => {
    setImage(data)
    setPrevImage(true);

  };
  const handlePreviewClose = () => {
    setPrevImage(false);
  };
  return (
    <div>
      <Modal
        show={photoShow}
        onHide={handlePhotoClose}
        centered
        size="xl"
        className="common_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{Hotels?.hotel?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-4">
          <div className="roolDetails_allPhotos">
            {images?.map((item, index) => (
              <img
                src={item?.url}
                className="img-fluid photo"
                onClick={()=>handlePreviewShow(item.url)}
              />
            ))}
          </div>
        </Modal.Body>
      </Modal>

{  prevImage&&Image&&
    <ParticularImagePreview
        show={prevImage}
        handleClose={handlePreviewClose}
        image={Image}
      />}
    </div>
  );
};

export default PhotoModal;
