import React, { useState } from "react";
import {  Col, Modal, Row } from "react-bootstrap";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import OtpInput from "react-otp-input";
import { LoadToast, isEmpty, updateToast, validation } from "../lib/common";
import * as User from "../Axios/user.axios";
import Countdown from "react-countdown";
import { useEffect } from "react";


const Otpmail = ({
  showOtpEmail,
  handleOtpEmailClose,
  showResetPassword,
  UserEmail,
  Registerotp,
  handlelogin,
  Time
}) => {
  const initial = {
    OTP: "",
    Email: UserEmail,
  };
  const [formdata, setFormdata] = useState(initial);
  const [err, setErr] = useState({});
  const [loader, setloader] = useState(false);
  const [registerotp, setRegisterotp] = useState(Registerotp??'');
  const [resendloader, setresendloader] = useState(false);
  const [counter, setCounter] = useState(60);


console.log("Timeddddddd",Time);


// useEffect(() => {

//     // const timer =
//       // counter > 0 &&
//       setInterval(
//         () => {

//             setCounter(counter + 1);
          
//         },

//         1000
//       );

//     const seconds = String(counter % 60).padStart(2, 0);
//     const minutes = String(Math.floor(counter / 60)).padStart(2, 0);
//     // SetTimer({
//     //   Minutes: minutes,
//     //   Seconds: seconds
//     // })
//     // return () => clearInterval(timer);
  
//   // }
// }, [counter]);



  const submit = async () => {
    setloader(true);
    const id = LoadToast();
    let err = await validation(Object.keys(initial), formdata);

    if (Object.keys(err)?.length > 0) {
      updateToast(id, "fix");
      setErr(err);
      setloader(false);

      return;
    }
    setErr({});
    if (!isEmpty(registerotp)) {
      if (registerotp == formdata?.OTP) {
        var { success, msg } = await User.Statusupdate({
          Email: UserEmail,
          Status:{VerifyStatus: true},
        });
        setloader(false);

        updateToast(id, "OTP Verified Successfully", "success");
        if (success) {
          handlelogin(true);
          handleOtpEmailClose();
        }
      } else {
        updateToast(id, "Please Enter Valid OTP", "error");
        setFormdata(initial);
        setloader(false);
      }
    }
     else {
      var { success, msg } = await User.VerifyOTP
      (formdata);
      updateToast(id, msg, success);
      if (!isEmpty(success)) {
        setloader(false);

        setTimeout(() => {
          showResetPassword();
        }, 2000);
      } else {
        setFormdata(initial);

        setloader(false);
        setErr(err);
      }
    }
  };

  const Resendotp = async () => {
    setresendloader(true)
    const id = LoadToast();
    // let err = await validation(Object.keys(initial), formdata);
    // if (Object.keys(err)?.length > 0) {
    //   updateToast(id, "fix");
    //   setErr(err);
    //   return;
    // }
    // setFormdata(initial);
    // setErr({});

    var { success, msg,data } = await User.SendOtp({Email:UserEmail});
    setresendloader(false)

    setRegisterotp(data)
    updateToast(id, msg, success);
  };

  const Completionist = () => <span>You are good to go!</span>;

  const renderer = ({ hours, minutes, seconds }) => {
      return (
        <span className="counttimer">
          {hours}h:{minutes}m:{seconds}s
        </span>
      );
  }
  return (
    <div>
      <Modal
        show={showOtpEmail}
        onHide={handleOtpEmailClose}
        centered
        size="md"
        className="otp_modal common_modal"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title></Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row className="mx-auto sgn_row">
            <Col xs={12} xl={12} className="p-0">
              <div className="login_mdl_right">
                <h4 className="modal_title text-center">OTP</h4>
                <p className="thm_labl mt-4 mb-0 text-center">Enter OTP</p>
                <div className="otp_input mt-3">
                  <OtpInput
                    id="OTP"
                    value={formdata?.OTP}
                    onChange={(e) =>
                      setFormdata({ ...formdata, ...{ ["OTP"]: e } })
                    }
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                  />
                  
                </div>{" "}
                {err?.OTP && (
                    <p className="text-left err_msg_txt text-center mb-0">{err?.OTP}</p>
                  )}
                {/* <button className="themeButton my-4" onClick={showOtpEmail}>Continue</button> */}
                <div className="d-flex align-items-center justify-content-center">
                  {loader ? (
                    <button className="themeButton mt-4 disabled w-auto">
                      Processing...
                    </button>
                  ) : (
                    <button
                      className="themeButton my-4 w-auto f-14 px-4 py-2"
                      onClick={() => submit()}
                    >
                      Continue
                    </button>
                  )}
                </div>
              </div>
            </Col>
          </Row>


{/* 
          <Countdown
                                  date={
                                 new Date(   new Date().setMinutes(
                                    new Date().getMinutes()+5
                                    ))
                                    
                                  }
                                  // autoStart={true}
                                  renderer={renderer}
                                /> */}

          { (
            <div className="sgn_bot_sec">
   {           
   !resendloader?
   
   <p className="mb-0">
                Can't get OTP?{" "}
                <span className="sgn_term_btn" onClick={() => Resendotp()}>
                  {" "}
                  RESEND
                </span>
              </p>
              :
              <p className="mb-0">
              Can't get OTP?{" "}
              <span className="sgn_term_btn">
                {" "}
                RESEND
              </span>
            </p>
              }
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Otpmail;
