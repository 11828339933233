export const allData = {
  popular: [
    {
      title: "Free Cancellation",
    },
    {
      title: "Private tours only",
    },
    {
      title: "Skip The Line",
    },
    {
      title: "Excellent rate",
    },
  ],
  price: [
    {
      title: "₹ 0 - ₹ 2000",
    },
    {
      title: "₹ 2000 - ₹ 4000",
    },
    {
      title: "₹ 4000 - ₹ 6000",
    },
    {
      title: "₹ 6000 - ₹ 8000",
    },
    {
      title: "₹ 8000 - ₹ 15000",
      status: "inactive",
    },
  ],
  duration: [
    {
      title: "0 - 3 hours",
    },
    {
      title: "3 - 5 hours",
    },

    {
      title: "5 - 7 hours",
      status: "inactive",
    },
    {
      title: "Full day",
      status: "inactive",
    },
    {
      title: "Multi-Day",
      status: "inactive",
    },
  ],
  userRating: [
    {
      title: "Excellent: 4.2+",
    },
    {
      title: "Very Good: 3.5+",
    },
    {
      title: "Good: 3+",
    },
  ],
  activitiesType: [
    {
      title: "Yard",
    },
    {
      title: "Car",
    },
    {
      title: "Truckking",
    },
  ],
  popularTourist: [
    {
      title: "KK Nagar",
    },
    {
      title: "West Perumal Maistry Street",
    },
    {
      title: "North Veli Street",
    },
    {
      title: "Alagar Kovil Road",
    },
    {
      title: "Tirupparankunram Road",
    },
  ],
  nearHub: [
    {
      title: "Mattuthavani Bus Terminus",
    },
    {
      title: "Madurai Railway Station",
    },
  ],
  topAreas: [
    {
      title: "Madurai Main",
    },
    {
      title: "K.Pudur",
    },
    {
      title: "Tallakulam",
    },
  ],
  activityViews: [
    {
      title: "City View",
    },
    {
      title: "Garden View",
      status: "inactive",
    },
  ],
  activityAmenties: [
    {
      title: "Private Pool",
    },
    {
      title: "Home Theatre",
    },
    {
      title: "Jacuzzi",
    },
    {
      title: "Living Area",
    },
    {
      title: "Bathtub",
    },
    {
      title: "Cook & Butler Service",
    },
    {
      title: "Balcony",
    },
    {
      title: "Interconnected Room",
    },
    {
      title: "Fireplace",
    },
    {
      title: "Heater",
    },
    {
      title: "Kitchenette",
    },
    {
      title: "Smoking Room",
    },
  ],
  guestLove: [
    {
      title: "Swimming Pool",
    },
    {
      title: "Wi-Fi",
    },
    {
      title: "Spa",
    },
  ],
  houseRules: [
    {
      title: "Unmarried Couples Allowed",
    },
    {
      title: "Alcohol Allowed",
    },
    {
      title: "Smoking Allowed",
    },
    {
      title: "Pets Allowed",
    },
  ],
  swiperData: [
    {
      img: require("../assets/images/pie_1.png"),
    },
    {
      img: require("../assets/images/pie_2.png"),
    },
    {
      img: require("../assets/images/pie_3.png"),
    },
    {
      img: require("../assets/images/pie_4.png"),
    },
  ],
};
