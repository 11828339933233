import React, { useEffect, useState } from "react";
import RoomCard from "./RoomCard";
import cardImg1 from "../assets/images/account/card/card-bg1.png";
import cardImg2 from "../assets/images/account/card/card-bg2.png";
import { useSelector } from "react-redux";
import { Get_Likelist } from "../Axios/hotel.axios";
import { Button } from "react-bootstrap";
import NodataFound from "./NodataFound";
function Wishlist() {
  const [wishlistTabs, setWishlistTabs] = useState("stays");
  const [wishlist, setWishlist] = useState(
    { loader: true, page: 1, list: [] }
    
  );

  const { UserDetails } = useSelector((state) => state.LoginReducer.User);

  useEffect(()=>{
  Wishlistdata()
},[])



const LoadMore =()=>{
  wishlist.page = wishlist.page + 1;
  setWishlist(wishlist);
  Wishlistdata(wishlist.page)

}
const Wishlistdata=async(page)=>{
  if(UserDetails?._id !== "") {
    var senddata = {
        Userid: UserDetails?._id,
        type:'user',
        limit:10,
        skip:((page ? parseInt(page) : 1) - 1) * 10


    }
    var {status,data} = await Get_Likelist(senddata);
    if(data){
      setWishlist({
        ...wishlist,
        ...{
            list:[...wishlist?.list,...data],
            loader:data?.length == 0 ? false : true,
            page: wishlist?.page,
        
               },
        
      });
  
    }

  }
   
}

  return (
    <div className="whishlist">
      <p className="mb-0 fw-700 purple">My WishList</p>
      <hr className="wishlist-hr" />
      <div className="myWishlist-tabs mt-3">
        <div className="tabs-headings">
          <span
            className={wishlistTabs == "stays" ? "name active" : "name"}
            onClick={() => setWishlistTabs("stays")}
          >
            Stays
          </span>
          {/* <span
            className={wishlistTabs == "activities" ? "name active" : "name"}
            onClick={() => setWishlistTabs("activities")}
          >
            Activities
          </span> */}
        </div>
        <div className="tbs-content">
          {
          // wishlistTabs == "stays" ? (
            <div className="tbs-item mt-4">
              {/* <p className="f-13 gray2 fw-500">2 property saved</p> */}
              <div className="row mt-3">
                {wishlist?.list?.length>0?wishlist?.list?.map((item, index) => {
                  return (
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4 mb-3">
                      <RoomCard Hoteldata={item} />
                    </div>
                  );
                }):
                <NodataFound/>}
              </div>
            </div>

          }
        </div>
      {wishlist?.loader&&  
      <div className="book-now-btn mt-3 d-flex align-items-center justify-content-center">
            <Button onClick={()=>LoadMore()} className='cmn-btn px-4' style={{fontWeight:600}}>Load More</Button>
          </div>}
      </div>
    </div>
  );
}

export default Wishlist;
