 import { AppenData, axiosFunc } from "../lib/common"
import config from '../lib/config'
/**Add User Ratings Policy For Hotels*/
export const AddUserRatingsandreviews = async (data) => {
    try {
        let formdata = AppenData(data)


        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/activities/ratingsandreviwes`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
   

      
}

/**Get User Ratings Policy */

export const GetUserRatingsandreviews = async (data) => {
    try {
        let formdata = AppenData(data)


        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/activities/getreviews`,
            'data': formdata[0],
            'headers': {
                'contentype':'application/json',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
   

      
}
/** To get Overall Reviews For show*/

export const GetOverallreviews = async (data) => {
    try {
        let formdata = AppenData(data)


        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/activities/getallreviews`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
   

      
}