import axios from "axios"
import { AppenData, EncryptData, axiosFunc } from "../lib/common"
import config from "../lib/config"
import { useSelector } from "react-redux";

var token ;

export const GetUserCookieToken = () => {

    document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=')
        if(parts[0] == "token"){
            token = decodeURIComponent(parts[1])
        } 

      }, '');
}
GetUserCookieToken()

/**Search Countires and cities (Static Data) */
export const SerachCountryandCities = async (data) => {
    try {
        let datas = EncryptData(data)

        let SendData = {
            'method': 'GET',
            'url': `${config.Back_Url}/front/stays/searchcountry`,
            'params': {data:datas},
            'headers': {
                'contentype':'application/json',
              }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
   

      
}
/**Search AvailableHotel Details */
export const Available_Hotel_Search = async (data) => {
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/stays/hotelsearchlist`,
            'data': formdata[0],
            
            'headers': {
                'Content-Type': 'multipart/form-data',

            }

        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        console.log("Available_Hotel_Search_ERR",error);
        return error

    }
   

      
}


/**Search AvailableHotel Details */
export const Hotel_Details = async (data) => {
        // let datas = EncryptData(data)


        try {
            let formdata = AppenData(data)
    
            let SendData = {
                'method': 'POST',
                'url': `${config.Back_Url}/front/stays/hoteldetails`,
                'data': formdata[0],
                
                'headers': {
                    'Content-Type': 'multipart/form-data',
                }
            }
            let Resp = await axiosFunc(SendData)
    
            return Resp
    
    
        } catch (error) {
            console.log("Hotel_Details_ERR",error);
            return error
    
        }

}
/**Cancellation Policy */
export const CancellationPolicy = async (data) => {


        try {
            let formdata = AppenData(data)
    
            let SendData = {
                'method': 'POST',
                'url': `${config.Back_Url}/front/stays/cancellationpolicy`,
                'data': formdata[0],
                
                'headers': {
                    'Content-Type': 'multipart/form-data',
                }
            }
            let Resp = await axiosFunc(SendData)
    
            return Resp
    
    
        } catch (error) {
            console.log("ConcellationPolicy_ERR",error);
            return error
    
        }





}
/**Add User Ratings Policy For Hotels*/

export const AddUserRatingsandreviews = async (data) => {
    try {
        let formdata = AppenData(data)


        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/stays/ratingsandreviwes`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
                'Authorization': token

                
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
   

      
}
/**Get User Ratings Policy */

export const GetUserRatingsandreviews = async (data) => {
    try {
        let formdata = AppenData(data)


        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/stays/getreviews`,
            'data': formdata[0],
            'headers': {
                'contentype':'application/json',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
   

      
}

/** To get Overall Reviews For show*/
export const GetOverallreviews = async (data) => {
    try {
        let formdata = AppenData(data)


        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/stays/getallreviews`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
   

      
}
/** Guest Details for booking */
export const AddBookingGuestDetails =async(data)=>{

    try {
        let formdata = AppenData(data)


        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/stays/addbookingguest`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }

}

/** Guest Review for booking */

export const ReviewBooking=async(data)=>{
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/stays/reviewbooking`,
            'data': formdata[0],
            
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        console.log("ReviewBooking_ERR",error);
        return error

    }


}
/**Instant Boooking */

export const Instant_Bookings=async(data)=>{


    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/stays/instantbooking`,
            'data': formdata[0],
            
            'headers': {
                'Content-Type': 'multipart/form-data',
                'Authorization': token

            }
        }
        let Resp = await axiosFunc(SendData)
        console.log("Respssss",Resp);
        return Resp
    
    
    } catch (error) {
        console.log("Instant_Bookings_ERR",error);
        return error
    
    }




}
/**Hold Boooking */

export const Hold_Bookings=async(data)=>{


    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/stays/holdbooking`,
            'data': formdata[0],
            
            'headers': {
                'Content-Type': 'multipart/form-data',
                'Authorization': token

            }
        }
        let Resp = await axiosFunc(SendData)
    
        return Resp
    
    
    } catch (error) {
        console.log("Instant_Bookings_ERR",error);
        return error
    
    }    


}
/**Hold Boooking Confirm */

export const Hold_Bookings_Confirm=async(data)=>{

    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/stays/holdbookingconfirm`,
            'data': formdata[0],
            
            'headers': {
                'Content-Type': 'multipart/form-data',
                'Authorization': token

            }
        }
        let Resp = await axiosFunc(SendData)
    
        return Resp
    
    
    } catch (error) {
        console.log("Instant_Bookings_ERR",error);
        return error
    
    } 


}

/**Add Boooking Details */

export const Add_Booking_Details=async(data)=>{

    try {
        let formdata = AppenData(data)


        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/stays/addbookingdetails`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
                'Authorization': token

            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
}
/**Get Boooking Details */

export const Get_Booking_Details_Local=async(data)=>{

    try {
        let formdata = AppenData(data)


        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/stays/getbookingdetails`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
}

/**Hold Boooking */

export const Get_Booking_Details_API=async(data)=>{

}
/**Update Boooking Details */

export const Update_Booking_Details=async(data)=>{

    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/stays/updatebooking`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        console.log("Update_Booking_Details_ERR",error);
        return false

    }
}
/**Cancel Boooking */

export const Cancel_Booking=async(data)=>{


    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/stays/bookingcancel`,
            'data': formdata[0],
            
            'headers': {
                'Content-Type': 'multipart/form-data',
                'Authorization': token

            }
        }
        let Resp = await axiosFunc(SendData)
    
        return Resp
    
    
    } catch (error) {
        console.log("Instant_Bookings_ERR",error);
        return error
    
    } 

}

/** AddLikeAction */

export const Add_LikeAction =async(data)=>{
    try {
        let formdata = AppenData(data)


        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/stays/likeaction`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
                'Authorization': token

            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        console.log('AddLikeAction_err',error);
        return false

    }
}
/** Get LikeList */

export const Get_Likelist =async(data)=>{
    try {
        let formdata = AppenData(data)


        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/stays/likelist`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        console.log('AddLikeAction_err',error);
        return false

    }
}

/** Image Download */
export const Hotel_Img_Download =async(data)=>{
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/stays/imgdownload`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        console.log('AddLikeAction_err',error);
        return false

    }
}
/** Search Bookings Details*/
export const Search_Booking =async(data)=>{
    try {
        // console.log("dataaaadata",data);

        let formdata = AppenData(data)
        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/stays/searchbooking`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        console.log('Search_Booking_err',error);
        return false

    }
}

/** Booking Room Details*/
export const Get_Booking_Hotel_Details =async(data)=>{

    try {
        // console.log("dataaaadata",data);

        let formdata = AppenData(data)
        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/stays/getbookingsromms`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        console.log('Search_Booking_err',error);
        return false

    }

}

/**Refund For Booking Cancel -Stripe*/
export const Strip_Refund =async(data)=>{

    try {
        // console.log("dataaaadata",data);

        let formdata = AppenData(data)
        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/stripe/refund`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        console.log('Search_Booking_err',error);
        return false

    }

}
/**Refund For Booking Cancel */
export const CurrentCountry =async(data)=>{

    try {

        let SendData = {
            'method': 'get',
            'url': `https://ipinfo.io?token=${"42f5398874771c"}`,

        }
        let Resp = await axios(SendData)

        return Resp


    } catch (error) {
        console.log('Search_Booking_err',error);
        return false

    }

}
/**To Get country details*/
export const GetCountry =async(data)=>{

    try {
        let formdata = AppenData(data)
        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/stays/getcountry`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        console.log('Search_Booking_err',error);
        return false

    }

}

/**To Create Nowpayments*/

export const createNowpayment=async(data)=>{

    try {
        let formdata = AppenData(data)
        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/nowpayment/create-payment`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        console.log('Search_Booking_err',error);
        return false

    }

}
/** Available currencies for payment*/
export const Availablecurrency=async()=>{

    try {
        let SendData = {
            'method': 'GET',
            'url': `${config.Back_Url}/front/nowpayment/availablecurrency`,
            'headers': {
                'Content-Type': 'application/json',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        console.log('Search_Booking_err',error);
        return false

    }

}
/** To Get Current Crypto Balance*/
export const CurrencyValue=async({currency})=>{

    try {
        let SendData = {
            'method': 'GET',
            'url': `https://v6.exchangerate-api.com/v6/0943dbd8b9e7f1ebf5a79c9f/latest/${currency}`,
            'headers': {
                'Content-Type': 'application/json',
            }
        }
        let Resp = await axios(SendData)

        return Resp?.data


    } catch (error) {
        console.log('Search_Booking_err',error);
        return false

    }

}


/** */
export const Nowpayment_Refund =async(data)=>{

    try {
        // console.log("dataaaadata",data);

        let formdata = AppenData(data)
        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/nowpayment/refund`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        console.log('Search_Booking_err',error);
        return false

    }

}

export const Nowpaymentdetails = async (data) => {
console.log("config.Nowpayment_Api_keyconfig.Nowpayment_Api_key",data,config.Nowpayment_Api_key);
// 6404390296

    try {

        let SendData = {
            'method': 'GET',
            'url': `https://api-sandbox.nowpayments.io/v1/payment/${data}`,
            
            'headers': {
                'x-api-key':config.Nowpayment_Api_key
            }
        }
        let Resp = await axios(SendData)

        return Resp


    } catch (error) {
        console.log("Nowpaymentdetails_ERR",error);
        return error

    }





}


export const FiatcurrencyValue=async()=>{

    try {
        let SendData = {
            'method': 'GET',
            url: `https://min-api.cryptocompare.com/data/price?fsym=${"USD"}&tsyms=${"USDT"}`,
            'headers': {
                'Content-Type': 'application/json',
            }
        }
        let Resp = await axios(SendData)

        return Resp?.data


    } catch (error) {
        console.log('Search_Booking_err',error);
        return false

    }

}

export const Commissions =async(data)=>{

    try {
        // console.log("dataaaadata",data);

        let formdata = AppenData(data)
        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/stays/commissions`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        console.log('Search_Booking_err',error);
        return false

    }

}