import React, { useState } from "react";
import roomPhoto from "../assets/images/account/bookings/room-img.png";
import map from "../assets/images/account/bookings/map.png";
import calendar from "../assets/images/account/bookings/datePicker1.png";
import { Button } from "react-bootstrap";
import { FaStar } from "react-icons/fa";
import Dropdown from "react-bootstrap/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateTimeForm, Decryptdata, LoadToast, NightdaysCalculation, isEmpty, updateToast } from "../lib/common";
import ViewMapModal from "../modals/ViewMapModal";
import { Add_Booking_Details, Cancel_Booking, Hold_Bookings_Confirm, Update_Booking_Details } from "../Axios/hotel.axios";
import CancelModal from "../modals/CancelModel";
import BookingModal from "../modals/BookingModal";
import StripepaymentModal from "../modals/Strpepaymentmodel";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import RefundModal from "../modals/RefundpolicyModal";
import WalletaddressModal from "../modals/WalletadressModal";
import OtpModal from "../modals/Otpmodel";
const  BookingRoomCard =({ Hoteldata, Status, BookingDetailsfun,stripe })=> {
  const [viewMapShow, setViewMapShow] = useState(false);
  const [CancelShow, setCancelShow] = useState(false);
  const [BookingShow, setBookingShow] = useState(false);
  const [Bookingid, setBookingid] = useState('');
  const [Stripepay,SetStripepay] = useState(false);
  const [hold_loader, sethold_Loader] = useState(false);
  const [cancelmodeltype, setcancelmodaltype] = useState('');
  const [RefundPolicy, setRefundPolicyShow] = useState(false);
  const [Walletaddressmodal, setWalletaddressModalShow] = useState(false);
  const [BookingData, setBookingData] = useState('');
  const [Otpmodal, setOtpmodal] = useState('');


  const handleMapShow = () => setViewMapShow(true);
  const handleMapclose = () => setViewMapShow(false);
  const handleCancelShow = (data,type) =>{ 
    setCancelShow(true);
    setBookingShow(false)
    setBookingData(data)
    setcancelmodaltype(type)    
  }
  const handleCancelClose = () => setCancelShow(false);
  const handleBookingShow = (data) => {
    setBookingShow(true); 
    setBookingid(data?.BookingId)
  }
  const handleBookingClose = () => setBookingShow(false);
  const handleStrpieClose = () => {
    SetStripepay(false)
  }
  const handleStrpieShow = () => {
    SetStripepay(true)
  }
  const handleRefundShow = () => {
    setRefundPolicyShow(true)
  }
  const handleotpmodalShow = () => {
    setOtpmodal(true)
    setWalletaddressModalShow(false)
  }
  const { UserDetails } = useSelector((state) => state.LoginReducer.User);



  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <FaStar
          key={i}
          className={rating >= i && "star-filled"}
          size={12}
        />
      );
    }
    return stars;

  }


  const HoldBookingConfirm=async()=>{
    console.log("Your Reservation CancelledYour Reservation Cancelled",);
    const id = LoadToast();
    sethold_Loader(true)      

  if(isEmpty(UserDetails)){
    return toast.error('Please Login First....!')
      }

  
    let sendData={
  
        "bookingId": Hoteldata?.BookingId,

        "paymentInfos": [
            {
                "amount": Hoteldata?.Amount
  
            }
        ]
  
      
    }
  
    let Bookings = await Hold_Bookings_Confirm(sendData);
    if(!Bookings?.data){
      sethold_Loader(false)

      handleBookingClose()
      handleStrpieShow()
      // sethold_Loader(false)      

      //   let {status,data,msg}=await Add_Booking_Details(adddata)


        updateToast(
          id,
          "Payment Initialaition Successfully",
          'success'
        );
        
    // if (status=='success') {
    //   toast.success('Your Reservation Completed Successfully')
    //   // handleClose()
    
    // }
    // else{
    //   toast.error('Your Reservation Cancelled')
    
    // }
    
    }
    else
    {
      sethold_Loader(false)
  
      updateToast(
        id,
        "Your Reservation Cancelled",
        'error'
      );  
    }

}



  return (
    <div className="bookingRoom-card">
      <div className="row">
        <div className="col-12 col-sm-5 col-md-4 col-lg-4 col-xl-4">
          <div className="room-photo">
            <img src={Hoteldata?.Images ? Hoteldata?.Images?.url : roomPhoto} className="img-fluid" alt="room-img" />
            {<Button className="booked-btn">{Status}-{`Paid with ${Hoteldata?.Paymentmethod} `}</Button>}          
            </div>
        </div>
        <div className="col-12 col-sm-7 col-md-5 col-lg-5 col-xl-5">
          <div className="roomDetail">
            <p className="mb-1 fw-700 header-txt">{Hoteldata?.HotelName}</p>
            <div className="ratings">
              {renderStars(Hoteldata?.Ratings)}
            </div>
            <div className="address mt-2">
              <img src={map} className="img-fluid" />
              <span className="add-txt fw-600">
                {Hoteldata?.Address.adr} {Hoteldata?.Address?.adr?.city?.name}

              </span>
              -<span className="see-map fw-700" onClick={handleMapShow}>See Map</span>
            </div>
            <p className="bookingCode fw-600 f-11 mt-2 mb-0">
              Booking Code: {Hoteldata?.BookingId}
            </p>
            <p className="HotelCnfm fw-600 f-11 mt-2 mb-0">
              Booking Status: {Status}
            </p>
            <div className="datePicking mt-2">
              <Dropdown className="cmn-CheckInOut checkin">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="cmn-heading"
                >
                  <div className="d-flex align-items-center gap-2">
                    <img src={calendar} className="img-fluid calendar-logo" />
                    <div className="d-flex flex-column align-items-start">
                      <p className="mb-0 f-8 fw-500 check-txt">Check In</p>
                      <p className="mb-0 f-10 fw-700 date-txt">{DateTimeForm({ date: Hoteldata?.Checkin, words: true })}</p>
                    </div>
                  </div>
                </Dropdown.Toggle>

              </Dropdown>
              <Dropdown className="cmn-CheckInOut checkout">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="cmn-heading"
                >
                  <div className="d-flex align-items-center gap-2">
                    <img src={calendar} className="img-fluid calendar-logo" />
                    <div className="d-flex flex-column align-items-start">
                      <p className="mb-0 f-8 fw-500 check-txt">Check Out</p>
                      <p className="mb-0 f-10 fw-700 date-txt">{DateTimeForm({ date: Hoteldata?.Checkout, words: true })}</p>
                    </div>
                  </div>
                </Dropdown.Toggle>

              </Dropdown>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <div className="roomPrice bdr-left">
            <div>
              <p className="mb-2 mb-md-4 pb-2 stay-day">{NightdaysCalculation(Hoteldata?.Checkin, Hoteldata?.Checkout)}Night, {Hoteldata?.RoomsCount} Rooms </p>
              <p className="mb-3 mb-md-0 text-center amt">INR ₹{Hoteldata?.Amount}</p>
            </div>
            {Status == 'Pending' &&
              <Button className="bookCnfm-btn cmn-purple-btn"  onClick={() => handleBookingShow(Hoteldata)} >Booking Confirmed</Button>
            }
            {Status == 'Completed' &&
              <Button className="bookCnfm-btn cmn-purple-btn" onClick={() => Hoteldata?.Paymentmethod=='Card'? handleCancelShow(Hoteldata,"booked"):handleRefundShow()} >Cancel</Button>
              // <Button className="bookCnfm-btn cmn-purple-btn" onClick={() => handleCancelShow(Hoteldata)} >Cancel</Button>

            }
          </div>
        </div>


        {
          viewMapShow &&
          <ViewMapModal
            viewMapShow={viewMapShow}
            handleViewMapClose={handleMapclose}
            location={Hoteldata?.Loacation}
          />
        }

                {
          CancelShow && BookingData&&
          <CancelModal
          show={CancelShow}
          handleClose={handleCancelClose}
          setRefundPolicyShow={setRefundPolicyShow}
          BookingData={BookingData}
          Cancelmodeltype={cancelmodeltype}




          />
        }
                {
          BookingShow && Bookingid &&
          <BookingModal
          show={BookingShow}
          handleClose={handleBookingClose}
          Hoteldata={Hoteldata}
          CancelShow={handleCancelShow}
          StrpieShow={handleStrpieShow}
          HoldBookingConfirm={HoldBookingConfirm}
          bookingloader={hold_loader}

          />
        }

        
 {stripe && Hoteldata?.Clientsecret && Stripepay &&
              <StripepaymentModal
              show={Stripepay}
              handleClose={handleStrpieClose}
              Data={stripe}
              Client={Decryptdata(Hoteldata.Clientsecret)}
              Travellerdata={{name:UserDetails?.FirstName+UserDetails?.LastName,phone:UserDetails?.MobileNumber}}

              
                    />
      }



{
          RefundPolicy&&
          <RefundModal
          show={RefundPolicy}
          handleClose={setRefundPolicyShow}
          setWalletaddressModalShow={setWalletaddressModalShow}

          />
        }
        
        {
          Walletaddressmodal &&
          <WalletaddressModal
          show={Walletaddressmodal}
          handleClose={setWalletaddressModalShow}
          handleCancelShow={()=>handleCancelShow(Hoteldata,'booked')}
          handleotpmodalShow={handleotpmodalShow}
          useremail={UserDetails?.Email}

          />
        }
            {
          Otpmodal &&
          <OtpModal
          show={Otpmodal}
          handleClose={setOtpmodal}
          handleCancelShow={()=>handleCancelShow(Hoteldata,'booked')}
          useremail={UserDetails?.Email}

          />
        }
        

      </div>


    </div>


  );


}

export default BookingRoomCard;
