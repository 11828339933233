import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";

import { FaCheck } from "react-icons/fa";

import bathroom_icon from "../assets/images/bathroom_icon.png"
import bedroom_icon from "../assets/images/bedroom_icon.png"
import tv_icon from "../assets/images/tv_icon.png"
import wifi_icon from "../assets/images/wifi_icon.png"
import more_icon from "../assets/images/more_icon.png"

import drink from "../assets/images/book/drink.png"
import * as Hotels from "../Axios/hotel.axios";
import { isEmpty } from "../lib/common";
import CustomLoader from "../components/CustomLoader";





const Fecility = ({ amenitiesShow, handleAmenitiesClose, Fecilities, Type }) => {
  const [HotelData, setHotelData] = useState(false);
  console.log("FecilitiesFecilities", Fecilities, Type);



  return (
    <div>

      <Modal
        show={amenitiesShow}
        onHide={handleAmenitiesClose}
        centered
        size="md"
        className="common_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title> Hotel {Type}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-4">

          <div className="row mb-3">
            <div className="col-12 col-sm-12 col-md-12">
              <p className="amenitied_head mb-2"><img src={bathroom_icon} className="me-2" />{Type}</p>
            </div>
            {!isEmpty(Fecilities) ?
              <div className="col-12 col-sm-12 col-md-12">
                {Fecilities?.length > 0 ?

                  (Type == 'Amenities' ?
                    Fecilities?.map((item) =>
                      <p className="mb-1 f-12 fw-400 list_tick">
                        <FaCheck size={11} className="green_check" />
                        <span className="ps-1">{item}</span>
                      </p>

                    )
                    :
                    
                    Fecilities?.split(' - ')?.map((item) =>
                      <p className="mb-1 f-12 fw-400 list_tick">
                        <FaCheck size={11} className="green_check" />
                        <span className="ps-1">{item}</span>
                      </p>)

                  )
                  :
                  <p className="mb-1 f-12 fw-400 list_tick">
                    <span className="ps-1">No Data Available</span>
                  </p>

                }

              </div>
              :
              <>{CustomLoader()} </>
            }

          </div>


        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Fecility;
