import React, { useEffect, useState, useRef } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/esm/Button";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";
import { IoIosArrowDown } from "react-icons/io";
import search from "../assets/images/search.png";
import { DateTimeForm, isEmpty } from "../lib/common";
import * as Hotels from "../Axios/hotel.axios";
import Select from "react-select";
import NodataFound from "./NodataFound";

function SearchFilter({ Searchdata, Setformdata, Setsearchsubmit, Searchfun }) {
  const [number, setNumber] = useState([0]);
  const [Submitstatus, setSubmitstatus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [SearchData, setSearchData] = useState([]);

  const [Error, setErr] = useState({});
  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isHovered
          ? "#653883"
          : isSelected
          ? "#653883"
          : isFocused
          ? "#653883"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "38px",
      padding: "0px 10px",
      border: "none",
      backgroundColor: "#fff",
      color: "#000",
      borderColor: "#BEBEBE",
      borderRadius: "4px",
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: "4px",
      backgroundColor: "transparent",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
      padding: "0px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#ffffff",
    }),
    menuList: (base) => ({
      ...base,

      padding: 0,
      width: "100%",

      borderRadius: 5,
      background: "transparent",
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#000",
      };
    },
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown1 = () => {
    setIsOpen1(!isOpen1);
  };

  const options1 = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
    { value: "15", label: "15" },
    { value: "16", label: "16" },
    { value: "17", label: "17" },
  ];
  useEffect(() => {
    if (Searchdata?.Searchdata) {
      Search();
    }
  }, [Searchdata.Searchdata]);

  useEffect(() => {
    setTimeout(() => {
      if (!isOpen) {
        setSubmitstatus(true);
        Searchdata?.roomInfo?.map((item) => {
          item.childAge.length > 0 &&
            item.childAge.map((value, idx) => {
              if (isEmpty(value) && !isEmpty(item?.numberOfChild)) {
                setIsOpen(true);
              }
            });
        });
      }
    }, 50);
  }, [isOpen]);

  const Search = async () => {
    let { status, data } = await Hotels.SerachCountryandCities({
      searchdata: Searchdata?.Searchdata,
      limit: 10,
    });
    if (status) {
      setSearchData(data);
    }
  };
  const Addrooms = async (data, index) => {
    if (data == "add") {
      setNumber([...number, number.length]);
      Setformdata({
        ...Searchdata,
        ...{
          ["roomInfo"]: [
            ...Searchdata.roomInfo,
            {
              numberOfAdults: 1,
              numberOfChild: 0,
              childAge: [],
            },
          ],
        },
      });
    } else if (data == "sub") {
      let Rooms = Searchdata;
      Rooms.roomInfo.pop();
      Setformdata({ ...Rooms });
    }
  };

  const checkInRef = useRef(null);
  const checkOutRef = useRef(null);

  const DateSelect = async (data, id) => {
    if (id == "Checkin") {
      checkInRef.current.click();
    } else if (id == "Checkout") {
      checkOutRef.current.click();
    }
    let CheckoutDate = new Date(Searchdata.Checkout).setDate(
      new Date(Searchdata.Checkout).getDate()
    );
    let initialCheckinDate = new Date(Searchdata.Checkin).setDate(
      new Date(Searchdata.Checkin).getDate() + 1
    );

    if (id == "Checkin") {
      console.log("CheckinCheckin",data);
      if (CheckoutDate == initialCheckinDate || CheckoutDate < data) {
        Setformdata({
          ...Searchdata,
          ...{
            ["Checkin"]: data,
            ["Checkout"]: new Date(
              new Date(data).setDate(new Date(data).getDate() + 1)
            ),
          },
        });
      } else if (CheckoutDate < data) {
        Setformdata({
          ...Searchdata,
          ...{
            ["Checkin"]: data,
            ["Checkout"]: new Date(
              new Date(data).setDate(new Date(data).getDate() + 1)
            ),
          },
        });
      } else {
        Setformdata({
          ...Searchdata,
          ...{ ["Checkin"]: data },
        });
      }
    } else if (id == "Checkout") {
      Setformdata({
        ...Searchdata,
        ...{ ["Checkout"]: data },
      });
    } else {
      Setformdata({
        ...Searchdata,
        ...{ [id]: data },
      });
    }
  };
  const Roomsdata = async (index, id, value, childindex, type) => {
    var Roomdata = Searchdata.roomInfo;

    if (id == "numberOfChild") {
      if (type == "add" && Roomdata[index].numberOfChild < 3) {
        Roomdata[index]["childAge"].push("");
        Roomdata[index][id] = value > 0 ? value : 0;
        setSubmitstatus(false);
      } else if (type == "sub") {
        Roomdata[index]["childAge"].pop();
        Roomdata[index][id] = value > 0 ? value : 0;
      }
      Setformdata({ ...Searchdata, ...{ ["roomInfo"]: [...Roomdata] } });
      setSubmitstatus(false);
    } else if (id == "childAge") {
      Roomdata[index]["childAge"][childindex] = value;
      Setformdata({ ...Searchdata, ...{ ["roomInfo"]: [...Roomdata] } });
    } else if (id == "numberOfAdults") {
      if (type == "add" && Roomdata[index].numberOfAdults < 7) {
        Roomdata[index][id] = value > 1 ? value : 1;
        Setformdata({ ...Searchdata, ...{ ["roomInfo"]: [...Roomdata] } });
      } else if (type == "sub") {
        Roomdata[index][id] = value > 1 ? value : 1;
        Setformdata({ ...Searchdata, ...{ ["roomInfo"]: [...Roomdata] } });
      }
    }
  };
  const onchange = (e) => {
    setErr({});
    const { id, value } = e.target;
    Setformdata({ ...Searchdata, ...{ [id]: value } });
  };


  return (
    <div className="filter-search">
      <div className="city sec1 cmn-sec">
        <Dropdown
          className="area-city"
          show={isOpen1}
          onToggle={toggleDropdown1}
        >
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            className="cmn-heading"
          >
            
            <span className="d-flex align-items-center gap-1"> CITY, AREA OR PROPERTY <IoIosArrowDown size={15} /></span>
        <p className="mb-0 f-14 fw-500">{Searchdata?.SelectedData?.cityName}</p>
          
          </Dropdown.Toggle>
          <Dropdown.Menu className="locaPick-drop">
            <div className="cityArea-Property">
              <div className="search mb-2">
                <img src={search} className="img-fluid" />
                <input
                  type="text"
                  className="searchInp"
                  placeholder="Search"
                  id="Searchdata"
                  value={Searchdata?.Searchdata}
                  onChange={(e) => onchange(e)}
                />
              </div>
              {SearchData?.length > 0 && (
                <p className="mb-2 f-15 fw-600">Cities</p>
              )}{" "}
              <div className="loc">
                {SearchData?.length > 0 ? (
                  SearchData?.map((item) => (
                    <div className="loc-details mb-2">
                      {                    console.log("itemitem",item?.Countries[0]?.code)
}
                      <img
                        src={require("../assets/images/map.png")}
                        className="loc-logo img-fluid"
                      />
                      <span
                        className="f-13 loc-txt gray fw-500"
                        onClick={() => {
                          setIsOpen1(false);
                          Setformdata({
                            ...Searchdata,
                            ...{
                              ["SelectedData"]: item,
                              ["Citydata"]: {
                                city: item?.id,
                                nationality: item?.Countries[0]?.countryid,
                                countrycode: item?.Countries[0]?.code,

                              },
                            },
                          });
                        }}
                      >
                        {item?.cityName}
                      </span>
                    </div>
                  ))
                ) : (
                  <> {Searchdata?.Searchdata && <NodataFound />}</>
                )}
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="checkin sec2 cmn-sec">
        <Dropdown className="check-inout-dropp">
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            className="cmn-heading"
            ref={checkInRef}
          >
           <span className="d-flex align-items-center gap-1"> CHECK-IN <IoIosArrowDown size={15} /></span>
            <div>
            {Error?.Checkin && (
              <p className="text-left err_msg_txt">{Error?.Checkin}</p>
            )}
            <p className="mb-0 f-14 fw-500">
              {Searchdata?.Checkin
                ? DateTimeForm({ date: Searchdata.Checkin, datealone: true })
                : "Start Date"}
            </p>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="check-inout-drop">
            <DatePicker
              selected={Searchdata.Checkin}
              onChange={(e) => {
                DateSelect(e, "Checkin");
              }}
              open={true}
              minDate={new Date()}
            />
          </Dropdown.Menu>
        </Dropdown>
        {/* {Error?.Checkin && (
          <p className="text-left err_msg_txt">{Error?.Checkin}</p>
        )}
        <p className="mb-0 f-14 fw-500">
          {Searchdata?.Checkin
            ? DateTimeForm({ date: Searchdata.Checkin, datealone: true })
            : "Startdate"}
        </p> */}
      </div>
      <div className="checkout sec3 cmn-sec">
        <Dropdown className="check-inout-drop">
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            className="cmn-heading"
            ref={checkOutRef}
          >
            
            <span className="d-flex align-items-center gap-1"> CHECK-OUT <IoIosArrowDown size={15} /></span>

            {Error?.Checkout && (
          <p className="text-left err_msg_txt">{Error?.Checkout}</p>
        )}
        <p className="mb-0 f-14 fw-500">
          {Searchdata?.Checkout
            ? DateTimeForm({ date: Searchdata.Checkout, datealone: true })
            : "End Date"}
        </p>
          </Dropdown.Toggle>
          <Dropdown.Menu className="check-inout-drop">
            <DatePicker
              selected={Searchdata?.Checkout}
              onChange={(e) => {
                DateSelect(e, "Checkout");
              }}
              open={true}
              minDate={
                new Date(
                  new Date(Searchdata.Checkin).setDate(
                    new Date(Searchdata.Checkin).getDate() + 1
                  )
                )
              }
            />
          </Dropdown.Menu>
        </Dropdown>
        
      </div>
      <div className="rooms-guest sec4 cmn-sec">
        <Dropdown className="" show={isOpen} onToggle={toggleDropdown}>
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            className="cmn-heading"
          >
            
            <span className="d-flex align-items-center gap-1"> ROOMS & GUESTS <IoIosArrowDown size={15} /></span>
            <p className="mb-0 f-14 fw-500">
          {Searchdata?.roomInfo?.length} Rooms 
        </p>
          </Dropdown.Toggle>
          <Dropdown.Menu className="rooms-guest-menu" rootCloseEvent={null}>
            {Searchdata?.roomInfo?.map((value, index) => (
              <div>
                <div className="room-no">
                  <span className="f-15 fw-600">Room {index + 1}</span>
                  <span className="close-icon">
                    {index !== 0 && (
                      <IoMdCloseCircleOutline
                        onClick={() => Addrooms("sub", index)}
                      />
                    )}{" "}
                  </span>
                </div>
                <div className="customer-details">
                  <div>
                    <span className="f-13 fw-500">Adults</span>
                    <div className="inc-dec mt-1">
                      <Button
                        className="decrement-btn"
                        onClick={() =>
                          Roomsdata(
                            index,
                            "numberOfAdults",
                            value?.numberOfAdults - 1,
                            undefined,
                            "sub"
                          )
                        }
                      >
                        <FaMinus />
                      </Button>
                      <span className="value">
                        {" "}
                        {value?.numberOfAdults} Adults
                      </span>
                      <Button
                        className="increment-btn"
                        onClick={() =>
                          Roomsdata(
                            index,
                            "numberOfAdults",
                            value?.numberOfAdults + 1,
                            undefined,
                            "add"
                          )
                        }
                      >
                        <FaPlus />
                      </Button>
                    </div>
                  </div>
                  <div>
                    <span className="f-13 fw-500">
                      Children<span className="age"> (Age 0-17)</span>
                    </span>
                    <div className="inc-dec mt-1">
                      <Button
                        className="decrement-btn"
                        onClick={() =>
                          Roomsdata(
                            index,
                            "numberOfChild",
                            value?.numberOfChild - 1,
                            undefined,
                            "sub"
                          )
                        }
                      >
                        <FaMinus />
                      </Button>
                      <span className="value">
                        {" "}
                        {value?.numberOfChild} Child
                      </span>
                      <Button
                        className="increment-btn"
                        onClick={() =>
                          Roomsdata(
                            index,
                            "numberOfChild",
                            value?.numberOfChild + 1,
                            undefined,
                            "add"
                          )
                        }
                      >
                        <FaPlus />
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="childrens-age mt-2">
                  {value?.childAge?.length > 0 && (
                    <span className="f-13 fw-500">Age of children</span>
                  )}
                  <div className="row">
                    {value?.childAge?.map((itm, childindex) => (
                      <div className="col-6">
                        <Select
                          //  value={selectedOption1}
                          onChange={(e) =>
                            Roomsdata(index, "childAge", e?.label, childindex)
                          }
                          options={options1}
                          styles={stylesgraybg}
                          isSearchable={false}
                          placeholder="Select Age"
                          classNamePrefix="customselect"
                          value={{
                            value: itm ? itm : 0,
                            label: itm ? itm : 0,
                          }}

                          // menuIsOpen
                        />
                        {Submitstatus && isEmpty(itm) && (
                          <p className="error_msg mt-2">{`Select the child's age`}</p>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
            <div
              className={
                Searchdata?.roomInfo?.length < 4
                  ? "mt-3 f-14 fw-600 d-flex align-items-center justify-content-end"
                  : "d-none"
              }
            >
              <div className="add-room" onClick={() => Addrooms("add")}>
                <span className="">ADD ROOM</span>
                <FaCirclePlus className="plus-icon ms-1" />
              </div>
              
            </div>
            <div
                          className="mt-3 d-flex align-items-center justify-content-center"
                          onClick={() => setIsOpen(false)}
                        >
<Button className="f-14 px-3 cmn-purple-btn"> Apply

</Button>                         
                        </div>
          </Dropdown.Menu>
        </Dropdown>
        
      </div>
      <div className="search sec5">
        <Button
          className="search-btn"
          onClick={() => (!isOpen ? Searchfun() : null)}
        >
          SEARCH
        </Button>
      </div>
    </div>
  );
}

export default SearchFilter;
