import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { allData } from "../Data/CommonDataOne";
import { BiRightArrowAlt } from "react-icons/bi";
import { BiSearch } from "react-icons/bi";
function ActivitiesSelectFilter() {
  const [sliceVal, setSliceVal] = useState(3);
  return (
    <div>
      <div className="filter-sec">
        <Button className="explore-map">EXPLORE ON MAP</Button>
      </div>
      <div className="select-filters mt-3">
        <h5 className="fw-800">Select Filters</h5>
        <div className="suggested">
          <Form className="">
            <div>
              <div>
                <h6 className="m-0 filterTitle mt-4">Popular Filters</h6>
                {allData.popular.map((item, index) => (
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="pm_mapping_check">
                      <Form.Check
                        className="mt-1"
                        name="group1"
                        label={item.title}
                        type="checkbox"
                        id={index}
                      />
                    </div>
                    <span className="search__filterCounter">
                      ({item.title.length})
                    </span>
                  </div>
                ))}
              </div>

              <div>
                <h6 className="m-0 filterTitle mt-4">Price per Person</h6>
                {allData.price.map((item, index) => (
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="pm_mapping_check">
                      <Form.Check
                        className={
                          item?.status == "inactive" ? "inactive mt-1" : "mt-1"
                        }
                        name="group1"
                        label={item.title}
                        type="checkbox"
                        id={index}
                      />
                    </div>
                    <span className="search__filterCounter">
                      ({item.title.length})
                    </span>
                  </div>
                ))}
              </div>

              <p className="search__secondaryTitle m-0 mt-2 mb-1">
                Your Budget
              </p>
              <div className="search__filterInpHolder d-flex justify-content-between align-items-center">
                <input
                  type="text"
                  placeholder="Min"
                  className="search__filterInp flex-grow-1"
                />
                <p className="m-0 search__toDivider">to</p>
                <input
                  type="text"
                  placeholder="Max"
                  className="search__filterInp flex-grow-1"
                />
                <button className="cmn__themeBtn rounded-1">
                  <BiRightArrowAlt />
                </button>
              </div>

              <div>
                <h6 className="m-0 filterTitle mt-4">Duration</h6>
                {allData.duration.map((item, index) => (
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="pm_mapping_check">
                      <Form.Check
                        className={
                          item?.status == "inactive" ? "inactive mt-1" : "mt-1"
                        }
                        name="group1"
                        label={item.title}
                        type="checkbox"
                        id={index}
                      />
                    </div>
                    <span className="search__filterCounter">
                      ({item.title.length})
                    </span>
                  </div>
                ))}
              </div>

              <div>
                <h6 className="m-0 filterTitle mt-4">User Rating</h6>
                {allData.userRating.map((item, index) => (
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="pm_mapping_check">
                      <Form.Check
                        className="mt-1"
                        name="group1"
                        label={item.title}
                        type="checkbox"
                        id={index}
                      />
                    </div>
                    <span className="search__filterCounter">
                      ({item.title.length})
                    </span>
                  </div>
                ))}
              </div>

              <div>
                <h6 className="m-0 filterTitle mt-4">Activities Type</h6>
                {allData.activitiesType.map((item, index) => (
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="pm_mapping_check">
                      <Form.Check
                        className="mt-1"
                        name="group1"
                        label={item.title}
                        type="checkbox"
                        id={index}
                      />
                    </div>
                    <span className="search__filterCounter">
                      ({item.title.length})
                    </span>
                  </div>
                ))}
              </div>

              <div>
                <h6 className="m-0 filterTitle mt-4">Locality</h6>
                <p className="search__secondaryTitle m-0 mt-2 mb-1">
                  Popular with Tourists:
                </p>
                {(allData.popularTourist.length > 4
                  ? allData.popularTourist.slice(0, sliceVal)
                  : allData.popularTourist
                ).map((item, index) => (
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="pm_mapping_check">
                      <Form.Check
                        className="mt-1"
                        name="group1"
                        label={item.title}
                        type="checkbox"
                        id={index}
                      />
                    </div>
                    <span className="search__filterCounter">
                      ({item.title.length})
                    </span>
                  </div>
                ))}
              </div>
              <p
                className="m-0 search__showMore mt-2 text-end"
                onClick={() =>
                  setSliceVal(sliceVal == 3 ? allData.popularTourist.length : 3)
                }
              >
                {/* {sliceVal <= 3 ? "Show More" : "Show Less"} */}
              </p>

              <div>
                <p className="search__secondaryTitle m-0 mt-2 mb-1">
                  Near Transit Hub(s):
                </p>
                {allData.nearHub.map((item, index) => (
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="pm_mapping_check">
                      <Form.Check
                        className="mt-1"
                        name="group1"
                        label={item.title}
                        type="checkbox"
                        id={index}
                      />
                    </div>
                    <span className="search__filterCounter">
                      ({item.title.length})
                    </span>
                  </div>
                ))}
              </div>

              <div>
                <p className="search__secondaryTitle m-0 mt-2 mb-1">
                  Top Areas
                </p>
                {allData.topAreas.map((item, index) => (
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="pm_mapping_check">
                      <Form.Check
                        className="mt-1"
                        name="group1"
                        label={item.title}
                        type="checkbox"
                        id={index}
                      />
                    </div>
                    <span className="search__filterCounter">
                      ({item.title.length})
                    </span>
                  </div>
                ))}
              </div>

              <div>
                <h6 className="m-0 filterTitle mt-4">Activity Views</h6>
                {allData.activityViews.map((item, index) => (
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="pm_mapping_check">
                      <Form.Check
                        className={
                          item?.status == "inactive" ? "inactive mt-1" : "mt-1"
                        }
                        name="group1"
                        label={item.title}
                        type="checkbox"
                        id={index}
                      />
                    </div>
                    <span className="search__filterCounter">
                      ({item.title.length})
                    </span>
                  </div>
                ))}
              </div>

              <div>
                <h6 className="m-0 filterTitle mt-4">Activity Amenities</h6>
                {(allData.activityAmenties.length > 3
                  ? allData.activityAmenties.slice(0, sliceVal)
                  : allData.activityAmenties
                ).map((item, index) => (
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="pm_mapping_check">
                      <Form.Check
                        className="mt-1"
                        name="group1"
                        label={item.title}
                        type="checkbox"
                        id={index}
                      />
                    </div>
                    <span className="search__filterCounter">
                      ({item.title.length})
                    </span>
                  </div>
                ))}
                <p
                  className="m-0 search__showMore mt-2 text-end"
                  onClick={() =>
                    setSliceVal(
                      sliceVal == 3 ? allData.activityAmenties.length : 3
                    )
                  }
                >
                  {/* {sliceVal <= 3 ? "Show More" : "Show Less"} */}
                </p>
              </div>

              <div>
                <h6 className="m-0 filterTitle mt-4 mb-2">Amenities</h6>
                <div className="search__amentiesSearchHolder position-relative d-flex justify-content-between align-items-center gap-3 p-2 mb-2">
                  <input
                    type="text"
                    className="search__amentiesSearch flex-grow-1"
                    placeholder="Search amenities"
                  />
                  <BiSearch />
                </div>
                <p className="search__secondaryTitle m-0 mt-2 mb-1">
                  Guests Love
                </p>
                {allData.guestLove.map((item, index) => (
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="pm_mapping_check">
                      <Form.Check
                        className="mt-1"
                        name="group1"
                        label={item.title}
                        type="checkbox"
                        id={index}
                      />
                    </div>
                    <span className="search__filterCounter">
                      ({item.title.length})
                    </span>
                  </div>
                ))}
              </div>

              <div>
                <h6 className="m-0 filterTitle mt-4">House Rules</h6>
                {allData.houseRules.map((item, index) => (
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="pm_mapping_check">
                      <Form.Check
                        className="mt-1"
                        name="group1"
                        label={item.title}
                        type="checkbox"
                        id={index}
                      />
                    </div>
                    <span className="search__filterCounter">
                      ({item.title.length})
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ActivitiesSelectFilter;
