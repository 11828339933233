import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/esm/Button";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { allData } from "../Data/CommonData";
import { BiRightArrowAlt } from "react-icons/bi";
import { BiSearch } from "react-icons/bi";
import bed from "../assets/images/bed.svg";
import map from "../assets/images/map.png";
import search from "../assets/images/search.png";

function ActivitiesSearchFilter() {
  const [startDate, setStartDate] = useState(new Date());

  return (
    <div className="filter-search">
      <div className="city sec1 cmn-sec">
        <Dropdown className="area-city">
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            className="cmn-heading"
          >
            CITY, AREA OR ACTIVITIES
          </Dropdown.Toggle>
          <Dropdown.Menu className="locaPick-drop">
            <div className="cityArea-Property">
              <div className="search mb-2">
                <img src={search} className="img-fluid" />
                <input type="text" className="searchInp" placeholder="Search" />
              </div>
              <p className="mb-2 f-15 fw-600">Destinations</p>
              <div className="loc">
                <div className="loc-details mb-2">
                  <img
                    src={require("../assets/images/map.png")}
                    className="loc-logo img-fluid"
                  />
                  <span className="f-13 loc-txt gray fw-500">
                    Tirunelveli,Tamilnadu,India.
                  </span>
                </div>
                <div className="loc-details mb-2">
                  <img src={map} className="loc-logo img-fluid" />
                  <span className="f-13 loc-txt gray fw-500">
                    Madurai,Tamilnadu,India.
                  </span>
                </div>
                <div className="loc-details mb-2">
                  <img
                    src={require("../assets/images/map.png")}
                    className="loc-logo img-fluid"
                  />
                  <span className="f-13 loc-txt gray fw-500">
                    Chennai,Tamilnadu,India.
                  </span>
                </div>
              </div>
              <div className="properties mt-4">
                <p className="mb-2 f-15 fw-600">Properties</p>
                <div>
                  <div className="prop-details mb-2">
                    <div className="d-flex align-items-center gap-2">
                      <img src={bed} className="loc-logo img-fluid" />

                      <div className="">
                        <p className="mb-0 f-13 fw-500 d-flex align-items-center gap-1">
                          <span className="purple fw-700">AMD</span> Hotel
                          <span className="ratings">
                            <FaStar className="star-filled" />
                            <FaStar className="star-filled" />
                            <FaStar className="star-filled" />
                          </span>
                        </p>
                        <p className="prop-loc f-13 fw-500 mb-0">
                          Filderstadt, DE
                        </p>
                      </div>
                    </div>
                    <div className="hotel fw-600 f-12 gray">Hotel</div>
                  </div>
                  <div className="prop-details mb-2">
                    <div className="d-flex align-items-center gap-2">
                      <img src={bed} className="loc-logo img-fluid" />

                      <div className="">
                        <p className="mb-0 f-13 fw-500 d-flex align-items-center gap-1">
                          <span className="purple fw-700">AMD</span> Hotel
                          <span className="ratings">
                            <FaStar className="star-filled" />
                            <FaStar className="star-filled" />
                            <FaStar className="star-filled" />
                          </span>
                        </p>
                        <p className="prop-loc f-13 fw-500 mb-0">
                          Filderstadt, DE
                        </p>
                      </div>
                    </div>
                    <div className="hotel fw-600 f-12 gray">Hotel</div>
                  </div>
                </div>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
        <p className="mb-0 f-14 fw-500">Dubai</p>
      </div>
      <div className="checkin sec2 cmn-sec">
        <Dropdown className="check-inout-dropp">
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            className="cmn-heading"
          >
            CHECK-IN
          </Dropdown.Toggle>
          <Dropdown.Menu className="check-inout-drop">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              open={true}
            />
          </Dropdown.Menu>
        </Dropdown>
        <p className="mb-0 f-14 fw-500">Sat, 23 Mar 2024</p>
      </div>
      <div className="checkout sec3 cmn-sec">
        <Dropdown className="check-inout-drop">
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            className="cmn-heading"
          >
            CHECK-OUT
          </Dropdown.Toggle>
          <Dropdown.Menu className="check-inout-drop">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              open={true}
            />
          </Dropdown.Menu>
        </Dropdown>
        <p className="mb-0 f-14 fw-500">Tue, 23 Apr 2024</p>
      </div>
      <div className="search sec5">
        <Button className="search-btn">SEARCH</Button>
      </div>
    </div>
  );
}

export default ActivitiesSearchFilter;
