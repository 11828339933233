import React, {useEffect, useRef, useState} from "react";
import {  useNavigate} from "react-router-dom";
import { FaStar } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import AmenitiesModal from "../modals/AmenitiesModal";
import PhotoModal from "../modals/PhotoModal";
import { Ratingsquoetes } from "../lib/common";
import { Button } from "react-bootstrap";
import {LikeRef} from '../hooks/LikeRef'
import { toast } from "react-toastify";
import * as Hotelsaxios from "../Axios/hotel.axios";
import { useDispatch, useSelector } from "react-redux";
import NodataFound from "./NodataFound";




function HotelRoomsList({Hotels,formdata,loadlimit}) {

  const navigate = useNavigate();
  const dispatch=useDispatch()


  const [amenitiesShow, setAmenitiesShow] = useState(false);
  const [Hotelid, setHotelid] = useState('');
  const [LikedTokenList, setLikedTokenList] = useState([]);
  const [HoltelsLike, setHoltelsLike] = useState([]);
  const [photoShow, setPhotoShow] = useState(false);

  const handlePhotoClose = () => setPhotoShow(false);
  const handlePhotoShow = () => setPhotoShow(true);
  var LikeForwardRef = useRef();



  const handleAmenitiesClose = () => {
    setAmenitiesShow(false);
  }
  const handleAmenitiesShow = (data,event) => {
    setAmenitiesShow(true);
    setHotelid(data)

    event.stopPropagation();


  }
  function LikeList(data) {
    setLikedTokenList(data);
  }

  const { UserDetails } = useSelector((state) => state.LoginReducer.User);



useEffect(()=>{
  GetLikeList()

},[UserDetails])

useEffect(()=>{
  dispatch({
    type: "Hotels",
    Hotels: Hotels
  })
},[loadlimit])


const GetLikeList=async()=> {
  var check = await Hotelsaxios.Get_Likelist({type:'likecount'});
  setHoltelsLike(check?.data)


}

  const LikeAction = async (Data,event) => {
    event.stopPropagation();

    if (UserDetails) {
      var check = await LikeForwardRef.current.hitLike(Data);
      GetLikeList()

    } else{
      toast.error("Please Login First...!", {
        autoClose: 1000,
        closeButton: true,
        closeOnClick: true,
      });
    }
  };


  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <FaStar
          key={i}
          className={rating >= i ? "star-fill" : "star-without-fill"}
        />
      );
    }
    return stars;

  }

  const Navigate = (item) => {

    const url = `/roomDetails?hotelid=${item.id}`;
    window.open(url, '_blank');
  };


  return (
    <>
    <div className="rooms-card h-100">
      <h5 className="country-properties">Showing Properties</h5>
      {
Hotels.length >0?      
      Hotels.slice(0,loadlimit)?.map((item, index) => {
        return (
          <div className="custom-rooms-card mb-3 mb-lg-4">

           <>
            <div className="room_detailnav" onClick={() => Navigate(item)}>


            <div className="row">
              <div className="col-xl-4 fst-sec">
                <div className="row room-images left-sec">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <img
                        src={item?.img[0]?.url?item?.img[0]?.url:""}
                        className="big-img img-fluid"
                    />
                  </div>

                </div>
              </div>
              <div className="col-xl-4 snd-sec">
              
                <div className="room-details">
                 
                  <div className="sec-fst">
                    <div className="name mb-2">
                      <p className=" mb-0 f-15 fw-800">{item?.name}</p>
                      <div className="d-flex align-items-center gap-1">


{                        renderStars(item?.rt)
}                      </div>
                    </div>
                    <div className="address mb-2">
                      <p className=" mb-0 f-13 fw-700 addr1">{item?.ad?.adr}{','}</p>

                      <p className=" mb-0 f-13 fw-600 addr2">
                      {item?.ad?.city?.name}{' - '}{item?.ad?.postalCode}{', '}
                      {item?.ad?.state?.name}
                      </p>
                      <p className=" mb-0 f-13 fw-600 addr2">
                      {' , '}{item?.ad?.country?.name}{' '}
                      </p>
                    </div>
                    <p className="mb-0 f-13 fw-500 mb-2">
{item.pt}   
                 </p>
                    <div className="d-flex align-items-center gap-1 mb-2">
                  
                      <p className="mb-0 f-13 fw-500"> 
                        {/* Approx. US$1.99 giveback in AVA */}
                      </p>
                    </div>
                  </div>
               
                  <div className="sec-snd">
                    <Button  
                    onClick={(event)=>handleAmenitiesShow(item?.id,event)}
                     className="text-decoration-none btn-link viewAll_amenBtn">
                      <p className="mb-0 fw-600 f-13 view-all-amen themeclr1">
                        View All Amenities
                      </p>
                    </Button>
                    <div onClick={(event)=>LikeAction(item,event)} className="like_Counts">

                    <LikeRef
                ref={LikeForwardRef}
                setLikedTokenList={setLikedTokenList}
                LikeList={LikeList}
                Hotel={item}
                HoltelsLikelist={HoltelsLike}
              />
 
                {/* LikeRef */}

                    <FaHeart className={`heart-fill 
                    ${LikedTokenList.find(s=>s.HotelId==(item?.id)?.split('-')?.[1])?.LikesCount>0?'active':''}`} />
                    <p id={(item?.id)?.split('-')?.[1]+'-likecount'} className="count_val mb-0">{
                    HoltelsLike?.find(s=>s._id==(item?.id)?.split('-')?.[1])?.LikesCount
                     }</p>
                                        

                    </div>
                  </div>
                </div>
                
              </div>
              <div className="col-xl-4 third-sec">
                <div className="amt-rating">
                  <div className="ratings">
                    <div className="d-flex align-items-center justify-content-end rat-div">
                      <span className="rating-txt f-14 fw-700">{Ratingsquoetes(item?.rt)}</span>
                      <span className="rating-count f-13 fw-600 ms-1">{item?.rt}</span>
                    </div>
                    <p className="rating-value f-13 fw-500 text-end">
              </p>
                  </div>
                  <div className="amount">
                    <p className="f-12 fw-500 old-off text-decoration-line-through mb-0">
                      ₹ {parseInt(item?.pops[0]?.tpc)+315}
                    </p>
                    <h5 className="fw-800 new-off mb-0">₹ {parseInt(item?.pops[0]?.tpc)}</h5>
                    <p className="mb-0 f-13 fw-600 per-room">
                      Per room/night with taxes
                    </p>
                    <p className="mb-0 f-11 fw-500 total">
                      {/* Total ₹ 5,080 + Taxes */}
                    </p>
                  </div>
                  <div className="emi">
                    <p className="mb-0 f-11 fw-700">
                      Link
                      No Cost EMI starts at ₹6,90,133
                    </p>
                  </div>
                </div>
              </div>
            </div>
            </div>
            </>
            {/* </Link> */}
          </div>
        );
      })
    :
<div className="hotel_roomList_nodataFount">
    <NodataFound/>
    </div>
    }
    </div>
    {amenitiesShow &&
 <AmenitiesModal
 amenitiesShow={amenitiesShow}
 handleAmenitiesClose={handleAmenitiesClose}
 Hotelid={Hotelid}
/>
}

{photoShow &&
 <PhotoModal
 photoShow={photoShow}
 handlePhotoClose={handlePhotoClose}
/>
}
    </>
  );
}

export default HotelRoomsList;
