import React, { useState, useEffect } from "react";

// import useAxiosFile from "../actions/useaxios";
import { Elements, LinkAuthenticationElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import config from '../lib/config'
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { updateToast } from "../lib/common";
const StripePayment = ({ Setsubmitcall, Datas, SetDisabledstatus, handleClose }) => {

  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(()=>{
localStorage.setItem("payment","stripe")
  },[])

  const handleSubmit = async (e) => {
    setIsLoading(true)
    try {
      let toastid = toast.loading("Processing");

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return updateToast(null, "Stripe Fields are required", "error")


      }


      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${config.FRONT_URL}/account?bookingstatus=Completed`,
          shipping: {
            name: Datas?.name,
            phone: Datas?.phone,
            address: {
              city: "Madurai",
              line1: `Gandhi street`,
              line2: 'Thathaneri',
              country: "India",
              state: "tamilnadu",
              postal_code: '625018'
            }
          }
        },
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.

      if (error) {
        setIsLoading(false)
        toast.update(toastid, { render: "Fix Mistakes", type: 'error', autoClose: 1000, isLoading: false });

      }
      else {
        handleClose()
        setIsLoading(false)

        toast.update(toastid, { render: "Paid Successfully", type: 'success', autoClose: 1000, isLoading: false });



      }
      SetDisabledstatus({ paynow: false })
      setIsLoading(false);
      Setsubmitcall(false)
    }
    catch (e) {
      console.error("!!!!!!!!Error stripe", e)
    }
  }






  return (
    <>
      <LinkAuthenticationElement id="link-authentication-element"
      />
      <PaymentElement id="payment-element" />
      <div className="d-flex align-items-center justify-content-center mt-4">
        <Button class={`cmn-btn btn btn-primary text-uppercase ${isLoading || !stripe || !elements ? 'disabled' : "cmn-btn submit_Btn"}`} onClick={() => handleSubmit()} style={{ padding: '10px 20px' }}>Submit</Button>
      </div>
      <span id="button-text">
      </span>
    </>
  )
}

export default StripePayment;
