import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { LoadToast, isEmpty, updateToast, validation } from "../lib/common";
import * as User from "../Axios/user.axios";


const Changeemail = ({ show, handleClose,newEmailOtpModal,setnewEmailOtp,setupdateEmail }) => {


  const initial = {
    Email: "",
    Type:"newmail"
  };
  const [formdata, setFormdata] = useState(initial);
  const [err, setErr] = useState({});
  const [loader, setloader] = useState(false);
  
  const onchange = (e) => {
    setloader(false)
    setErr({});


    const { id, value } = e.target;
    setFormdata({ ...formdata, ...{ [id]: value } });
  };
  
  const submit = async () => {
    setloader(true)
    const id = LoadToast();
    let err = await validation(Object.keys(initial), formdata);
    
    if (Object.keys(err)?.length > 0) {
      updateToast(id, "fix");
      setErr(err);
      setloader(false)

      return;
    }
    setErr({});
    
    var { success, msg,data } = await User.SendOtp(formdata);
    
    updateToast(
        id,
        msg,
        success
      );
      setloader(false)

    if (success=='success') {
        newEmailOtpModal(true)
        handleClose()
        setnewEmailOtp(data)
        setupdateEmail(formdata?.Email)

    
  };
}

  

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="md"
        className="common_modal otp_modal"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title></Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="p-3">
            <h4 className="modal_title text-center">Enter New Email</h4>
            <div className="otp_input mt-4">
            <input
                  type="text"
                  placeholder="Email"
                  className="cred_input mb-2"
                  id="Email"
                  value={formdata.Email}
                  onChange={onchange}
                />
            </div>{" "}
            {err?.Email && (
                      <p className="text-left err_msg_txt">{err?.Email}</p>
                    )}
        {  loader?    
        <div className="mt-4 d-flex align-items-center justify-content-center">
              <Button className="cmn-btn f-14 fw-600 px-4 disabled" >Processing...</Button>
            </div>
            :
           <div className="mt-4 d-flex align-items-center justify-content-center">
              <Button className="cmn-btn f-14 fw-600 px-4" onClick={()=>submit()}>Send</Button>
            </div>}
          </div>

          {/* <div className="sgn_bot_sec">
            <p className="mb-0 text-center">
              Can't get OTP? <span className="sgn_term_btn resend_txt">RESEND</span>
            </p>
          </div> */}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Changeemail;
