import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import meal from "../assets/images/book/meals.png"
import drink from "../assets/images/book/drink.png"
import NodataFound from "../components/NodataFound";



const InformationModal = ({ informationShow, handleInformationClose,Hoteldata }) => {


  return (
    <div>
      
      <Modal
        show={informationShow}
        onHide={handleInformationClose}
        centered
        size="md"
        className="common_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>All Hotel Rules</Modal.Title>
      </Modal.Header>
        <Modal.Body className="pb-4">
         
{/* <p className="black_bold_text_sm mb-1">Check In/out</p>
<p>Hotel Check-in Time is 2 PM, Check-out Time is 12 PM.</p> */}

{Hoteldata?.inst?.length>0?
      Hoteldata?.inst?.map((item)=>

  <>
  <p className="black_bold_text_sm mb-1">{item?.type}</p>
  {Object.values(JSON.parse(item?.msg)).map((item)=>

<ul className="ps-4">
    <li>
{item}
    </li>

</ul>
  )}
</>
            )
            :
            <NodataFound/>
}







        </Modal.Body>
      </Modal>
    </div>
  );
};

export default InformationModal;
