import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import Image from "../components/Image";
import LoginModal from "./LoginModal";
import { LoadToast, isEmpty, updateToast, validation } from "../lib/common";
import * as User from "../Axios/user.axios";
import Otpmail from "./Otpmail";
import { useNavigate } from "react-router";


const ForgotEmail = ({ showForgotEmail, handleForgotEmailClose,handleLogin,showOtpEmail,UserEmail,setTime}) => {
  const [loginShow, setLoginShow] = useState(false);

  const initial = {
    Email: "",

  };
  const [formdata, setFormdata] = useState(initial);
  const [err, setErr] = useState({});
  const [loader, setloader] = useState(false);
  const navigate = useNavigate();


  const handleLoginClose = () => setLoginShow(false);
  
  const handleLoginShow = () => {
    handleForgotEmailClose();
    handleLogin(true);
  };


  const onchange = (e) => {
    const { id, value } = e.target;
    setFormdata({ ...formdata, ...{ [id]: value } });
  };
  const submit = async () => {
    setloader(true)
    const id = LoadToast();
    let err = await validation(Object.keys(initial), formdata);
    
    if (Object.keys(err)?.length > 0) {
      updateToast(id, "fix");
      setErr(err);
      setloader(false)

      return;
    }
    setErr({});
    
    var { success, msg,data,user } = await User.SendOtp(formdata);
    if (!isEmpty(success)) {
      setloader(false)

      setTimeout(() => {
        handleForgotEmailClose()
        showOtpEmail()
        setTime(user?.Expirestime)
        UserEmail(formdata.Email)
      }, 2000);


        } else{ 
          setloader(false)
          setErr(err);

        } 

        updateToast(
          id,
          msg,
          success
        );
  };



  return (
    <div>
        <LoginModal loginShow={loginShow} handleLoginClose={handleLoginClose} />
      
      <Modal
        show={showForgotEmail}
        onHide={handleForgotEmailClose}
        centered
        size="lg"
        className="custom_modal common_modal signup_modal"
      >
        <Modal.Header closeButton className="p-0">
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row className="mx-auto sgn_row">
            <Col xs={12} xl={6} className="p-0">
              <div className="login_mdl_right">
                <h4 className="modal_title">Forgot Password</h4>
                <p className="thm_labl mt-4 mb-2">Email</p>
                <input
                  type="text"
                  placeholder="Email"
                  className="cred_input mb-2"
                  id="Email"
                  value={formdata?.Email}
                  onChange={onchange}
                />{" "}
                   {err?.Email && (
                      <p className="text-left err_msg_txt">{err?.Email}</p>
                    )}
              { 
              loader?<button className="themeButton my-4 disabled">Processing...</button>:

              <button className="themeButton my-4" onClick={()=>submit()}>Continue</button>}
                <p className="sgn_nav_p">
                  By clicking <span className="sng_rgst_btn">'Register'</span>,
                  I agree to the{" "}
                  <span className="sgn_term_btn" onClick={()=>navigate("/cms?content=termsandconditions")}>Terms of Use</span> and 
                  <span className="sgn_term_btn" onClick={()=>navigate("/cms?content=privacypolicy")}>Privacy Policy</span>
                </p>
              </div>
            </Col>{" "}
            <Col
              xs={12}
              xl={6}
              className="p-0 d-flex align-items-center justify-content-center"
            >
              <div className="login_mdl_right no_border">
                <div className="d-flex flex-column align-items-center justify-content-center  sign_optn">
                  <p>Login With Smart way</p>
                  <div>
                    <button>
                      <img src={Image.eth} alt="eth" className="img-fluid" />
                    </button>{" "}
                    <button>
                      <img
                        src={Image.google}
                        alt="google"
                        className="img-fluid"
                      />
                    </button>{" "}
                    <button>
                      <img
                        src={Image.message}
                        alt="message"
                        className="img-fluid"
                      />
                    </button>{" "}
                    <button>
                      <img
                        src={Image.facebook}
                        alt="facebook"
                        className="img-fluid"
                      />
                    </button>{" "}
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <div className="sgn_bot_sec">
            <p className="mb-0">
              Back to login?{" "}
              <span className="sgn_term_btn" onClick={() => handleLoginShow()}>
                {" "}
                LOGIN
              </span>
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ForgotEmail;
