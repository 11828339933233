import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { AddressValidation, LoadToast, updateToast, validation } from "../lib/common";
import { SendOtp, Statusupdate } from "../Axios/user.axios";




const WalletaddressModal = ({ show, handleClose,handleCancelShow,handleotpmodalShow,useremail}) => {
  const [loader, setloader] = useState(false);
  const [check, setcheck] = useState(false);
  const [formdata, setFormdata] = useState({Walletaddress:""});
  
  const [err, setErr] = useState({});
  const onchange = (e) => {
    setErr({})
    setloader(false)
    const { id, value } = e.target;
    setFormdata({ ...formdata, ...{ [id]: value } });
  }
  const submit = async () => {
    setloader(true)
    const id = LoadToast();
    let err = await validation(Object.keys(formdata), formdata);
    if (Object.keys(err)?.length > 0) {
        updateToast(id, "fix");
        setErr(err);
        setloader(false)
        return;
      }
      else{
          setErr({});
          let isvalid=await AddressValidation(formdata?.Walletaddress)
          if(!isvalid){
            
              updateToast(id, "fix");
              setErr({Walletaddress:"Enter valid Wallet Address"})
              setloader(false)

              return
  
          }
          else{
            let resp = await Statusupdate({
              Email: useremail,
              Status:{WalletAddress: (formdata?.Walletaddress).trim()},
            });


            var { success, msg } = await SendOtp({Email:useremail});
if(success=='success'){
  updateToast(id, "Your Otp send to your register email address","success");
  handleotpmodalShow()

}
else{
  updateToast(id, msg,success);

}

              // handleCancelShow()
              // handleClose()
              // updateToast(id, "You can cancel your booking now ","success");

          }
      }

      

  }
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="md"
        className="common_modal reservation_modal"
      >
        <Modal.Header closeButton className="justify-content-center">
          <Modal.Title> Wallet Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-4">
          <div className="reservation_modal_details">
            {/* <p className="heading_txt mb-1">Cancel</p> */}
            <p className="description mb-2 text-center fw-500 f-15" style={{fontSize:'15px'}}>
Please enter valid ETH wallet address </p>
            <div className="d-flex align-items-center justify-content-center">
            <input
                  type="text"
                  placeholder="Enter your Walletaddress"
                  className="cred_input"
                  id="Walletaddress"
                  value={formdata?.Walletaddress}
                  onChange={onchange}
                />{" "}
                      {err?.Walletaddress && (
                      <p className="text-left err_msg_txt">{err?.Walletaddress}</p>
                    )}
         
            </div>
            {loader ?
                <Button className="cmn-btn mt-2" disabled>Processing...</Button>
                :
                <Button className="cmn-btn mt-2" onClick={() => submit()} >Submit</Button>

              }
          </div>




        </Modal.Body>
      </Modal>

    </div>

  );
};

export default WalletaddressModal;
