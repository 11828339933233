import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "../src/pages/Landing";
import Search from "./pages/Search";
import "./App.css";
import RoomDetails from "./pages/RoomDetails";
import ReviewBooking from "./pages/ReviewBooking";
import Account from "./pages/Account";
import PaymentOption from "./pages/PaymentOption";
import ConfirmBooking from "./pages/ConfirmBooking";
import CancellationPolicy from "./pages/CancellationPolicy";
import ViewBenefits from "./pages/ViewBenefits";
import ActivitiesSearch from "./pages/ActivitiesSearch";
import ActivityDetails from "./pages/ActivityDetails";
import ActivityReviewBooking from "./pages/ActivityReviewBooking";
import ActivityPaymentOption from "./pages/ActivityPaymentOption";
import ActivityConfirmBooking from "./pages/ActivityConfirmBooking";
import BookingConfirmation from "./components/BookingConfirmation";
import { loadStripe } from "@stripe/stripe-js";


function App() {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Landing />} />
        {/* <Route exact path="/booking-confirm" element={<BookingConfirmation />} /> */}
        <Route exact path="/search" element={<Search />} />
        <Route exact path="/activities-search" element={<ActivitiesSearch />} />
        <Route exact path="/roomDetails" element={<RoomDetails />} />
        <Route exact path="/activityDetails" element={<ActivityDetails />} />
        <Route exact path="/activityReviewBooking" element={<ActivityReviewBooking />} />

        <Route exact path="/activityPaymentOption" element={<ActivityPaymentOption />} />
        <Route exact path="/activityConfirmBooking" element={<ActivityConfirmBooking />} />

        
        
        <Route exact path="/reviewBooking" element={<ReviewBooking />} />
        <Route exact path="/account" element={<Account stripe={stripePromise}/>} />
        <Route exact path="/paymentOption" element={<PaymentOption  stripe={stripePromise} />} />
        <Route exact path="/confirmBooking" element={<ConfirmBooking />} />
        <Route exact path="/cms" element={<CancellationPolicy />} />
        <Route exact path="/viewBenefits" element={<ViewBenefits />} />

        
        
        
      </Routes>
    </BrowserRouter>
  );
}

export default App;
