import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";

import { FaCheck } from "react-icons/fa";

import config from '../lib//config'



const ReviewPhotoModal = ({ photoShow, handlePhotoClose,images,Hotels,type }) => {


  return (
    <div>
      
      <Modal
        show={photoShow}
        onHide={handlePhotoClose}
        centered
        size="xl"
        className="common_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{Hotels?.hotel?.name}</Modal.Title>
      </Modal.Header>
        <Modal.Body className="pb-4">
         
  <div className="row">
  {images?.map((item, index) => (
   <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-3">
   <div className="img_div_more_img">
{type=='Activities'?  
 <img
                     src={`${config?.IMG_URL}/activity/reviewimages/${item}`
                    }
                     className="big-img img-fluid"
                   />
                   :
                   <img
                   src={`${config?.IMG_URL}/hotels/reviewimages/${item}`
                  }
                   className="big-img img-fluid"
                 />
                  }
   </div>
</div>
  ))}
    
  </div>


        
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ReviewPhotoModal;