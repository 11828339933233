import { toast } from 'react-toastify';
import CryptoJS, { AES, enc } from "react-native-crypto-js";
import axios from 'axios'
import config from '../lib/config'
import html2canvas from "html2canvas";
import Web3 from 'web3';
import {isValidPhoneNumber} from "react-phone-number-input";





export const validation = (requirefield, data) => {


  //* data must be an object *//
  let err = {}
  requirefield.forEach(function (field) {
    if (data[field]) {
      if (field.toLowerCase() == "password" || field.toLowerCase() == "createpassword" || field.toLowerCase() == "confirmpassword" || field.toLowerCase() == "newpassword") {

        if (!config.PASSWORD.test(data[field])) {
          err = { ...err, ...{ [field]: field + " must be contain alpha numeric" } }
        }
      }
      if (field.toLowerCase() == "email") {
        if (!config.EMAIL.test(data[field])) {
          err = { ...err, ...{ [field]: "Please enter a valid email...!" } }
        }
      }
      if (field.toLowerCase() == "otp") {
        if (!config.OTP.test(data[field])) {
          err = { ...err, ...{ [field]: field + "Enter 6 digit OTP" } }
        }
      }
      if (field.toLowerCase() == "pan"||field.toLowerCase() == "pancard") {
          if (!config.PANCARD.test(data[field])) {
            err = { ...err, ...{ [[field+"val"]]:  "Please enter Valid Pancard Number...!" } }
          }
        
      }
      if (field?.toLowerCase() == "mobilenumber"||field?.toLowerCase() == "phonenumber") {
        if (!isValidPhoneNumber(data[field])) {
          err = { ...err, ...{ [[field+'val']]:  "Please enter a valid phone number...!" } }
        }
      
    }
    //   if (field.toLowerCase() == "dateofbirth") {
    //     if (!isValidBirthday(data[field])) {
    //       err = { ...err, ...{ [[field]]:  "Age must be above 18" } }
    //     }
      
    // }
      
      if (field.toLowerCase() == "passport"||field.toLowerCase() == "pnum"||field.toLowerCase() == "documentnumber") {
        if (!config.PASSPORT.test(data[field])) {
          err = { ...err, ...{ [field+"val"]:  "Please enter a valid passport number...!" } }
        }
      
    }
      if (field?.toLowerCase() == "walletaddress") {
        if (!AddressValidation(data[field])) {
          err = { ...err, ...{ [field]:  "Please enter a Wallet address...!" } }
        }
      
    }
      if (field.toLowerCase() == "ln") {
        if (data[field].length<3) {
          err = { ...err, ...{ [field]:  "Please enter more than two letters" } }
        }
      
    }



    }
    else {

      if (field.toLowerCase() == "pan"||field.toLowerCase() == "pancard")
{
  err = { ...err, ...{ [field]: "PanCard Number is required" } }

}
else if (field.toLowerCase() == "ln")
{
  err = { ...err, ...{ [field]: " Last Name is required" } }

}
else if (field.toLowerCase() == "type")
{
  err = { ...err, ...{ [field]: " Room Type is required" } }

}
else if (field.toLowerCase() == "createpassword")
{
  err = { ...err, ...{ [field]: "Create Password is required" } }

}
else if (field.toLowerCase() == "confirmpassword")
{
  err = { ...err, ...{ [field]: "Confirm Password is required" } }

}
else{
  err = { ...err, ...{ [field]: field + " is required" } }

}
    }


  });
  return err;

}
export const LoadToast = (data) => { return toast.loading(data ?? "Loading.....") }
export const updateToast = (id, data, type) => {

  try {
    if (id) {
      if (data == "fix") {
        data = "Fix All Validation"
        type = "error"
      }
      if (type == "error") type = toast.TYPE.ERROR
      if (type == "success") type = toast.TYPE.SUCCESS
      if (type == "info") type = toast.TYPE.INFO

      setTimeout(() => {
        return toast.update(id, { render: data, type: type, isLoading: false, autoClose: 1000 })

      }, 1000)
      // 
      // return toast.update(id, { render: data, type: type, autoClose: 1000 })
    }
    else {
      // 
      return toast[type](data, 1000)
    }
  }
  catch (e) {
    console.error("tpast update", e)
    return false
  }
}
export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value)?.length === 0) ||
  (typeof value === "string" && value.trim()?.length === 0) ||
  (typeof value === "string" && value == "error") ||
  (typeof value === "string" && value === "0") ||
  (typeof value === "number" && value === 0) ||
  (Array.isArray(value) === true && value?.length === 0);



export const AppenData = (data) => {

  var formdata = new FormData()

  var SendDta = Object.entries(data).map((item) => {
    if (Array.isArray(item[1])) {
      var come = item[1].map(data => {
        if (data?.type) {
          formdata.append(item[0], (data))
        }
        else {
          formdata.append(item[0], EncryptData(data))
        }
        return formdata
      })
      return come
    }
    else {
      if (item?.[1]?.type) {
        formdata.append(item[0], (item[1]))
      }
      else {
        formdata.append(item[0], EncryptData(item[1]))
      }
      return formdata
    }
  })
  return SendDta
}
export const EncryptData = (data) => {
  const encJson = CryptoJS.AES.encrypt(JSON.stringify(data), config.SECRET_KEY).toString();
  const encData = CryptoJS.enc.Base64.stringify(
    CryptoJS.enc.Utf8.parse(encJson)
  );
  return encData;
}


export const Decryptdata = ((data) => {
  if (isEmpty(data)) {
    return data
  }
  const decData = CryptoJS.enc.Base64.parse(data)?.toString(CryptoJS.enc.Utf8);
  const bytes = CryptoJS.AES.decrypt(decData, config.SECRET_KEY).toString(CryptoJS.enc.Utf8);

  return JSON.parse(bytes)
})

export const axiosFunc = (async (data) => {
  try {

    let Resp = await axios(data)

    if (Resp?.data) {
      Resp.data = Resp.data ? isEmpty(Decryptdata(Resp.data)) ? Resp.data : Decryptdata(Resp.data) : Resp.data
    }
    setTimeout(() => {
      if(Resp?.data?.type =='authentication'){
        toast.error(Resp?.data?.msg)
        }
    }, 1000);

    return Resp.data
  }
  catch (e) {

    return false
  }
})
export const Name_showing = (item) => {
  if (item && item.toString().length > 30) {
    var slice_front = item.slice(0, 9);
    return slice_front + "....";
  } else return item;
};

export const DateTimeForm = (data) => {
  try {
    let { date, datealone, timealone, ampm, words } = data;
    if (datealone) {
      return `${
        words ? new Date(date).toLocaleString('default', { day: 'long' }) : MinuteHourFormat(new Date(date)?.getDate())
      }/${MinuteHourFormat(
        new Date(date)?.getMonth() + 1
      )}/${MinuteHourFormat(new Date(date)?.getFullYear())}`;
    } else if (timealone) {
      if (ampm) {
        return `${MinuteHourFormat(
          new Date(date)?.getHours() > 12
            ? new Date(date)?.getHours() - 12
            : new Date(date)?.getHours()
        )}:${MinuteHourFormat(new Date(date)?.getMinutes())} ${new Date(date)?.getHours() >= 12 ? "PM" : "AM"
          }`;
      } else {
        return `${MinuteHourFormat(
          new Date(date)?.getHours()
        )}:${MinuteHourFormat(new Date(date)?.getMinutes())} `;
      }
    } else if (ampm) {
      return `${MinuteHourFormat(new Date(date)?.getDate())}/${MinuteHourFormat(
        new Date(date)?.getMonth() + 1
      )}/${MinuteHourFormat(new Date(date)?.getFullYear())}, ${MinuteHourFormat(
        new Date(date)?.getHours() > 12
          ? new Date(date)?.getHours() - 12
          : new Date(date)?.getHours()
      )}:${MinuteHourFormat(new Date(date)?.getMinutes())} ${new Date(date)?.getHours() >= 12 ? "PM" : "AM"
        } `;
    }
    else if(words){

        return `
        ${MinuteHourFormat(new Date(date)?.getDate())}
        ${new Date(date).toLocaleString('default', { weekday: 'long' })}
        ${new Date(date).toLocaleString('default', { month: 'long' })}
        ${MinuteHourFormat(new Date(date)?.getFullYear())} 
   `;
//    ${MinuteHourFormat(
//     new Date(date)?.getHours() > 12
//       ? new Date(date)?.getHours() - 12
//       : new Date(date)?.getHours()
//   )}:
//  ${MinuteHourFormat(new Date(date)?.getMinutes())} ${new Date(date)?.getHours() >= 12 ? "PM" : "AM"
    // } 
    }
    return `${MinuteHourFormat(new Date(date)?.getDate())}:${MinuteHourFormat(
      new Date(date)?.getMonth() + 1
    )}:${new Date(date)?.getFullYear()},${MinuteHourFormat(
      new Date(date)?.getHours()
    )}:${MinuteHourFormat(new Date(date)?.getMinutes())} `;
  } catch (err) {
    console.log("DateTimeFormDateTimeFormP_err", err);
    return "No Date";
  }
};


export const MinuteHourFormat = (data) => {
  data = Number(isEmpty(data) ? 0 : data);
  return (data < 10 ? "0" : "") + data;
};

export const Ratingsquoetes = (data) => {
  if (data == 1) return "Poor"
  if (data == 2) return "Not Bad"
  if (data == 3) return "Good"
  if (data == 4) return "Very Good"
  if (data == 5) return "Excellent"



}

export const DynamicArithemetics = (data) => {
  try {
    let { operation, first, second } = data;
    if (operation == '==') {
      return first == second;
    }
    if (operation == '>') {
      return first > second;
    }
    if (operation == '<') {
      return first < second;
    }
    if (operation == '>=') {
      return first >= second;
    }
    if (operation == '<=') {
      return first <= second;
    }
  }
  catch (err) {
    console.log("DynamicArithemetics_error", err);
    return false
  }
}

export const ChangeValType = (datas) => {
  try {
    let { data, type } = datas
    if (type == 'string') {
      return String(data).toLowerCase();
    }
    else if (type == 'number') {
      return Number(data);
    }
    return '';
  }
  catch (err) {
    console.log('ChangeValType_error', err);
    return false
  }
}
export const AverageRatings = (data) => {

  let AVG = data.reduce((a, b) => a + b, 0) / data?.length

  return parseInt(AVG)

}
export const NightdaysCalculation=(Checkin,Checkout)=>{
  let timeDiff = Math.abs(new Date(Checkout)?.getTime() - new Date(Checkin)?.getTime());
let numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
return numberOfNights
}

export const DownloadCertificate = async (certificateRef) => {
  try {
    // { useCORS: "cors-anywhere" }
    if (certificateRef.current) {
      var canvas = await html2canvas(certificateRef.current,
        
        {
          useCORS: true,
          allowTaint: false
        }
        );
      if (canvas) {
        const dataURL = await canvas.toDataURL("image/png");

        var fileDataCon = await dataURLtoFile(dataURL, "invoice.png");

        return fileDataCon;
      }
    }
  } catch (err) {
    console.log("DownloadCertificate err", err);
  }
};
function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
export const AddressValidation = async (Address)=>{

try {      
  const httpProvider = new Web3.providers.HttpProvider(config.BNB_URL);
  
      const web3 = new Web3(httpProvider);
      const isValid = await web3.utils.isAddress(Address);
      return isValid
    }
    catch(err){
      console.log("AddressValidation_err",err);
      return false

    }

}
export const isValidBirthday = (birthday) => {
  const today = new Date();
  const birthDate = new Date(birthday);
  const age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  const dayDifference = today.getDate() - birthDate.getDate();
  console.log("agecccc18",age > 18);

  // Adjust age if the birthdate has not yet occurred this year
  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    return age > 18;
  }

  return age >= 18;
};
export const Describtion_showing = (item) => {
  if (item && item.toString().length > 100) {
    let slice_front = item.slice(0, 80);
    return slice_front + "....";
  } else return item;
}