import React from "react";
import Accordion from "react-bootstrap/Accordion";
import NodataFound from "./NodataFound";

function FaqAccor({ Faqdata }) {
  // console.log("faqdatafaqdata", Faqdata);
  // const faq = [
  //   {
  //     id: 1,
  //     heading: "When do I get a confirmation email?",
  //     content:
  //       "Normally, you will receive a confirmation email along with the booking voucher (PDF file) within 60 minutes of making your reservation. If you still haven't received it after that time, please check your junk mail or spam folder. You can always view your booking status online by signing in and selecting Bookings section from the account menu.",
  //   },
  //   {
  //     id: 2,
  //     heading:
  //       "What is the maximum number of rooms that I can book per booking?",
  //     content:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  //   },
  //   {
  //     id: 3,
  //     heading:
  //       "What should I do if I can't find my booking confirmation email?",
  //     content:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  //   },

  //   {
  //     id: 4,
  //     heading: "Why do I see no availability for a hotel?",
  //     content:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  //   },
  //   {
  //     id: 5,
  //     heading: "Why did my booking expire?",
  //     content:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  //   },
  //   {
  //     id: 6,
  //     heading:
  //       "Is there any discount available if I do not have any AVA tokens?",
  //     content:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  //   },
  //   {
  //     id: 7,
  //     heading:
  //       "What should I do if my booking expires or fails despite having already made the payment?",
  //     content:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  //   },
  //   {
  //     id: 7,
  //     heading: "How do we determine our sort and filter order?",
  //     content:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  //   },
  // ];

  return (
    <div className="faq-accor-content">
      <h6 className="mb-0 themeclr fw-800">{Faqdata?.[0]?.Category}</h6>
      <div className="faq-accordion">
        <Accordion>
          {Faqdata?.map((item, index) => {
            return (
              <>
                <Accordion.Item eventKey={index + 1} className="">
                  <Accordion.Header>{item.Question}</Accordion.Header>
                  <Accordion.Body>{item.Answer}</Accordion.Body>
                </Accordion.Item>
              </>
            );
          })}
        </Accordion>

        {Faqdata.length == 0 && (
          <div className="mt-3">
            <NodataFound />
          </div>
        )}
      </div>
    </div>
  );
}

export default FaqAccor;
