import { combineReducers } from "redux";
import { Register_Section,Logout,Hotels } from "./constants";

const Initial_State = {

  User:{
    token : '',
    UserDetails : '',
  },

  }
const Hotel_Initial_State=[]

function LoginReducer(state = Initial_State, action) {
  // console.log("actionaction",action);
    switch (action.type) {
      
      case Register_Section:
        return {
          ...state,
          ...action.Register_Section
        }
        case Logout:
          return Initial_State
          

      default:
        return state;
    }
  }  
  function HotelReducer(state = Hotel_Initial_State, action) {
    // console.log("actionaction",action);
      switch (action.type) {  
        
        case Hotels:
          return [...action.Hotels]
            
        default:
          return state;
      }
    }  


  
  

const PieTrip = combineReducers({LoginReducer:LoginReducer,HotelReducer:HotelReducer});

export default PieTrip;