import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/Form";
import { allData } from "../Data/CommonData";
import { BiRightArrowAlt } from "react-icons/bi";
import { ChangeValType, DynamicArithemetics } from "../lib/common";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
function HotelSelectFilter({Hotelsdetails,SetHotelsdetails}) {
  const [sliceVal, setSliceVal] = useState(3);
  const [amount, setamount] = useState({
    min:"",
    max:""
  });

  const [Filters, setFilters] = useState({
suggested:[],
pricepernight:[],
// yourbudget:[],
starcategory:[],
userrating:[],
propertytype:[]

  });

  const Hotelsdata = useSelector((state) => state.HotelReducer);
  console.log("HotelsdataHotelsdata",Hotelsdata);

  const Onchangefilters = (Id,data) => {
    let findindex = Filters[Id]?.findIndex(
      (val) => val?.title == data?.title
    );
    let instselfilter = Filters[Id];
    if (findindex > -1) {
      instselfilter = [
        ...instselfilter.slice(0, findindex),
        ...instselfilter.slice(findindex + 1, instselfilter?.length),
      ];
    } else {
      instselfilter = [...instselfilter, ...[data]];
    }
      setFilters({...Filters,...{[Id]:instselfilter}})

  };

const FiltersChange =()=>{
  // if(Filters.suggested.length>0){
    let alldata = Hotelsdata;
    let checkfilter = Object.values(Filters).some((val)=>val?.length>0)
    let outputarray = [];
    if(checkfilter){
      alldata?.map((hotelval)=>{
        let keys = Object.keys(Filters);
        let count = keys?.length;
        let i = 0;
        let conditionstatus = true;
        while(count>0){
          if(conditionstatus){
            if(Filters?.[keys[i]]?.length>0){
              let insamecondstatus = false;
              Filters[keys[i]].map((item,filterind)=>{

                if(DynamicArithemetics({
                  first: ChangeValType({data: item?.value, type: item.type}),
                  second: ChangeValType({data: hotelval?.[item.key], type: item.type}),
         
                  operation: item?.operation
                })){
                  if(item?.secondoperation){
                    if(DynamicArithemetics({
                      first: ChangeValType({data: item?.secondvalue, type: item.type}),
                      second: ChangeValType({data: hotelval?.[item.key], type: item.type}),
       
                      operation: item?.secondoperation
                    })){
                      conditionstatus = true;
                      insamecondstatus = true;
                      
                    }
                    else{
                      if(item?.samecond!="or"){
                        conditionstatus = false;
                      }
                      else if(insamecondstatus==false&&Filters[keys[i]]?.length==filterind+1){
                        conditionstatus = false;
                      }
                    }
                  }
                  else{
                    conditionstatus = true;
                    insamecondstatus = true;
                  }
                }
                else{
                  if(item?.samecond!="or"){
                    conditionstatus = false;
                  }
                  else if(insamecondstatus==false&&Filters[keys[i]]?.length==filterind+1){
                    conditionstatus = false;
                  }
                }
              })
            }
            count = count-1;
          }
          else{
            count = 0
          }
          i = i+1
        }
        if(conditionstatus){
          outputarray.push(hotelval)
        }

        SetHotelsdetails([...outputarray])

      })
    }
    else{
      SetHotelsdetails([...Hotelsdata])
      return true
    }
      


}

useEffect(()=>{
  FiltersChange()
},[Filters])


const Amountsubmit =()=>{
  if(amount?.min&&amount?.max){


    let amountobj={
      title: "amount",
      value: amount?.min,
      key: "pricepernight",
      operation: "<=",
      type: "number",
      samecond: "or",
      secondvalue: amount?.max,
      secondoperation: ">="
    }
    
    let findindex = Filters['pricepernight']?.findIndex(
      (val) => val?.title == amountobj?.title
    );
    
    let instselfilter = Filters['pricepernight'];
    if (findindex > -1) {
      instselfilter = [
        ...instselfilter.slice(0, findindex),
        ...instselfilter.slice(findindex + 1, instselfilter?.length),
      ];
    } else {
      instselfilter = [...instselfilter, ...[amountobj]];
    }
      setFilters({...Filters,...{['pricepernight']:instselfilter}})


  }
  else{
    toast.error("Please fill the amount...!")
  }


}
  return (
    <div>
      {/* <div className="filter-sec">
        <Button className="explore-map">EXPLORE ON MAP</Button>
      </div> */}
      <div className="select-filters mt-3">
        {/* <h5 className="fw-800">Select Filters</h5> */}
        <div className="suggested">
          <Form className="">
            <div>
              {/* <div>
                <h6 className="m-0 filterTitle mt-4">Suggested For You</h6>
                {allData.filterData.map((item, index) => (
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="pm_mapping_check">
                      <Form.Check
                        className="mt-1"
                        name="group1"
                        label={item.title}
                        type="checkbox"
                        id={index+'sfy'}
                        onChange={(e)=>Onchangefilters('suggested',item)}
                      />
                    </div>
                    <span className="search__filterCounter">
                    </span>
                  </div>
                ))}
              </div> */}

              <div>
                <h6 className="m-0 filterTitle mt-4">Price per night</h6>
                {allData?.filterData1.map((item, index) => (
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="pm_mapping_check">
                      <Form.Check
                        className="mt-1"
                        name="group1"
                        label={item.title}
                        type="checkbox"
                        id={index+'ppn'}
                        onChange={(e)=>Onchangefilters('pricepernight',item)}

                      />
                    </div>
                    <span className="search__filterCounter">
                      {/* ({item.title.length}) */}
                    </span>
                  </div>
                ))}
              </div>

              <p className="search__secondaryTitle m-0 mt-2 mb-1">
                Your Budget
              </p>
              <div className="search__filterInpHolder d-flex justify-content-between align-items-center">
                <input
                  type="number"
                  placeholder="Min"
                  className="search__filterInp flex-grow-1"
                  value={amount?.min}
                  onChange={(e)=> {setamount({...amount,['min']:e.target.value<0?0:e.target.value})}}
                />
                <p className="m-0 search__toDivider">to</p>
                <input
                  type="number"
                  placeholder="Max"
                  className="search__filterInp flex-grow-1"
                  value={amount?.max}

                  onChange={(e)=> {setamount({...amount,['max']:e.target.value<0?0:e.target.value})}}

                />
               <button className="cmn__themeBtn rounded-1" type="button"
               onClick={()=>Amountsubmit()}
               >
                  <BiRightArrowAlt />
                </button>
              </div>

              {/* <div className="search__switcher d-flex justify-content-start align-items-center gap-3 mt-5">
                <p className="search__switchLabel m-0">Basic Value Stays 0</p>

                <label class="switch">
                  <input type="checkbox" />
                  <span class="slider round"></span>
                </label>
              </div> */}
              <div>
                <h6 className="m-0 filterTitle mt-4">Star Category</h6>
                {allData.filterData2.map((item, index) => (
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="pm_mapping_check">
                      <Form.Check
                        className="mt-1"
                        name="group1"
                        label={item.title}
                        type="checkbox"
                        id={index+'sc'}
                        onChange={(e)=>Onchangefilters('starcategory',item)}

                      />
                    </div>
                    <span className="search__filterCounter">
                      {/* ({item.title.length}) */}
                    </span>
                  </div>
                ))}
              </div>

              <div>
                <h6 className="m-0 filterTitle mt-4">User Rating</h6>
                {allData.filterData3.map((item, index) => (
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="pm_mapping_check">
                      <Form.Check
                        className="mt-1"
                        name="group1"
                        label={item.title}
                        type="checkbox"
                        id={index+'ur'}
                        onChange={(e)=>Onchangefilters('userrating',item)}

                      />
                    </div>
                    <span className="search__filterCounter">
                      {/* ({item.title.length}) */}
                    </span>
                  </div>
                ))}
              </div>

              <div>
                <h6 className="m-0 filterTitle mt-4">Property Type</h6>
                {allData.filterData4.map((item, index) => (
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="pm_mapping_check">
                      <Form.Check
                        className="mt-1"
                        name="group1"
                        label={item.title}
                        type="checkbox"
                        id={index}
                        onChange={(e)=>Onchangefilters('propertytype',item)}

                        
                      />
                    </div>
                    <span className="search__filterCounter">
                      {/* ({item.title.length}) */}
                    </span>
                  </div>
                ))}
              </div>

              {/* <div>
                <h6 className="m-0 filterTitle mt-4">Locality</h6>
                <p className="search__secondaryTitle m-0 mt-2 mb-1">
                  Popular with Tourists:
                </p>
                {(allData.filterData5.length > 4
                  ? allData.filterData5.slice(0, sliceVal)
                  : allData.filterData5
                ).map((item, index) => (
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="pm_mapping_check">
                      <Form.Check
                        className="mt-1"
                        name="group1"
                        label={item.title}
                        type="checkbox"
                        id={index}
                      />
                    </div>
                    <span className="search__filterCounter">
                      ({item.title.length})
                    </span>
                  </div>
                ))}
              </div> */}
              {/* <p
                className="m-0 search__showMore mt-2 text-end"
                onClick={() =>
                  setSliceVal(sliceVal == 3 ? allData.filterData5.length : 3)
                }
              >
                {sliceVal <= 3 ? "Show More" : "Show Less"}
              </p> */}

              {/* <div>
                <p className="search__secondaryTitle m-0 mt-2 mb-1">
                  Near Transit Hub(s):
                </p>
                {allData.filterData6.map((item, index) => (
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="pm_mapping_check">
                      <Form.Check
                        className="mt-1"
                        name="group1"
                        label={item.title}
                        type="checkbox"
                        id={index}
                      />
                    </div>
                    <span className="search__filterCounter">
                      ({item.title.length})
                    </span>
                  </div>
                ))}
              </div> */}
{/* 
              <div>
                <p className="search__secondaryTitle m-0 mt-2 mb-1">
                  Top Areas
                </p>
                {allData.filterData7.map((item, index) => (
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="pm_mapping_check">
                      <Form.Check
                        className="mt-1"
                        name="group1"
                        label={item.title}
                        type="checkbox"
                        id={index}
                      />
                    </div>
                    <span className="search__filterCounter">
                      ({item.title.length})
                    </span>
                  </div>
                ))}
              </div> */}

              {/* <div>
                <h6 className="m-0 filterTitle mt-4">Room Views</h6>
                {allData.filterData8.map((item, index) => (
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="pm_mapping_check">
                      <Form.Check
                        className="mt-1"
                        name="group1"
                        label={item.title}
                        type="checkbox"
                        id={index}
                      />
                    </div>
                    <span className="search__filterCounter">
                      ({item.title.length})
                    </span>
                  </div>
                ))}
              </div> */}

              {/* <div>
                <h6 className="m-0 filterTitle mt-4">Room Amenities</h6>
                {(allData.filterData9.length > 3
                  ? allData.filterData9.slice(0, sliceVal)
                  : allData.filterData9
                ).map((item, index) => (
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="pm_mapping_check">
                      <Form.Check
                        className="mt-1"
                        name="group1"
                        label={item.title}
                        type="checkbox"
                        id={index}
                      />
                    </div>
                    <span className="search__filterCounter">
                      ({item.title.length})
                    </span>
                  </div>
                ))}
              </div> */}
              <p
                className="m-0 search__showMore mt-2 text-end"
                onClick={() =>
                  setSliceVal(sliceVal == 3 ? allData.filterData9.length : 3)
                }
              >
                {/* {sliceVal <= 3 ? "Show More" : "Show Less"} */}
              </p>

              {/* <div>
                <h6 className="m-0 filterTitle mt-4">Chains</h6>
                {(allData.filterData10.length > 3
                  ? allData.filterData10.slice(0, sliceVal)
                  : allData.filterData10
                ).map((item, index) => (
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="pm_mapping_check">
                      <Form.Check
                        className="mt-1"
                        name="group1"
                        label={item.title}
                        type="checkbox"
                        id={index}
                      />
                    </div>
                    <span className="search__filterCounter">
                      ({item.title.length})
                    </span>
                  </div>
                ))}
              </div> */}

              {/* <p
                className="m-0 search__showMore mt-2 text-end"
                onClick={() =>
                  setSliceVal(sliceVal == 3 ? allData.filterData10.length : 3)
                }
              >
                {sliceVal <= 3 ? "Show More" : "Show Less"}
              </p> */}

              {/* <div>
                <h6 className="m-0 filterTitle mt-4">Amenities</h6>
                <div className="search__amentiesSearchHolder position-relative d-flex justify-content-between align-items-center gap-3 p-2 mb-2">
                  <input
                    type="text"
                    className="search__amentiesSearch flex-grow-1"
                    placeholder="Search amenities"
                  />
                  <BiSearch />
                </div>
                <p className="search__secondaryTitle m-0 mt-2 mb-1">
                  Guests Love
                </p>
                {allData.filterData11.map((item, index) => (
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="pm_mapping_check">
                      <Form.Check
                        className="mt-1"
                        name="group1"
                        label={item.title}
                        type="checkbox"
                        id={index}
                      />
                    </div>
                    <span className="search__filterCounter">
                      ({item.title.length})
                    </span>
                  </div>
                ))}
              </div> */}

              {/* <div>
                <h6 className="m-0 filterTitle mt-4">Booking Preference</h6>
                {allData.filterData12.map((item, index) => (
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="pm_mapping_check">
                      <Form.Check
                        className="mt-1"
                        name="group1"
                        label={item.title}
                        type="checkbox"
                        id={index}
                      />
                    </div>
                    <span className="search__filterCounter">
                      ({item.title.length})
                    </span>
                  </div>
                ))}
              </div> */}

              {/* <div>
                <h6 className="m-0 filterTitle mt-4">House Rules</h6>
                {allData.filterData13.map((item, index) => (
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="pm_mapping_check">
                      <Form.Check
                        className="mt-1"
                        name="group1"
                        label={item.title}
                        type="checkbox"
                        id={index}
                      />
                    </div>
                    <span className="search__filterCounter">
                      ({item.title.length})
                    </span>
                  </div>
                ))}
              </div> */}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default HotelSelectFilter;
