import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

// import Profile from "../assets/images/account/profile.png";
import Profile from "../assets/images/account/dummy_user.png";
import ProSub from "../assets/images/account/pro-sub.png";
import bulb from "../assets/images/account/bulb.png";

import account from "../assets/images/account/tabs/account.png";
import booking from "../assets/images/account/tabs/booking.png";
import wishlist from "../assets/images/account/tabs/wishlist.png";

import plus from "../assets/images/account/bink-plus.png";
import check from "../assets/images/account/checked.png";
import calendar from "../assets/images/account/calendar.png";

import fbText from "../assets/images/account/socialIcon/facebook-txt.png";
import fbLogo from "../assets/images/account/socialIcon/facebook-logo.png";

import googleText from "../assets/images/account/socialIcon/google-txt.png";
import googleLogo from "../assets/images/account/socialIcon/google-logo.png";

import binanceText from "../assets/images/account/socialIcon/binance-txt.png";
import binanceLogo from "../assets/images/account/socialIcon/binance-logo.png";

import googleAuth from "../assets/images/account/googleAuth.png";
import phoneVerification from "../assets/images/account/phoneVerification.png";

import { FaPencilAlt } from "react-icons/fa";
import { Button } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import Select from "react-select";
import DatePicker from "react-datepicker";
import ReactFlagsSelect from "react-flags-select";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput,{isValidPhoneNumber} from "react-phone-number-input";
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";
import Wishlist from "../components/Wishlist";
import Bookings from "../components/Bookings";
import { LoadToast, Name_showing, isEmpty, updateToast, validation } from "../lib/common";
import { toast } from "react-toastify";
import * as User from "../Axios/user.axios";
import { useDispatch, useSelector } from "react-redux";
import config from "../lib/config"
import { useNavigate,useLocation } from 'react-router-dom';
import moment from "moment";
import OldEmailOtpModal from "../modals/OldEmailOtpModal";
import NewEmailOtpModal from "../modals/NewEmailOtpModal";
import Changeemail from "../modals/Changeemailmodel";







function Account({stripe}) {
  const [activeTabs, setActiveTabs] = useState(0);
  const [selectedOption1, setSelectedOption1] = useState("Mr");
  const [selectedOption2, setSelectedOption2] = useState("pdf");
  const [select, setSelect] = useState("IN");

  const [pwd, setPwd] = useState(false);
  const [cnfmPwd, setCnfmPwd] = useState(false);
  const [oldPwd, setoldPwd] = useState(false);
  const [change, setchange] = useState(false);
  const [oldEmailOtpModal, setOldEmailOtpModal] = useState(false);
  const [newEmailOtpModal, setNewEmailOtpModal] = useState(false);
  const [newEmail, setnewEmail] = useState(false);
  const [newEmailOtp, setnewEmailOtp] = useState(false);
  const [updateEmail, setupdateEmail] = useState("");

  



  const [accTabs, setAccTabs] = useState("Account");
  const [StatusTabs, setstatusTabs] = useState("Pending");
  const [accPersonal, setAccPersonal] = useState("personal-details");
  const { UserDetails } = useSelector((state) => state.LoginReducer.User);
  const [Logintype, setLogintype] = useState( localStorage.getItem("logintype"));




  const initial = {
    Profile: "",
    Title: "",
    FirstName: "",
    LastName: "",
    Email: "",
    Nationality: "IN",
    Address: "",
    MobileNumber: "",
    // Affiliatecode: "",
    City: "",
    DateofBirth: "",
    Zipcode: "",
    Documenttype: "Passport",
    Documentnumber: "",
    Issuedby: "IN",
    // Documentexpirationdate: ""
  };
  const initialpassworddata = {
    NewPassword:"",
    ConfirmPassword:"",
    OldPassword:""
  };

  const [passwordata, setPassworddata] = useState(initialpassworddata);

  const [formdata, setFormdata] = useState(initial);
  const [ValidateError, SetValidateError] = useState({});
  const [Err, setErr] = useState({});
/** redux*/
  const dispatch = useDispatch();
  /**Navigation */
  const navigate = useNavigate();
  const location = useLocation();


/**Props Data Get */
  const searchParams = new URLSearchParams(location?.search);
  const bookingstatus = searchParams.get("bookingstatus");
  const  tabname = searchParams.get("tabname");


  const inputRef = useRef(null);

  const handleImageUpload = () => {
    inputRef.current.click();
  };


  const AccountSideTabs = [
    {
      id: 1,
      icon: account,
      name: "Account",
    },
    {
      id: 2,
      icon: booking,
      name: "Bookings",
    },
    // {
    //   id: 3,
    //   icon: wallet,
    //   name: "Wallet",
    // },
    // {
    //   id: 4,
    //   icon: credit,
    //   name: "Credits",
    // },
    // {
    //   id: 5,
    //   icon: smart,
    //   name: "Smart",
    // },
    // {
    //   id: 6,
    //   icon: trip,
    //   name: "Trip DAO",
    // },
    // {
    //   id: 7,
    //   icon: passport,
    //   name: "Passport",
    // },
    {
      id: 8,
      icon: wishlist,
      name: "WishList",
    },
  ];

  const handleActive = (index, val) => {
    setActiveTabs(index);
    setAccTabs(val);
  };

  const options = [
    { value: "mr", label: "Mr" },
    { value: "miss", label: "Miss" },
    { value: "mrs", label: "Mrs" },
  ];

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isHovered
          ? "#653883"
          : isSelected
            ? "#653883"
            : isFocused
              ? "#653883"
              : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
            ? "#fff"
            : isFocused
              ? "#fff"
              : "",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "38px",
      padding: "0px 10px",
      border: "none",
      backgroundColor: "#fff",
      color: "#000",
      borderColor: "#BEBEBE",
      borderRadius: "4px",
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: "4px",
      backgroundColor: "transparent",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
      padding: "0px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#555555",
    }),
    menuList: (base) => ({
      ...base,

      padding: 0,
      width: "100%",

      borderRadius: 5,
      background: "transparent",
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#000",
      };
    },
  };


useEffect(()=>{
if(isEmpty(UserDetails)&& isEmpty(localStorage.getItem("userInfo"))){
  navigate('/')
}
},[UserDetails])
  
  const handleChange1 = (selectedOption) => {
    setSelectedOption1(selectedOption);
  };

  const document = [
    { value: "Passport", label: "Passport" },
    // { value: "word", label: "Word" },
  ];

  const handleChange2 = (selectedOption) => {
    setSelectedOption2(selectedOption);
  };

  useEffect(() => {
    if (UserDetails) {
      setFormdata({
        ...formdata,
        ...{
          Profile: UserDetails?.Profile ?? "",
          Title: UserDetails?.Title ?? "",
          FirstName: UserDetails?.FirstName ?? "",
          LastName: UserDetails?.LastName ?? "",
          Email: UserDetails?.Email ?? "",
          Nationality: UserDetails?.Nationality ?? "IN",
          Address: UserDetails?.Address ?? "",
          MobileNumber: UserDetails?.MobileNumber,
          // Affiliatecode: UserDetails?.Affiliatecode,
          City: UserDetails?.City,
          DateofBirth: UserDetails?.DateofBirth,
          Countrycode: UserDetails?.Countrycode,
          Zipcode: UserDetails?.Zipcode,
          Id: UserDetails?._id,
          Documentnumber: UserDetails?.Documents?.[0]?.Documentnumber,
          // Documentexpirationdate:UserDetails?.Documents?.[0]?.Documentexpirationdate??""
        },
      });
    }
  }, [UserDetails]);

useEffect(()=>{

  if(bookingstatus){
    setAccTabs('Bookings')
    setActiveTabs(1);
    setstatusTabs(bookingstatus)
  }
  else if(tabname){
    setAccTabs(tabname)
    setActiveTabs(tabname=='WishList'?3:2);
  }

},[bookingstatus,tabname])



  const onchange = async(e) => {
    SetValidateError({})
    const { id, value, files } = e.target;

    if (files) {
      if (!files[0]?.type?.includes("image")) {
        return toast.error("Only images are allowed");
      }


      let { success, msg, token, data } = await User.ProfileUpdate({Profile:files[0],Email:formdata?.Email,Id:formdata.Id});

      setFormdata({ ...formdata, ...{ [id]: files[0] } })
    }
    else {
      setFormdata({ ...formdata, ...{ [id]: value } });

    }
  };
  const FormSubmit = async () => {
    const id = LoadToast();
    let Errors = await validation(Object.keys(initial), formdata);
    console.log("erresdsrr",Errors);
    if (Object.keys(Errors)?.length > 0) {
      updateToast(id, "fix");
      SetValidateError(Errors);
      return;
    }
    SetValidateError({});

    let { success, msg, token, data } = await User.ProfileUpdate(formdata);
    updateToast(
      id,
      msg,
      success
    );
    if (success == "success") {
      dispatch({
        type: "Register_Section",
        Register_Section: {
          User: {
            UserDetails: data,
            token: token,
          },
        },
      })
    }
    setTimeout(() => {
      window.location.reload()

    }, 2000);

  }

  const Logout =async()=>{
    localStorage?.removeItem("userInfo");
    localStorage?.removeItem("logintype");
    dispatch({
      type: "Logout",
  
    });
    navigate('/')
    toast.success('Logout Successfully')
    
  }

  const Passwordonchange=(e)=>{
    SetValidateError({})
    const { id, value } = e.target;
    setPassworddata({ ...passwordata, ...{ [id]: value } });

  }

  const passwordsubmit = async () => {

    const id = LoadToast();
    let err = await validation(Object.keys(initialpassworddata), passwordata);
    
    if (Object.keys(err)?.length > 0) {
      updateToast(id, "fix");
      setErr(err);
      return;
    }
    setErr({});
    if(passwordata?.ConfirmPassword!=passwordata?.NewPassword){
      updateToast(id, "Confirm password must be same as the New Password ","error");
      setErr(err);
      return;
    }
    // if(passwordata?.ConfirmPassword!=passwordata?.OldPassword){
    //   updateToast(id, "Enter Valid Old Password...!","error");
    //   setErr(err);
    //   return;
    // }

    let sendata={
      Password:passwordata?.ConfirmPassword,
      OldPassword:passwordata?.OldPassword,
      Email:UserDetails?.Email
    }
    var { success, msg } = await User.ChangePassword(sendata);
    updateToast(
      id,
      msg,
      success
    );
    if (success=='success') {

      setTimeout(() => {
        setPassworddata(initialpassworddata)
      }, 2000);
      setoldPwd(false)
      setCnfmPwd(false)
      setPwd(false)
    } else setErr(err);
  };




const handleOldEmailEmailClose = () => {
  setOldEmailOtpModal(false)
}
const handleNewEmailEmailClose = () => {
  setNewEmailOtpModal(false)
}
const handleNewEmailClose=()=>{
  setnewEmail(false)
}
const Changeemailfun =async()=>{
  setchange(true)
  const id = LoadToast();

  let {success,msg} =await User.SendOtp({Email:UserDetails?.Email})
  updateToast(
    id,
    msg,
    success
  );
  if(success=='success'){
    setTimeout(() => {
      setchange(false)

    }, 1000);
    setOldEmailOtpModal(true)

  }
  // else{
    
  // }
}

  return (
    <>
    <div className="account-page">
      <Header />
      
      <div className="preHeader">
        <div className="container">
          <h5 className="mb-0 fw-800">Account</h5>
        </div>
      </div>
      <div className="container">
        <div className="acc-pag-nav mt-4">
          <span className="inact-name">Home</span> /{" "}
          <span className="act-name">Account</span>
        </div>
        <div className="row py-4">
          <div className="col-12 col-sm-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div className="prof-headings">
              <img
                src={
                  typeof formdata?.Profile == "string"
                    ? isEmpty(formdata?.Profile)
                      ? Profile
                      : `${config?.IMG_URL}/user/${UserDetails?._id}/profile/${UserDetails?.Profile}`
                    : formdata?.Profile?.type
                      ? URL.createObjectURL(
                        formdata?.Profile
                      )
                      : Profile
                }

                className="img-fluid profile-logo" />
        
              {/* <img src={ProSub} className="img-fluid pro-sub" /> */}
              <div className="edit-pro" >
                <input

                  type="file"
                  ref={inputRef}
                  style={{ display: "none" }}
                  id="Profile"
                  onChange={(e) => onchange(e)}
                />

                <FaPencilAlt onClick={handleImageUpload} />
              </div>
            </div>
        
            <h5 className="name text-center mt-2 fw-700">{Name_showing(formdata?.FirstName + formdata?.LastName)}</h5>
           
            {ValidateError?.Profile && (
                <p className="text-center err_msg_txt my-2">
                  {ValidateError?.Profile}
                  
                </p>
             )} 
            
            <div className="wlcm-back mt-3">
              <img src={bulb} className="bulb-img img-fluid" />
              <div>
                <p className="mb-0 fw-600">Welcome back,</p>
                <p className="mb-1 fw-600">{Name_showing(formdata?.FirstName)}</p>
                <p className="mb-0 f-15">Smart Basic member</p>
              </div>
            </div>
            <div className="prof-cus-tabs my-4">
              {AccountSideTabs.map((item, index) => {
                return (
                  <div
                    className={
                      index == activeTabs ? "tbs-itms active" : "tbs-itms"
                    }
                    onClick={() => handleActive(index, item.name)}
                  >
                    <div className="lft-bdr"></div>
                    <div className="tbs-heading">
                      <img src={item.icon} className="img-fluid tbs-icon" />
                      <span className="tbs-txt">{item.name}</span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="prof-lgot-btn mb-4 d-flex align-items-center justify-content-center">
              <Button className="cmn-linear-btn" onClick={()=>Logout()}>Log Out</Button>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
            {accTabs == "Account" ? (
              <>
                <div className="prf-personalInfo">
                  {/* <div className="prof-Progress">
                    <div className="pb-1 d-flex align-items-cemter justify-content-between">
                      <span className="f-12 gray fw-600">
                        Complete your Profile
                      </span>
                      <span className="f-12 fw-700">55%</span>
                    </div>
                    <ProgressBar now={60} />
                  </div> */}
                  <p className="mb-0 f-12 gray fw-600 pt-2">
                    Get the best out of TripFi by adding the remaining
                    details!
                  </p>
                  {/* <div className="verfifyAcc mt-4">
                    <div className="d-flex align-items-center gap-2">
                      <img src={plus} className="img-fluid" />
                      <span className="fw-600 f-13 gray">Verify Email</span>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <img src={check} className="img-fluid" />
                      <span className="fw-600 f-13 purple">
                        Verified mobile Number
                      </span>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <img src={check} className="img-fluid" />
                      <span className="fw-600 f-13 purple">
                        Complete Basic Info
                      </span>
                    </div>
                  </div> */}
                  <div className="cus-PersonalInfo-tabs mt-4">
                    <div className="tabs-headings">
                      <span
                        className={
                          accPersonal == "personal-details"
                            ? "name active"
                            : "name"
                        }
                        onClick={() => setAccPersonal("personal-details")}
                      >
                        Personal Details
                      </span>
                      {/* <span
                        className={
                          accPersonal == "connect-accounts"
                            ? "name active"
                            : "name"
                        }
                        onClick={() => setAccPersonal("connect-accounts")}
                      >
                        Connect Accounts
                      </span> */}
                      <span
                        className={
                          accPersonal == "settings" ? "name active" : "name"
                        }
                        onClick={() => setAccPersonal("settings")}
                      >
                        Settings
                      </span>
                      {/* <span
                        className={
                          accPersonal == "security" ? "name active" : "name"
                        }
                        onClick={() => setAccPersonal("security")}
                      >
                        Security
                      </span> */}
                    </div>
                    {accPersonal == "personal-details" ? (
                      <div className="mt-3 tbs-item">
                        <div className="tabs-content">
                          <div className="yourBook">
                            <p className="mb-1 gray1 fw-600 f-14">
                              For when you book
                            </p>
                            <p className="mb-0 gray1 fw-500 f-12">
                              This information is only used to autofill your
                              details and make it quicker for you to book. Your
                              details will be stored securely and won't be
                              shared publicly.
                            </p>
                          </div>
                          <div className="userInPuts mt-4">
                            <div className="row">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                <div className="row">
                                  <div className="col-12 col-sm-6 col-xl-4 mb-3 mb-sm-0">
                                    <p className="label-txt mb-2">Title</p>
                                    <Select
                                      value={{
                                        label: formdata.Title,
                                        value: formdata.Title,
                                      }}
                                      // onChange={() => handleChange1()}
                                      options={options}
                                      defaultValue="theme1"
                                      styles={stylesgraybg}
                                      isSearchable={false}
                                      classNamePrefix="customselect"
                                      onChange={(e) => {
                                        setFormdata({
                                          ...formdata,
                                          ...{ ["Title"]: e.label },
                                        });
                                        SetValidateError({})

                                      }}
                                    // defaultInputValue="Theme 1"
                                    // menuIsOpen
                                    />
                                    {ValidateError?.Title && (
                                      <p className="text-left err_msg_txt">{ValidateError?.Title}</p>
                                    )}
                                  </div>
                                  <div className="col-12 col-sm-6 col-xl-8 mb-3 mb-sm-0">
                                    <p className="label-txt mb-2">First Name</p>
                                    <input
                                      type="text"
                                      placeholder="First Name"
                                      className="cmn-inputFields"
                                      id="FirstName"
                                      value={formdata.FirstName}
                                      onChange={onchange}
                                    />
                                    {ValidateError?.FirstName && (
                                      <p className="text-left err_msg_txt">{"First Name is required"}</p>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                                <p className="label-txt mb-2">Last name</p>
                                <input
                                  type="text"
                                  placeholder="Last Name"
                                  className="cmn-inputFields"
                                  id="LastName"
                                  value={formdata.LastName}
                                  onChange={onchange}
                                />
                                {ValidateError?.LastName && (
                                  <p className="text-left err_msg_txt">{"Last Name is Required"}</p>
                                )}
                              </div>

                              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                                <p className="label-txt mb-2">Nationality</p>
                                <ReactFlagsSelect

                                  selected={select}
                                  onSelect={(e) =>
                            {       setFormdata({
                                      ...formdata,
                                      ...{ ["Nationality"]: e },
                                    });
                                    SetValidateError({});
                                  }
                                  }
                                  countries={[
                                    "IN",
                                    "GB",
                                    "IE",
                                    "IT",
                                    "NL",
                                    "SE",
                                  ]}
                                  placeholder="India"
                                  className="country-name-input"
                                />
                                {ValidateError?.Nationality && (
                                  <p className="text-left err_msg_txt">{ValidateError?.Nationality}</p>
                                )}

                              </div>
      
                              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                                <p className="label-txt mb-2">Address</p>
                                <input
                                  type="text"
                                  placeholder="Enter Your Address"
                                  className="cmn-inputFields"
                                  id="Address"
                                  value={formdata.Address}
                                  onChange={onchange}
                                />
                                {ValidateError?.Address && (
                                  <p className="text-left err_msg_txt">{ValidateError?.Address}</p>
                                )}
                              </div>
                              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                                <p className="label-txt mb-2">Phone number</p>
                                <PhoneInput
                                  className="cmn-PhoneInput"
                                  international
                                  defaultCountry="IN"
                                  placeholder="Enter phone number"
                                  id="MobileNumber"
                                  value={formdata?.MobileNumber}
                                  onChange={(e) => {
                                    setFormdata({
                                      ...formdata,
                                      ...{ ["MobileNumber"]: e }
                                    });
                                    SetValidateError({})

                                  }
                                  }
                                />
                                {ValidateError?.MobileNumber && (
                                  <p className="text-left err_msg_txt">{'Mobile Number is required '}</p>
                                )}
                                           {ValidateError?.MobileNumberval && (
                                  <p className="text-left err_msg_txt">{ValidateError?.MobileNumberval}</p>
                                )}
                              </div>
                              {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                                <p className="label-txt mb-2">Affiliate code</p>
                                <input
                                  type="number"
                                  placeholder="Enter Your Affiliate Code"
                                  className="cmn-inputFields"
                                  id="Affiliatecode"
                                  value={formdata.Affiliatecode}
                                  onChange={onchange} />
                                {ValidateError?.Affiliatecode && (
                                  <p className="text-left err_msg_txt">{ValidateError?.Affiliatecode}</p>
                                )}
                              </div> */}
                              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                                
                                <div className="mb-2 d-flex align-items-center justify-content-between">
                                  <p className="label-txt mb-0">Email</p>
                                  <p className="mb-0 affiliate-Prog" onClick={()=>Changeemailfun()}>
                                  {       change ?  

                                    'Processing...' : 'Change Email'

                                  }
                                  </p>
                                </div>
                                <input
                                  type="email"
                                  placeholder="Email"
                                  className="cmn-inputFields"
                                  id="Email"
                                  value={formdata.Email}
                                  // onChange={onchange}
                                   />
{/* {       change?                      
 <button > Processing...</button>:
<button onClick={()=>Changeemailfun()}>Change</button>

}     */}
                           {ValidateError?.Email && (  
                                  <p className="text-left err_msg_txt">{ValidateError?.Email}</p>
                                )}
                              </div>

                              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                                <div className="mb-2 d-flex align-items-center justify-content-between">
                                  <p className="label-txt mb-0">City</p>
                                  <p className="mb-0 affiliate-Prog">
                                    {/* Affiliate Program */}
                                  </p>
                                </div>
                                <input
                                  type="text"
                                  placeholder="Enter your affiliate code"
                                  className="cmn-inputFields"
                                  id="City"
                                  value={formdata.City}
                                  onChange={onchange}
                                />
                                {ValidateError?.City && (
                                  <p className="text-left err_msg_txt">{ValidateError?.City}</p>
                                )}
                              </div>
                              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                                <p className="label-txt mb-2">Zipcode</p>
                                <input
                                  type="text"
                                  placeholder="Enter Your Zipcode"
                                  className="cmn-inputFields"
                                  id="Zipcode"
                                  value={formdata.Zipcode}
                                  onChange={onchange}
                                />
                                {ValidateError?.Zipcode && (
                                  <p className="text-left err_msg_txt">{ValidateError?.Zipcode}</p>
                                )}
                              </div>
                              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                                <p className="label-txt mb-2">Birthday</p>
                                <div className="dob-cmn-datePicker">
                                  {" "}
                                  <DatePicker
                                    selected={formdata.DateofBirth}
                                    onChange={(e) => {
                                      setFormdata({
                                        ...formdata,
                                        ...{ ["DateofBirth"]: e }
                                      });
                                      SetValidateError({})

                                    }}
                                    showIcon
                                    peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
                                    placeholderText="Enter DOB"
                                    icon={<img src={calendar} />}
                                    className=""
                                    value={formdata?.DateofBirth?moment(formdata?.DateofBirth).format('DD-MM-YYYY'):Date.now()}
                                  />
                                  {ValidateError?.DateofBirth && (
                                    <p className="text-left err_msg_txt">{ValidateError?.DateofBirth}</p>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="docId mt-4">
                              <p className="mb-0 f-16 fw-700 gray1">
                                Document or ID Details
                              </p>
                              <p className="mb-0 f-13 fw-500 gray1">
                                Please enter the details exactly as they appear
                                on your passport/travel document.
                              </p>
                            </div>
                            <div className="row mt-3">
                              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                                <p className="label-txt mb-2">Document Type</p>
                                <Select
                                  value={{
                                    label: formdata.Documenttype,
                                    value: formdata.Documenttype,
                                  }} onChange={(e) => {
                                    setFormdata({
                                      ...formdata,
                                      ...{ ["Documenttype"]: e.label },
                                    });
                                    SetValidateError({})

                                  }}
                                  options={document}
                                  defaultValue="theme1"
                                  styles={stylesgraybg}
                                  isSearchable={false}
                                  classNamePrefix="customselect"
                                // defaultInputValue="Theme 1"
                                // menuIsOpen
                                />
                                {ValidateError?.Documenttype && (
                                  <p className="text-left err_msg_txt">{ValidateError?.Documenttype}</p>
                                )}
                              </div>
                              {formdata.Documenttype == 'Passport'
                                && <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                                  <p className="label-txt mb-2">Issued by</p>
                                  {/* <Select
                                  options={[
                                    { value: "option1", label: "option1" },
                                    { value: "option2", label: "option2" },
                                    { value: "option3", label: "option3" },
                                  ]}
                                  styles={stylesgraybg}
                                /> */}

                                  <ReactFlagsSelect

                                    selected={select}
                                    onSelect={(e) =>{
                                      SetValidateError({});
                                      setFormdata({
                                        ...formdata,
                                        ...{ ["Issuedby"]: e }
                                      
                                      })
                                    }}
                                    countries={[
                                      "IN",
                                      "GB",
                                      "IE",
                                      "IT",
                                      "NL",
                                      "SE",
                                    ]}
                                    placeholder="India"
                                    className="country-name-input"
                                  />
                                  {ValidateError?.Issuedby && (
                                    <p className="text-left err_msg_txt">{ValidateError?.Issuedby}</p>
                                  )}


                                </div>
                              }
                              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                                <p className="label-txt mb-2">
                                  Document number
                                </p>
                                <input
                                  type="text"
                                  placeholder="Enter Document Number"
                                  className="cmn-inputFields"
                                  id="Documentnumber"
                                  value={formdata?.Documentnumber}
                                  onChange={onchange}
                                />
                                {ValidateError?.Documentnumber && (
                                  <p className="text-left err_msg_txt">{ValidateError?.Documentnumber}</p>
                                )}
                                     {ValidateError?.Documentnumberval && (
                                  <p className="text-left err_msg_txt">{ValidateError?.Documentnumberval}</p>
                                )}
                              </div>
                              {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                                <p className="label-txt mb-2">
                                  Document expiration
                                </p>
                                <div className="cmn-datePicker">
                                  {" "}
                                  <DatePicker
                                    selected={formdata?.Documentexpirationdate}
                                    showIcon
                                    icon={<img src={calendar} />}
                                    className=""
                                    onChange={(e) => {
                                      setFormdata({
                                        ...formdata,
                                        ...{ ["Documentexpirationdate"]: e }
                                      });
                                      SetValidateError({})

                                    }}
                                    value={formdata?.Documentexpirationdate?moment(formdata?.Documentexpirationdate).format("DD-MM-YYYY"):Date.now()}

                                  />
                                  {ValidateError?.Documentexpirationdate && (
                                    <p className="text-left err_msg_txt">{ValidateError?.Documentexpirationdate}</p>
                                  )}
                                </div>
                              </div> */}
                            </div>
                          </div>

                          <div className="save-btn mt-4 d-flex align-items-center justify-content-end">
                            {/* <Button className="cmn-linear-btn disabled" onClick={FormSubmit}> */}
                            <Button className={isEmpty(ValidateError) ? "cmn-linear-btn" : "cmn-linear-btn disabled"} onClick={FormSubmit}>
                              Save my changes
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : accPersonal == "connect-accounts" ? (
                      <div className="mt-3 tbs-item">
                        <div className="tabs-content">
                          <div className="yourBook">
                            <p className="mb-1 gray1 fw-600 f-14">
                              Connect a Social NetWork
                            </p>
                            <p className="mb-0 gray1 fw-500 f-12">
                              Click the 'Connect' buttons below to connect your
                              account to TRIPFI
                            </p>
                          </div>
                          <div className="socialMedias mt-4">
                            <div className={`socialItems ${Logintype=='google'?"active":""} text-center`}>
                              <div className="social-logo">
                                <img
                                  src={googleLogo}
                                  className="img-fluid cmn-socialLogo"
                                  alt="google"
                                />
                              </div>
                              <img
                                src={googleText}
                                className="img-fluid my-3"
                                alt="google"
                              />
                              
                              <div>

                          {  Logintype=='google' ?  
                           <Button className="connect-btn">
                                  Connected
                                </Button>
                                :
                                <Button className="connect-btn">
                                Disconnected
                              </Button>
                                }
                           
                              </div>
                            </div>
                            <div className={`socialItems ${Logintype=='facebook'?"active":""} text-center`}>
                              <div className="social-logo">
                                <img
                                  src={fbLogo}
                                  className="img-fluid cmn-socialLogo"
                                  alt="facebook"
                                />
                              </div>
                              <img
                                src={fbText}
                                className="img-fluid my-3"
                                alt="facebook"
                              />
                                  {  Logintype=='facebook' ?  
                           <Button className="connect-btn" >
                                  Connected
                                </Button>
                                :
                                <Button className="connect-btn">
                                Disconnected
                              </Button>
                                }
                            </div>
                            <div className={`socialItems ${Logintype=='binance'?"active":""} text-center`}>
                              <div className="social-logo">
                                <img
                                  src={binanceLogo}
                                  className="img-fluid cmn-socialLogo"
                                  alt="binance"
                                />
                              </div>
                              <img
                                src={binanceText}
                                className="img-fluid my-3"
                                alt="binance"
                              />
                                {  Logintype=='binance' ?  
                           <Button className="connect-btn" >
                                  Connected
                                </Button>
                                :
                                <Button className="connect-btn">
                                Disconnected
                              </Button>
                                }
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : accPersonal == "settings" ? (
                      <div className="mt-3 tbs-item">
                        <div className="tabs-content">
                          <div className="yourBook">
                            <p className="mb-1 gray1 fw-600 f-14">
                              PASSWORD REQUIRED
                            </p>
                            <p className="mb-0 gray1 fw-500 f-12">
                              To access all the features, you need to create a
                              password
                            </p>
                          </div>
                          <div className="accPassword mt-4">
                            <div className="row">
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                                <p className="label-txt mb-2">Enter Old Password</p>
                                <div className="cus-pwdInp">
                                  <input
                                    type={oldPwd == true ? "text" : "password"}
                                    placeholder="Enter Old Password"
                                    className="cmn-inputFields"
                                    id="OldPassword"
                                    value={passwordata?.OldPassword}
                                    onChange={Passwordonchange}
                                  />
                                
                                  <span className="showHideIcon">
                                    {oldPwd ? (
                                      <IoEyeOutline
                                        onClick={() => setoldPwd(!oldPwd)}
                                      />
                                    ) : (
                                      <IoEyeOffOutline
                                        onClick={() => setoldPwd(!oldPwd)}
                                      />
                                    )}
                                  </span>
                             
                                </div>
                                
                                {Err?.OldPassword && (
                                      <p className="text-left err_msg_txt">{Err?.OldPassword}</p>
                                    )}
                              </div>
                              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                                <p className="label-txt mb-2">Enter New Password</p>
                                <div className="cus-pwdInp">
                                  <input
                                    type={pwd == true ? "text" : "password"}
                                    placeholder="Enter New Password"
                                    className="cmn-inputFields"
                                    id="NewPassword"
                                    value={passwordata?.NewPassword}
                                    onChange={Passwordonchange}
                                  />
                                
                                  <span className="showHideIcon">
                                    {pwd ? (
                                      <IoEyeOutline
                                        onClick={() => setPwd(!pwd)}
                                      />
                                    ) : (
                                      <IoEyeOffOutline
                                        onClick={() => setPwd(!pwd)}
                                      />
                                    )}
                                  </span>
                             
                                </div>
                                
                                {Err?.NewPassword && (
                                      <p className="text-left err_msg_txt">{Err?.NewPassword}</p>
                                    )}
                              </div>
                              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                                <p className="label-txt mb-2">
                                  Confirm Password
                                </p>
                                <div className="cus-pwdInp">
                                  <input
                                    type={cnfmPwd == true ? "text" : "password"}
                                    placeholder="Enter Confirm Password"
                                    className="cmn-inputFields"
                                    id="ConfirmPassword"
                                    value={passwordata?.ConfirmPassword}
                                    onChange={Passwordonchange}
                                  />
                                
                                  <span className="showHideIcon">
                                    {cnfmPwd ? (
                                      <IoEyeOutline
                                        onClick={() => setCnfmPwd(!cnfmPwd)}
                                      />
                                    ) : (
                                      <IoEyeOffOutline
                                        onClick={() => setCnfmPwd(!cnfmPwd)}
                                      />
                                    )}
                                  </span>
                               
                                </div>
                                {Err?.ConfirmPassword && (
                                      <p className="text-left err_msg_txt">{Err?.ConfirmPassword}</p>
                                    )}
                              </div>
                            </div>
                            <div className="create-pwd-btn mt-3 gap-3 d-flex align-items-center justify-content-start">
                              <Button className="cmn-linear-btn" onClick={passwordsubmit}>
                                Change Password
                              </Button>
                              <Button className="cmn-linear-btn cancel-btn">
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="mt-3 tbs-item">
                        <div className="tabs-content">
                          <div className="accSecurity mb-4">
                            <div className="fst-sec">
                              <img
                                src={googleAuth}
                                className="img-fluid cmn-icon"
                                alt="security"
                              />
                              <div>
                                <p className="mb-1 gray1 fw-600 f-14">
                                  Google Authentication
                                </p>
                                <p className="mb-0 gray1 fw-500 f-12">
                                  Used for withdrawals and account security
                                </p>
                              </div>
                            </div>
                            <div className="snd-sec">
                              <div className="security-toogle">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="accSecurity">
                            <div className="fst-sec">
                              <img
                                src={phoneVerification}
                                className="img-fluid cmn-icon"
                                alt="security"
                              />
                              <div>
                                <p className="mb-1 gray1 fw-600 f-14">
                                  Phone number verification
                                </p>
                                <p className="mb-0 gray1 fw-500 f-12">OTP</p>
                              </div>
                            </div>
                            <div className="snd-sec">
                              <div className="security-toogle">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : accTabs == "Bookings" ? (
              <Bookings stripe={stripe} Status={StatusTabs} statusTabs={setstatusTabs} />
            ) : accTabs == "WishList" ? (
              <Wishlist />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
    {
      oldEmailOtpModal && 
      <OldEmailOtpModal
show={oldEmailOtpModal}
handleClose={handleOldEmailEmailClose}
useremail={UserDetails?.Email}
Newemail={setnewEmail}
      />
    }
    {
      newEmailOtpModal&&
      <NewEmailOtpModal
show={newEmailOtpModal}
handleClose={handleNewEmailEmailClose}
useremail={UserDetails?.Email}
NewEmailotp={newEmailOtp}
updateemail={updateEmail}
      />
    }
        {
      newEmail && 
      <Changeemail
show={newEmail}
handleClose={handleNewEmailClose}
newEmailOtpModal={setNewEmailOtpModal}
setnewEmailOtp={setnewEmailOtp}
setupdateEmail={setupdateEmail}
      />
    }
    </>
  );
}

export default Account;
