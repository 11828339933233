import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";

import PhotoModal from "../modals/PhotoModal";


function ActivitiesRoomsList() {
  const Rooms = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
  ];


  const [photoShow, setPhotoShow] = useState(false);
  const handlePhotoClose = () => setPhotoShow(false);
  const handlePhotoShow = () => setPhotoShow(true);


  const [truncated, setTruncated] = useState(true);
  return (
    <div className="rooms-card">
      <h5 className="country-properties">680 Activities match your search</h5>
      {Rooms.map((item, index) => {
        return (
          <>
          <div className="custom-rooms-card mb-3 mb-lg-4">
            
            <div className="row">
              <div className="col-xl-4 fst-sec">
                <div className="row room-images left-sec">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <img
                      src={require("../assets/images/rooms-card/big-img.png")}
                      className="big-img img-fluid"
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="d-flex justify-content-between align-items-center mt-1 sml-img-sec">
                      <div className="pe-0 sml-img">
                        <img
                          src={require("../assets/images/rooms-card/small-img1.png")}
                          className="small-img img-fluid"
                        />
                      </div>
                      <div className="pe-0 sml-img">
                        <img
                          src={require("../assets/images/rooms-card/small-img2.png")}
                          className="small-img img-fluid"
                        />
                      </div>
                      <div className="pe-0 sml-img">
                        <img
                          src={require("../assets/images/rooms-card/small-img3.png")}
                          className="small-img img-fluid"
                        />
                      </div>
                      <Link className="custom-view-all sml-img" onClick={handlePhotoShow}>
                        <span className="txt">View All</span>
                        <img
                          src={require("../assets/images/rooms-card/small-img3.png")}
                          className="small-img img-fluid"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 snd-sec">
                <div className="room-details">
                  <div className="sec-fst">
                    <div className="name mb-2">
                      <p className=" mb-0 f-15 fw-800">3-Hour Luxury Yacht Cruise from Dubai Marina</p>
                   
                    </div>
                    <div className="address mb-2">
                      <p className=" mb-0 f-13 fw-700 addr1">Dubai, United Arab Emirates</p>

                     
                    </div>
                    <span className={truncated?"mb-0 f-13 fw-500 mb-2 truncate_row":"mb-0 f-13 fw-500 mb-2"}>
                    <span className="bold_text_desc">Highlights:</span> 
                    <span>Journey on Dubai’s most exotic Luxury Yacht tour, which can fulfill your dreams completely on a beautiful and majestic yacht.... 
                    </span>
                   
                    </span>
                    <span>
                      <a href="javascript:void(0)" className="show_link_purple" onClick={()=>{setTruncated(!truncated)}}>{truncated?"Show more":"Show Less"}</a>
                    </span>

                    <div className="d-flex align-items-center gap-1 mb-2 mt-2">
                      <img
                        src={require("../assets/images/rooms-card/dollar.png")}
                        className="img-fluid dollar-logo"
                      />
                      <p className="mb-0 f-13 fw-500">
                        {/* Approx. US$1.99 giveback in AVA */}
                      </p>
                    </div>
                  </div>
                  <div className="sec-snd">
                    <p className="text-decoration-none">
                      <p className="mb-0 fw-600 f-13 view-all-amen themeclr1">
                       Free Cancellation
                      </p>
                    </p>
                    <FaHeart className="heart-fill" />
                  </div>
                </div>
              </div>
              <div className="col-xl-4 third-sec">
                <div className="amt-rating">
                 
                  <div className="amount">
                    <p className="f-12 fw-500 old-off text-decoration-line-through mb-0">
                      ₹ 9,542
                    </p>
                    <h5 className="fw-800 new-off mb-0">₹ 6,246</h5>
                    <p className="mb-0 f-13 fw-600 per-room">
                      Per activity/night with taxes
                    </p>
                    <p className="mb-0 f-11 fw-500 total">
                      Total ₹ 5,080 + Taxes
                    </p>
                  </div>
                <Link to="/activityDetails" className="cmn-btn btn btn-primary w-100 d-block">See Activities</Link>

                  <div className="emi">
                    <p className="mb-0 f-11 fw-700">
                      No Cost EMI starts at ₹6,90,133
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {photoShow &&
 <PhotoModal
 photoShow={photoShow}
 handlePhotoClose={handlePhotoClose}
/>
}
          </>
        );
      })}
    </div>
  );
}

export default ActivitiesRoomsList;
