import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { LoadToast, updateToast, validation } from "../lib/common";
import * as User from "../Axios/user.axios";


const OtpModal = ({ show, handleClose,useremail,handleCancelShow }) => {
  const initial = {
    OTP: "",
    Email:useremail,

  };
  const [formdata, setFormdata] = useState(initial);
  const [err, setErr] = useState({});
  const [loader, setloader] = useState(false);
  
  const submit=async()=>{

    setloader(true)
    const id = LoadToast();
    let err = await validation(Object.keys(initial), formdata);
    
    if (Object.keys(err)?.length > 0) {
      updateToast (id, "fix");
      setErr(err);
      setloader(false)

      return;
    }
    setErr({});

    var { success, msg } = await User.VerifyOTP(formdata);
    setTimeout(() => {
        updateToast(
            id,
            msg,
            success
          );
          setloader(false)

    }, 1000);


if(success=='success'){
    setTimeout(() => {
        handleClose()
        handleCancelShow()
    }, 2000);

}
            
        }
  
  
        const Resendotp = async () => {
          const id = LoadToast();

          
          var { success, msg } = await User.SendOtp(formdata);
          
              updateToast(
                id,
                msg,
                success
              );
        };
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="md"
        className="common_modal otp_modal"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title></Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="p-3">
            <h4 className="modal_title text-center">Enter OTP</h4>
            <div className="otp_input mt-4">
              <OtpInput
                id="OTP"
                value={formdata?.OTP}
                numInputs={6}
                onChange={(e) => {setFormdata({ ...formdata, ...{ ['OTP']: e } });setloader(false)}}                
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>{" "}
            {err?.OTP && (
                      <p className="text-left err_msg_txt mb-0 text-center">{err?.OTP}</p>
                    )}
       {  loader? 
       <div className="mt-4 d-flex align-items-center justify-content-center">
       <Button className="cmn-btn f-14 fw-600 px-4 disabled">Processing...</Button>
     </div>
     :
       <div className="mt-4 d-flex align-items-center justify-content-center">
              <Button className="cmn-btn f-14 fw-600 px-4" onClick={submit}>Confirm</Button>
            </div>}
          </div>

          <div className="sgn_bot_sec">
            <p className="mb-0 text-center">
              Can't get OTP? <span className="sgn_term_btn resend_txt" onClick={() => Resendotp()} >RESEND</span>
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OtpModal;
