import React from "react";
import cardImg from "../assets/images/account/card/card-bg1.png";
import calendar from "../assets/images/account/card/calendar.png";
import map from "../assets/images/account/card/map.png";
import user from "../assets/images/account/card/people.png";
import { FaStar } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

function RoomCard({Hoteldata}) {


  const navigate = useNavigate();

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <FaStar
          key={i}
          className={rating >= i ? "star-filled" : "star-without-fill"}
        />
      );
    }
    return stars;

  }
  const Navigate=()=>{
    window.open(`/roomDetails?hotelid=${Hoteldata?.WholeHotelid}`, '_blank');

  }

  return (
    <div>
      <div className="wishlistCard">
        <div className="img-head position-relative">
          <img src={Hoteldata?.Images?.url??cardImg} className="img-fluid card-img" />
          {/* <span className="wishRmv">
            <IoCloseSharp />
          </span> */}
        </div>
        <div className="cardBody">
          <p className="f-15 mb-1 fw-600 purple">{Hoteldata.HotelName}</p>
          <div className="ratings">
            {renderStars(Hoteldata.Ratings)}
           

          </div>
          <hr className="cus-hr" />
          <div className="details">
            <div className="date-guest">
              <div className="date">
                <img src={calendar} className="img-fluid" />
                <span className="date f-12 fw-600 purple">
                  {/* {props.data.date} */}
                </span>
              </div>
              <div className="guest">
                <img src={user} className="img-fluid" />
                <span className="date f-12 fw-600 purple">
                  {/* {props.data.guest} guest */}
                </span>
              </div>
            </div>
            <div className="location mt-2">
              <img src={map} className="img-fluid" />
              <span className="location-txt f-13 fw-600 purple">
                {Hoteldata?.Address?.adr} {Hoteldata?.Address?.ctn}
              </span>
            </div>
          </div>
          <div className="book-now-btn mt-3">
            <Button className="" onClick={()=>Navigate()}>Book now</Button>
          </div>

      
        </div>
      </div>
    </div>
  );
}

export default RoomCard;
