import React from "react";
import Button from "react-bootstrap/esm/Button";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
function Offers() {
  const options = {
    loop: true,
    // center: true,
    items: 2,
    margin: 20,
    autoplay: true,
    dots: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    },
  };

  const Media = [
    {
      logo: require("../assets/images/landing/media/icon1.png"),
    },
    {
      logo: require("../assets/images/landing/media/icon2.png"),
    },
    {
      logo: require("../assets/images/landing/media/icon3.png"),
    },
    {
      logo: require("../assets/images/landing/media/icon4.png"),
    },
  ];
  return (
    <div className="container custom-container">
      <div className="offers">
        <div className="header-sec text-center">
          <h3 className="mb-0 fw-700 header-clr">OFFERS</h3>
          <p className="mb-0 header-sub-clr">
            Promotions, deals and special offers for you
          </p>
        </div>

        <div className="mt-4">
          <OwlCarousel
            id="customer-testimonoals"
            className="owl-carousel owl-theme offers-carousal"
            {...options}
          >
            <div className="offers-custom-card">
              <div>
                <p className="f-15 mb-1 fw-700">
                  Fly away to your dream holiday
                </p>
                <p className="mb-0 f-12 fw-500">
                  Get inspired, compare and book flights with more flexibility
                </p>
                <Button className="search-flight cmn-btn mt-3">
                  Search Flight
                </Button>
              </div>
              <div className="flight-sec-logo">
                <img
                  src={require("../assets/images/landing/offers-flight.png")}
                  className="img-fluid"
                />
              </div>
            </div>

            <div className="rooms-det">
              <div>
                <h5 className="mb-0 fw-700">New Year, New Stay</h5>
                <p className="mb-0 f-15 fw-500">
                  Save 15% or more when you book and stay before
                </p>
                <p className="mb-0 f-15 fw-500">1 April 2024</p>
              </div>
            </div>

            <div className="offers-custom-card">
              <div>
                <p className="f-15 mb-1 fw-700">
                  Fly away to your dream holiday
                </p>
                <p className="mb-0 f-12 fw-500">
                  Get inspired, compare and book flights with more flexibility
                </p>
                <Button className="search-flight cmn-btn mt-3">
                  Search Flight
                </Button>
              </div>
              <div className="flight-sec-logo">
                <img
                  src={require("../assets/images/landing/offers-flight.png")}
                  className="img-fluid"
                />
              </div>
            </div>

            <div className="rooms-det">
              <div>
                <h5 className="mb-0 fw-700">New Year, New Stay</h5>
                <p className="mb-0 f-15">
                  Save 15% or more when you book and stay before
                </p>
                <p className="mb-0 f-15">1 April 2024</p>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
      <div className="land-rating">
        <div className="lft-sec">
          <h3 className="mb-0 fw-800 mb-2">Pie Homes by dPie</h3>
          <p className="f-13 fw-500">
            Escape to the epitome of luxury, packed with signature amenities and
            services
          </p>
        </div>
        <div className="ryt-sec">
          <div className="d-flex align-items-center">
            <span className="f-14 fw-600">4.8 out of 5</span>
            <img
              src={require("../assets/images/landing/green-star.png")}
              className="img-fluid star mx-1"
            />
            <span className="fw-600">Trustpilot</span>
          </div>
          <div>
            <span className="f-14 fw-600">4.6 out of 5</span>
            <span>
              <img
                src={require("../assets/images/landing/Google.png")}
                className="img-fluid google ms-1"
              />
            </span>
          </div>
        </div>
      </div>
      <div className="land-media">
        <div className="header-sec text-center">
          <h3 className="mb-0 fw-700 header-clr">IN THE MEDIA</h3>
        </div>
        <div className="row mt-5">
          {Media.map((item, index) => {
            return (
              <div className="mb-4 col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 d-flex align-items-center justify-content-center">
                <div className="logo">
                  <img src={item.logo} className="img-fluid" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Offers;
