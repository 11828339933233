import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { FaStar } from "react-icons/fa";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import img1 from "../assets/images/pie_1.png";
import { LoadToast, isEmpty, updateToast, validation } from "../lib/common";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Hotels from "../Axios/hotel.axios";
import * as Activity from "../Axios/activity.axios";



const AddReviewsModal = ({ show, handleClose,Hotelid,Activityid,Type }) => {
  const [reviewerr, setreviewErr] = useState('');
  const { UserDetails } = useSelector((state) => state.LoginReducer.User);
  // const Hotelsdata = useSelector((state) => state.HotelReducer);

  const reviewinitial = {
    Id: !isEmpty(Hotelid)?Hotelid?.split('-')[1]:'1',
    Ratings: "",
    Reviews: "",
    Userid: UserDetails?._id??"",
    Type: "",
    Images:""

  };

  const [Reviewdata, setReviewdata] = useState(reviewinitial);
  const [rating, setRating] = useState({
    Location:0,
    Room:0,
    Food:0,
    Amenities:0,
    Hospitality:0,
    Cleanliness:0
  });


  const Reviewonchange = (id,value) => {
    setreviewErr({})
    if (id == 'Images') {
      Object.values(value).map((item) => {
        if (!item?.type?.includes("image")) {
          return toast.error("Only images are allowed");
        }
        else{
          console.log("itemitemitem",item);
          setReviewdata({ ...Reviewdata, ...{ ["Images"]: Object.values(value) } });

        }
      })

    }
    else{
      setReviewdata({ ...Reviewdata, ...{ [id]: value } });

    }
  };
  const handleClick = (id,selectedRating) => {
    setRating({...rating,...{[id]:selectedRating}});

    setReviewdata({ ...Reviewdata, ...{ ['Ratings']: {...rating,...{[id]:selectedRating}} } });

  
  };

  const renderStars = (id) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {

      stars.push(
        <FaStar
          key={i}
          className={rating[id] >= i ? "star active" : "star inactive"}
          onClick={() => handleClick(id,i)}
        />
      );
    }
    return stars;

  }
  const Reviewsubmit =async()=>{

if(!isEmpty(UserDetails)){
  const id = LoadToast();
  let err = await validation(Object.keys(reviewinitial), Reviewdata);

    if (Object.keys(err)?.length > 0) {
      updateToast(id, "fix");
      setreviewErr(err);
      return;
    }
    console.log("ReviewdataReviewdata",Reviewdata);
    if(Type =='Hotels'){
      var { status,msg,data } = await Hotels.AddUserRatingsandreviews(Reviewdata);

    }
    else if(Type =='Activities') {
      var { status,msg,data } = await Activity.AddUserRatingsandreviews(Reviewdata);

    }
    updateToast(
      id,
      msg,
      status
    );
    if(status=='success'){
      setTimeout(() => {
        handleClose()
        window.location.reload()

      }, 2000);
    }

}
else{
  toast.error('Please Login To Continue...!')

}
  
  }

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="md"
        className="common_modal addReviews_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Review</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-4">
          <div className="addReview_details">
            <div className="row">
              <div className="col-xl-12 mb-3">
                <div className="addImg">
                  <label className="label mb-2 fw-500 f-15">Room Type</label>
                  <Dropdown className="roomType_drop">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                     { !isEmpty(Reviewdata.Type)?Reviewdata.Type:'Select Type'}
                    </Dropdown.Toggle>

                    <Dropdown.Menu  > 
                      <Dropdown.Item href=""  onClick={()=>Reviewonchange('Type',"Couple")}>Couple</Dropdown.Item>
                      <Dropdown.Item href="" onClick={()=>Reviewonchange('Type',"Family")}>Family</Dropdown.Item>
                      <Dropdown.Item href="" onClick={()=>Reviewonchange('Type',"Business")}>Business</Dropdown.Item>
                      <Dropdown.Item href="" onClick={()=>Reviewonchange('Type',"Group")}>Group</Dropdown.Item>
                      <Dropdown.Item href="" onClick={()=>Reviewonchange('Type',"Solo")}>Solo</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {reviewerr?.Type && (
                      <p className="text-left err_msg_txt">{reviewerr?.Type}</p>
                    )}
              </div>
              <div className="col-xl-12 mb-3">
                <div className="addImg">
                  <label className="label mb-2 fw-500 f-15">Upload Images</label>
                  <div className="custom_uploadBtn">
                    <input type="file" onChange={(e)=>Reviewonchange('Images',e.target.files)} multiple></input>
                  </div>
                </div>
                {reviewerr?.Images && (
                      <p className="text-left err_msg_txt">{reviewerr?.Images}</p>
                    )}
                <div className="preview_images mt-2">
                  {console.log("vvvvvvvvv",Reviewdata?.Images)}
               { Reviewdata?.Images?.length>0
               &&  <p className="previewTxt mb-2">Preview</p>}
                  <div className="img_list">
                  {
                    Reviewdata?.Images?.length>0 && 
              Reviewdata?.Images?.map((val) => {
                      return(
                        <img
                        className="img-fluid"
                        src={ val
                            ? typeof val == "object"
                              &&window.URL.createObjectURL(val)
                              // : `${config.ImG}/cmsimages/${val}`
                            : ""
                        }
                        width={300}
                      />

                      )
                      
                    })
                  
                  }
                  </div>


                  {/* <div className="img_list">
                    <img src={img1} className="img-fluid" />
                    <img src={img1} className="img-fluid" />
                    <img src={img1} className="img-fluid" />
                  </div> */}
                </div>
              </div>

              <div className="col-xl-12 mb-3">
                <label className="label mb-2 fw-500 f-15">Ratings</label>
                <div className="all_ratingsList">
                  <div className="rating">
                    <label className="ratinglabel mb-2 fw-500 f-13">
                      Location
                    </label>
                    <div className="stars_list">

{                    renderStars('Location')
}             
                    </div>
                  </div>

                  <div className="rating">
                    <label className="ratinglabel mb-2 fw-500 f-13">Room</label>
                    <div className="stars_list">
                    {                    renderStars('Room')
}
                    </div>
                  </div>

                  <div className="rating">
                    <label className="ratinglabel mb-2 fw-500 f-13">Food</label>
                    <div className="stars_list">
                    {                    renderStars("Food")
}
                    </div>
                  </div>

                  <div className="rating">
                    <label className="ratinglabel mb-2 fw-500 f-13">
                      Amenities
                    </label>
                    <div className="stars_list">
                    {                    renderStars('Amenities')
}
                    </div>
                  </div>

                  <div className="rating">
                    <label className="ratinglabel mb-2 fw-500 f-13">
                      Hospitality
                    </label>
                    <div className="stars_list">
                    {                    renderStars('Hospitality')
}
                    </div>
                  </div>

                  <div className="rating">
                    <label className="ratinglabel mb-2 fw-500 f-13">
                      Cleanliness
                    </label>
                    <div className="stars_list">
                    {                    renderStars("Cleanliness")
}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12">
                <div className="commentTxt">
                  <label className="label mb-2 fw-500 f-15">Reviews</label>
                  <Form.Control as="textarea" rows={3} onChange={(e)=>Reviewonchange('Reviews',e.target.value)} />
                </div>
                {reviewerr?.Reviews && (
                      <p className="text-left err_msg_txt">{reviewerr?.Reviews}</p>
                    )}
              </div>
              <div className="col-xl-12 mt-4">
                <div className="submit d-flex align-items-center justify-content-end">
                  <Button className="cmn-btn" onClick={()=>Reviewsubmit()}>Submit</Button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddReviewsModal;
