import React, { useState, useEffect } from "react";

import Header from "../components/Header";

import Form from "react-bootstrap/Form";

import { Link } from "react-router-dom";
import Banner from "../components/Banner";
import Offers from "../components/Offers";
import Regions from "../components/Regions";
import Blogs from "../components/Blog";
import CryptoCurrency from "../components/CryptoCurrency";
import Faqs from "../components/Faqs";
import Footer from "../components/Footer";

export default function Landing() {
  const [active, setActive] = useState(1);

  return (
    <>
      <Header />
      <Banner />
      <Offers />
      <Regions />
      <Blogs />
      <Faqs />
      <CryptoCurrency />
      <Footer />
    </>
  );
}
