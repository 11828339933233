import React, { useEffect } from "react";
import CommonCard from "./CommonCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as CMS from '../Axios/cms.axios'
import { useState } from "react";
import BlogCard from "./Blogcard";

function Blogs() {
  const [blogs, setBlogs] = useState([]);


  useEffect(()=>{
    Blogs()
  },[])

  const Cities = [
    {
      id: 1,
      countryImg: require("../assets/images/landing/blog/blog-bg1.png"),
      logo: require("../assets/images/landing/regions/hongkong-logo.png"),
      stays: "3800",
      name: "Quangle",
      footerbg: "",
      blog: true,
    },
    {
      id: 2,
      countryImg: require("../assets/images/landing/blog/blog-bg3.png"),
      logo: require("../assets/images/landing/regions/india-logo.png"),
      stays: "4800",
      name: "Snow Train",
      footerbg: "black",
      blog: true,
    },
    {
      id: 3,
      countryImg: require("../assets/images/landing/blog/blog-bg1.png"),
      logo: require("../assets/images/landing/regions/china-logo.png"),
      stays: "4000",
      name: "Julius Sliver",
      footerbg: "black",
      blog: true,
    },
    {
      id: 4,
      countryImg: require("../assets/images/landing/blog/blog-bg3.png"),
      logo: require("../assets/images/landing/regions/hongkong-logo.png"),
      stays: "3800",
      name: "Kookay",
      footerbg: "black",
      blog: true,
    },
  ];

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    margin: 10,
    // arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  
  const Blogs=async()=>{
let {status,data}=await CMS.Get_Blogs({Type:'get'})
setBlogs(data)
  }

  {console.log('blogs',blogs)}
  return (
    <div className="container custom-container">
      <div className="explore-countries blog">
        <div className="header-sec text-center">
          <img
            src={require("../assets/images/landing/blog/blog.png")}
            className="img-fluid"
          />
          <h3 className="mb-0 fw-700 header-clr mt-3">
            WHERE TO GO & WHAT TO SEE
          </h3>
          <p className="mb-0 header-sub-clr f-14 mt-2">
            Get inspired with travel guides and destination ideas from our blog
          </p>
        </div>

        <Slider {...settings} className="country-slider mt-4">
          
          {blogs?.map((item, index) => {
            return (
              <BlogCard
            data={item}
            type={'blogs'}
            footerbg={'black'}
              />
            );
          })}
        </Slider>
      </div>
    </div>
  );
}

export default Blogs;
