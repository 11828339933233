import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";




const BookingModal = ({ show, handleClose, Hoteldata, CancelShow, StrpieShow, HoldBookingConfirm, bookingloader }) => {
  const [loader, setLoader] = useState(false);

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="md"
        className="common_modal reservation_modal"
      >
        <Modal.Header closeButton className="justify-content-center">
          <Modal.Title>Bookings Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-4">
          <div className="reservation_modal_details">
            <p className="heading_txt mb-2 fw-700 text-center">Bookings</p>
            <p className="description mb-0 text-center">
              If you want confirm your booking?
            </p>
            <div className="d-flex align-items-center justify-content-center">
              {bookingloader ?
                <Button className="cmn-btn mt-2" disabled>Processing...</Button>
                :
                <Button className="cmn-btn mt-2" onClick={() => HoldBookingConfirm()} >Submit</Button>

              }
            </div>
          </div>
          <div className="reservation_modal_details mt-3">
            <p className="heading_txt mb-1 fw-700 text-center">Cancel</p>
            <p className="description mb-0 text-center">
            Are you sure,you want to cancel this booking at this moment 
            </p>
            <div className="d-flex align-items-center justify-content-center">
              {loader ?
                <Button className="cmn-btn mt-2" disabled>Cancel</Button>
                :
                <Button className="cmn-btn mt-2" onClick={() => CancelShow(Hoteldata,'hold')} >Cancel</Button>

              }
            </div>
          </div>




        </Modal.Body>
      </Modal>
      {/* {
          CancelShow && Bookingid &&
          <CancelModal
          show={CancelShow}
          handleClose={handleCancelClose}
            bookingid={Bookingid}

          />
        } */}
    </div>

  );
};

export default BookingModal;
