import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";

import { FaCheck, FaWeight } from "react-icons/fa";

import meal from "../assets/images/book/meals.png";
import drink from "../assets/images/book/drink.png";
import room1 from "../assets/images/roomdetail/room_Img1.png";

const ParticularImagePreview = ({ show, handleClose,image }) => {
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="md"
        className="common_modal particular_ImagePreview"
      >
        <Modal.Header
          closeButton
          style={{ fontSize: "18px", fontWeight: "800" }}
          className="p-0"
        >
          {/* Preview */}
        </Modal.Header>
        <Modal.Body className="">
          <img src={image??room1} className="img-fluid photo" />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ParticularImagePreview;
