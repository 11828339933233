import isEmpty from "is-empty";
import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";



const RefundPolicyModal = ({ show, handleClose,setWalletaddressModalShow}) => {
  const [loader, setLoader] = useState(false);
  const [check, setcheck] = useState(false);
  const [Err, setErr] = useState('');

const submit =()=>{

  setWalletaddressModalShow(check);
  if(check){
    handleClose()
  }
  else{
    setErr('Please accept the conditions')
  }

}

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="md"
        className="common_modal reservation_modal"
      >
        <Modal.Header closeButton className="justify-content-center">
          <Modal.Title>Refund Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-4">
          <div className="reservation_modal_details">
            {/* <p className="heading_txt mb-1">Cancel</p> */}
            <p className="description mb-2 text-center fw-500 f-15" style={{fontSize:'15px'}}>
If you cancel this booking, your booking amount will be refunded to the USDT token to your crypto wallet</p>
<div class="form-check">
  <input class="form-check-input" type="checkbox" value={check} id="defaultCheck1" onClick={()=>{setcheck(!check);setErr('')}}/>
  <label class="form-check-label" for="defaultCheck1">
    Accept conditions
  </label>
</div>
{!isEmpty(Err) && (
                      <p className="text-left err_msg_txt mb-2">{"Accept the conditions"}</p>
                    )}
            <div className="d-flex align-items-center justify-content-center">

              {loader ?
                <Button className="cmn-btn mt-2" disabled>Processing...</Button>
                :
                <Button className="cmn-btn mt-2" onClick={() => submit()} >submit</Button>

              }
            </div>
          </div>




        </Modal.Body>
      </Modal>

    </div>

  );
};

export default RefundPolicyModal;
