import axios from "axios"
import { AppenData, axiosFunc } from "../lib/common"
import config from "../lib/config"

export const Register = async (data) => {
    try {
        let formdata = AppenData(data)


        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/user/register`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
}


export const Login = async (data) => {
    try {
        let formdata = AppenData(data)
        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/user/login`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp

    } catch (error) {
        return false

    }
      
}
export const SendOtp = async (data) => {
    try {
        let formdata = AppenData(data)


        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/user/sendotp`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
   

      
}
export const VerifyOTP = async (data) => {
    try {
        let formdata = AppenData(data)


        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/user/verifyotp`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
   

      
}
export const getGoogleAccountDetails = async (token) => {

    try {
        var senddata = {
            'method': 'get',
            'url': `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${token}`,
        }
        let Resp = await axios(senddata)
        return Resp.data
    } catch (err) {
        console.log("error", err)
    }

}

export const ProfileUpdate = async (data) => {
    try {
        let formdata = AppenData(data)


        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/user/profileupdate`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
   

      
}

export const ChangePassword = async (data) => {
    try {
        let formdata = AppenData(data)


        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/user/changepassword`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
   

      
}

export const Statusupdate = async (data) => {
    try {
        let formdata = AppenData(data)
        

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/user/statusupdate`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp

        
    } catch (error) {
        return false

    }
   

      
}
export const ChangeEmail = async (data) => {
    try {
        let formdata = AppenData(data)
        

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/user/changeemail`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp

        
    } catch (error) {
        return false

    }
   

      
}

export const Subscribers = async (data) => {
    try {
        let formdata = AppenData(data)


        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/user/subscribers`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
   

      
}