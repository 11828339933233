import config from '../lib/config'
import Web3, { Contract } from 'web3';

export const Gasfee =async(toaddress,amount)=>{
  const web3 = await UseWeb3();
  const ConnectContract = await Tokeninstatnce(config?.TokenAddress,config?.Adminprivatekey)
  let sendamount = await etoFixed(amount * 1e18)

  try{
    var contractobj = await
    ConnectContract
        .methods
        .transfer( 
          toaddress,
          sendamount.toString()
          )
    
    var gasprice = await web3.eth.getGasPrice();
    console.log("gaspricegasprice",gasprice);
    var gas_estimate = await contractobj.estimateGas({ from: config?.Adminaddress })
    console.log("ffffffff",gas_estimate,contractobj);
    var contract_Method_Hash = await
    ConnectContract
        .methods
        .transfer( 
          toaddress,
          sendamount.toString()
          )
        .send({
            from: config?.Adminaddress,
            gas: web3.utils.toHex(gas_estimate),
            gasPrice: web3.utils.toHex(gasprice),
        }).on('transactionHash', (transactionHash) => {
            return transactionHash
        })
        console.log("contract_Method_Hash",contract_Method_Hash);
  }
  catch(err){
console.log("GasfeeGasfee",err);
  }

}


export const Cryptorepay=async({toaddress,amount})=>{
try{
  let cryptobalance = await cryptoBlanace(config?.Adminprivatekey,config?.Adminaddress)
  let parseAmount = parseFloat(amount);
  let Amount = await convertamount(parseAmount);

let fees = await calculate_gas()
// let prdiction= await Gasfee(toaddress,Amount)
if((Number(cryptobalance) < Number(fees?.fee / 1e18))){
    return {status:false,msg:"Balance is Too low ...!"}
  }
  // else if((parseFloat(cryptobalance)-parseFloat(amount)?.toFixed(4) < parseFloat(Gas))){
  //   return {status:false,msg:"Insufficient funds for gas!"}
  // }
  let tokenbalance = await tokenBlanace(config?.TokenAddress,config?.Adminprivatekey,18)
  let decimals = 18;
  console.log("feesfees",fees);

  if (tokenbalance > 0) {
    const web3 = await UseWeb3();
    const Useraccount = await UseUserAccount(config?.Adminprivatekey);
    let txCount = await web3.eth.getTransactionCount(Useraccount);
    const txObject = {
      nonce: web3.utils.toHex(txCount),
      gasLimit: fees?.HxgasLimit,
      gasPrice: fees?.Hxgasprice,
      to: config?.TokenAddress
    };
    const erc20Contract = await Tokeninstatnce(config?.TokenAddress,config?.Adminprivatekey)
    let sendamount = await etoFixed(Amount * 1e18)
    let encoded = await erc20Contract.methods.transfer(
      toaddress,
      sendamount.toString()
    ).encodeABI()
    txObject.data = encoded

    txObject.gasLimit = await web3.eth.estimateGas({
      "data": encoded,
      "from": Useraccount,
      "to": config?.TokenAddress
    })

    
    const signedTx = await web3.eth.accounts.signTransaction(txObject, config?.Adminprivatekey);
const Rawtranscation =await web3.eth.sendSignedTransaction(signedTx?.rawTransaction);
return Rawtranscation


}

}
catch(err){

console.log("Cryptorepayerrerr",err);
return false

}



}
export const cryptoBlanace = async (privateKey,address) => {
    try {
      const web3 = await UsePrivateWallet(privateKey);

      let data = await web3.eth.getBalance(address)
      let balanceNumber = Number(data) / 1e18
      return balanceNumber
      
    }
    catch (e) {
      console.error("cryptoBlanace_error", e);
      return 0
    }
  };
  export const UsePrivateWallet = async (key) => {
    try {
    //   let PrivateKey = (key.includes('0x')) ? key : await DecryptPrivateKey(key)
  
      const web3 = await UseWeb3();
      await web3.eth.accounts.wallet.add(key);
  
      return web3;
    }
    catch (err) {
      console.log("UsePrivateWalleterr", err);
    }
  };
  export const UseUserAccount = async (privateKey) => {
    try {
      const web3 = await UseWeb3();
  
      const accountInfo = await web3.eth.accounts.privateKeyToAccount(privateKey);
      return accountInfo.address
    }
    catch (err) {
      console.log("UseUserAccount", err);
    }
  }
  export const UseWeb3 = async () => {

    try {
      const httpProvider = new Web3.providers.HttpProvider(config?.BNB_URL);
  
      const web3 = new Web3(httpProvider);
      return web3;
    } catch (err) {
      console.log("UseWeb3", err)
  
    }
  };
  export const calculate_gas = async () => {
    const web3 = await UseWeb3();
    let GasPrice = await web3.eth.getGasPrice();
    let getGasPrice = web3.utils.toHex(parseFloat(GasPrice));
    let gaslimit = web3.utils.toHex(21000);
    let fee = gaslimit * getGasPrice;
  
    return { getGasPrice: GasPrice, gaslimit: gaslimit, fee, HxgasLimit: gaslimit, Hxgasprice: getGasPrice };
  };
  export const tokenBlanace = async (Contractaddress,key, decimal) => {
    try {
      const erc20Contract = await Tokeninstatnce(Contractaddress,key);
      const Useraccount = await UseUserAccount(key);
      let balance = await erc20Contract.methods.balanceOf(Useraccount).call()
      let correctbalance = parseInt(balance) / (10 ** (decimal ? parseInt(decimal) : 18))
      return correctbalance
      

    }
    catch (e) {
      console.error("tokenbalancetokenBlanace_err", e);
      return 0
    }
  };
  export const Tokeninstatnce = async (Token, key) => {
    try {
        const web3 = await UsePrivateWallet(key)

      const ABI =config?.ABIARRAY_BEP20
      const contract = new web3.eth.Contract(ABI, Token);
      return contract;
      

    
    } catch (e) {
      console.error("private contract_err", e);
      return false;
    }
  };
  
  export function etoFixed(x) {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split('e-')[1]);
      if (e) {
          x *= Math.pow(10,e-1);
          x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
      }
    } else {
      var e = parseInt(x.toString().split('+')[1]);
      if (e > 20) {
          e -= 20;
          x /= Math.pow(10,e);
          x += (new Array(e+1)).join('0');
      }
    }
    return x;
  }
  export const convertamount = (n) => {
    try {
      // var sign = +n < 0 ? "-" : "",
      var toStr = n.toString();
      if (!/e/i.test(toStr)) {
        return n;
      }
      var [lead, decimal, pow] = n
        .toString()
        .replace(/^-/, "")
        .replace(/^([0-9]+)(e.*)/, "$1.$2")
        .split(/e|\./);
      return +pow < 0 ?
        //  sign +
        "0." +
        "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
        lead +
        decimal
        :
        // sign +
        lead +
        (+pow >= decimal.length
          ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
          : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
    } catch (err) {
      console.error("convertamount_err", err)
      return 0;
    }
  }