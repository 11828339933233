import React from 'react'

function CustomLoader() {
  return (
    <div className='customLoader'>
        <span class="loader"></span>
    </div>
  )
}

export default CustomLoader