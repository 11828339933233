import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import meal from "../assets/images/book/meals.png"
import drink from "../assets/images/book/drink.png"
import * as Hotels from "../Axios/hotel.axios";





const InclusionModal = ({ inclusionShow, handleInclusionClose,Data }) => {

  const CancellationPolicyfunction = async () => {
    if (Data) {
      let Resp = await Hotels.CancellationPolicy({
        id: Data.hotelid,
        optionId: Data.optionid
        ,
      });
    }
  };

  useEffect(()=>{
CancellationPolicyfunction()
  })


  return (
    <div>
      
      <Modal
        show={inclusionShow}
        onHide={handleInclusionClose}
        centered
        size="md"
        className="common_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Standard Double</Modal.Title>
      </Modal.Header>
        <Modal.Body className="pb-4">
         
<p className="black_bold_text">Room Inclusions</p>
<div className="d-flex align-items-start">
<img src={meal} className="inclusion_img"/>
<p className="mb-0 ms-2">No meals included</p>
</div>
<div className="d-flex align-items-start mt-3">
<img src={drink} className="inclusion_img"/>
<div>
<p className="mb-1 ms-2 inclusion_title">Free Welcome Drink on Arrival</p>

<p className="mb-0 ms-2">Complimentary 1 Welcome Drink on Arrival is provided. Drinks are served with Tea or coffee options.</p>
</div>
</div>
        
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default InclusionModal;
