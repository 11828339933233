import React, { useRef } from "react";
import success from "../assets/images/Invoice/success.png";
import rating from "../assets/images/Invoice/rating.png";
import location from "../assets/images/Invoice/location.png";
import roomImg from "../assets/images/Invoice/roomImg.jpg";
import userImg from "../assets/images/Invoice/user.png";
import notification from "../assets/images/Invoice/notification.png";
import leaves from "../assets/images/Invoice/leaves.png";


import { DateTimeForm, Name_showing, NightdaysCalculation } from "../lib/common";

import config from "../lib/config"

function BookingConfirmation({refdata,Bookingdata}) {



  return (
    <>
      {/* <button onClick={downloadInvoice}>Download Invoice as PDF</button> */}
      <div className="booking_pdf_WhiteBg" ref={refdata}>
        <div className="booking_pdf">
          <div className="booking_confirm_Pdf">
            <div className="company_address">
              <div>
                <p className="name mb-0">Pietrip India Private Limited</p>
                <div className="address mt-2">
                  <p className="mb-0">14/61-62, G702, Yog tower,</p>
                  <p className="mb-0">Civil lines road</p>
                  <p className="mb-0">Contact Number - 8009094291</p>
                  <p className="mb-0">Email ID - business@pietrip.comd</p>
                </div>
              </div>
            </div>
            <div className="booking_details">
              <div className="fst_sec">
                <div>
                  <p className="heading_txt mb-0">Booking ID</p>
                  <p className="val mb-0">{Bookingdata?.Bookingid}</p>
                </div>
{     Bookingdata?.PNR&&           <div>
                  <p className="heading_txt mb-0">Booking PNR</p>
                  <p className="val mb-0">{Bookingdata?.Bookingid}</p>
                </div>}
                <div>
                  <p className="heading_txt mb-0">Booking Date</p>
                  <p className="val mb-0">{DateTimeForm({date:Date.now(),words:true})}</p>
                </div>
              </div>
              <div className="booking_success">
                <img src={success} className="img-fluid successImg" />
                BOOKING CONFIRMED
              </div>
            </div>
            <div className="room_details">
              <div className="hotel_loc">
                <div>
                  <p className="mb-0 loc">
                    Hotel in {Bookingdata?.hoteldata?.ad?.ctn} | <span className="fw-800">{
                    NightdaysCalculation(
                      Bookingdata.Searchdata.checkinDate,Bookingdata.Searchdata.checkoutDate
)} Night</span>
                  </p>
                  <p className="addBig mb-0">
                    {Bookingdata?.hoteldata?.name}
                    <span className="ms-2">
                    {/* {renderStars(Bookingdata?.hoteldata?.rt)} */}
                    {[...Array(Bookingdata?.hoteldata?.rt)].map((val)=>
                      <img src={rating} className="img-fluid starimg" />
                    )}
           
                    </span>
                  </p>
                </div>
                {/* <button className="locationBtn">
                  <img src={location} className="img-fluid" />
                  Get Directions
                </button> */}
              </div>
              <div className="addr">
                <p className="mb-0">

{Bookingdata?.hoteldata?.ad?.adr}, {Bookingdata?.hoteldata.ad.ctn} ,{Bookingdata?.hoteldata.ad.sn},
{Bookingdata?.hoteldata.ad.cn}
              </p>
                <p className="mb-0">
                  Contact Number - {Bookingdata?.hoteldata?.cnt?.ph}
                </p>
                {/* <p className="mb-0">
                  Email ID -
                  ar@crowneplazapune.com,dm@crowneplazapune.com,reservations@crowneplazapune.com
                </p> */}
              </div>
              <div className="checkInOut">
                <div className="roomImg">
                  <img src={`${config?.IMG_URL}/Hotels/${Bookingdata?.hoteldata?.id?.split('-')[1]}.png`??roomImg} className="img-fluid" />
                </div>
                <div>
                  <div className="time_details">
                    <div className="">
                      <p className="checkInOut_txt mb-0">Check In</p>
                      <p className="date mb-0">{DateTimeForm({date:Bookingdata?.Searchdata?.checkinDate,words:true})}</p>
                    </div>
                    <div className="">
                      <p className="checkInOut_txt mb-0">Check Out</p>
                      <p className="date mb-0">{DateTimeForm({date:Bookingdata?.Searchdata?.checkoutDate,words:true})}</p>
                    </div>
                  </div>
                  <p className="mb-0 reservation">
                    <span className="fw-800">Reservation for</span> -
                    {Name_showing(Bookingdata?.travellerdata[0]?.travellerInfo?.[0]?.fN+Bookingdata?.travellerdata[0]?.travellerInfo?.[0]?.lN)}

                    (Primary Guest)  {
                    Bookingdata?.travellerdata.length-1!==0&&Bookingdata?.travellerdata?.length-1+'Others'
} 
                  </p>
                </div>
              </div>
{ Bookingdata?.roomsdata?.[0]?.ris?.map((item,index)=>           
  <div className="roomInfo">
                <p className="room mb-0">
                  <span className="fw-700">Room {index+1
} -</span> {item?.rt}
                </p>
                <p className="adults mb-0">
                  <img src={userImg} className="img-fluid userImg" />{item?.adt} Adult(s),
                  {item?.chd}  Children
                </p>
                <hr />
                <p className="meal mb-0">
                  <span className="mealPlan fw-500">Meal Plan - </span>{item?.mb}
                </p>
              </div>
              )}

              <div className="guestInfo">
                <p className="guest_txt mb-0">Guest Details ({Bookingdata?.travellerdata?.length})</p>
                <p className="guest_name mb-0">
                  <span className="fw-800"> {Name_showing(Bookingdata?.travellerdata[0]?.travellerInfo?.[0]?.fN+Bookingdata?.travellerdata[0]?.travellerInfo?.[0]?.lN)}
 </span>(PrimaryGuest) + {Bookingdata?.travellerdata.length-1!==0&&Bookingdata?.travellerdata?.length-1+'Others'
}
                </p>
              </div>
            </div>
{Bookingdata?.hoteldata?.inst?.length>0&&
  <>
            <div className="imp_noti">
              <img src={notification} className="img-fluid noti_Img" />
              IMPORTANT INFORMATION
            </div>
            
            <div className="imp_infor_details">
            {Bookingdata?.hoteldata?.inst?.map((item)=>
              <div className="cmn_step step1">
                <div className="heading_txt">
                  <span className="dot"></span>{item.type}
                </div>
                <div className="sub_cntList">
                  <div className="sub_cnt">
                    <span className="dot"></span>
                    {Object.values(JSON.parse(item?.msg))?.map((values)=>
                    <p>{values}</p>

                  )
                  }


                  </div>
                  {/* <div className="sub_cnt">
                    <span className="dot"></span>Local ids are allowed
                  </div> */}
                </div>
              </div>
              )}
      
            </div>

            </>
            // :
            // <NodataFound/>
            }
            <div className="advise_txt">
              <img src={leaves} className="img-fluid leavesImg" />
              <p className="mb-0">
                Please think twice before printing this mail.{" "}
                <span className="fw-800">Save paper</span>, it’s good for the
                environment
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BookingConfirmation;
