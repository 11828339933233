import React from "react";
import CommonCard from "./CommonCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Cities() {
  const Cities = [
    {
      id: 1,
      countryImg: require("../assets/images/landing/regions/kowloon-bg.png"),
      logo: require("../assets/images/landing/regions/hongkong-logo.png"),
      stays: "3800",
      name: "Kowloon City",
      footerbg: "black",
    },
    {
      id: 2,
      countryImg: require("../assets/images/landing/regions/chennai-bg.png"),
      logo: require("../assets/images/landing/regions/india-logo.png"),
      stays: "4800",
      name: "India",
      footerbg: "black",
    },
    {
      id: 3,
      countryImg: require("../assets/images/landing/regions/fenghuang.png"),
      logo: require("../assets/images/landing/regions/china-logo.png"),
      stays: "4000",
      name: "Fenghuang",
      footerbg: "black",
    },
    {
      id: 4,
      countryImg: require("../assets/images/landing/regions/kowloon-bg.png"),
      logo: require("../assets/images/landing/regions/hongkong-logo.png"),
      stays: "3800",
      name: "Kowloon City",
      footerbg: "black",
    },
  ];

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    margin: 10,
    // arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="explore-countries">
      <h3 className="mb-0 fw-700 header-clr text-center">TOP CITIES</h3>

      <Slider {...settings} className="country-slider mt-4">
        {Cities.map((item, index) => {
          return (
            <CommonCard
              countryImg={item.countryImg}
              logo={item.logo}
              stays={item.stays}
              name={item.name}
              footerbg={item.footerbg}
            />
          );
        })}
      </Slider>
    </div>
  );
}

export default Cities;
