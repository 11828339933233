import React from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Countries from "./Countries";
import Cities from "./Cities";

function Regions() {
  return (
    <div className="container custom-container">
      <div className="regions">
        <div className="header-sec text-center">
          <h3 className="mb-0 fw-700 header-clr">REGIONS</h3>
          <p className="mb-0 header-sub-clr">
            Where do you want to go? Find the best hotels in top destinations
          </p>
        </div>
        <div className="regions-custom-tabs">
          <Tab.Container id="left-tabs-example" defaultActiveKey="one">
            <div className="row">
              <div className="col-xl-12">
                <Nav variant="pills" className="">
                  <Nav.Item>
                    <Nav.Link eventKey="one">EUROPE</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="two">NORTH AMERICA</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="three">ASIA</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="four">CENTRAL AMERICA</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="five">OCEANIA</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="six">SOUTH AMERICA</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="seven">MIDDLE EAST</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="seven">AFRICA</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <Tab.Content>
                  <Tab.Pane eventKey="one">
                    <div className="countries mt-5">
                      <Countries />
                    </div>
                    <div className="cities mt-5">
                      <Cities />
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="two">
                    <div className="countries mt-5">
                      <Countries />
                    </div>
                    <div className="cities mt-5">
                      <Cities />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="three">
                    <div className="countries mt-5">
                      <Countries />
                    </div>
                    <div className="cities mt-5">
                      <Cities />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="four">
                    <div className="countries mt-5">
                      <Countries />
                    </div>
                    <div className="cities mt-5">
                      <Cities />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="five">
                    <div className="countries mt-5">
                      <Countries />
                    </div>
                    <div className="cities mt-5">
                      <Cities />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
}

export default Regions;
